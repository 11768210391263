import { computed, watch, ref } from 'vue';
import { isJSON } from '@shared/utils/helpers';

export function useConditionalValue(props: Record<string, any>) {
  function getDefaultText() {
    if (!props.element.conditionalValue) return '';
    if (!isJSON(props.element.conditionalValue)) return '';

    const conditions = parseConditionalValue();
    const defaultValue = (Object.values(conditions || {})[0] as any)?.value || '';

    if (props.isEditorMode) {
      return conditions[getActiveCondition()]?.value || defaultValue;
    }

    return defaultValue;
  }

  function parseConditionalValue(val = props.element.conditionalValue) {
    if (!isJSON(val)) return null;
    return val ? JSON.parse(val) : null;
  }

  function getActiveCondition() {
    if (props.isEditorMode) {
      return props.pageData?.activeCustomization?.value?.[props.element.id] || Object.keys(parseConditionalValue() || {})[0];
    }

    return props.pageData?.getActiveCondition?.();
  }

  function getConditionalValue() {
    if (props.isEditorMode) {
      if (conditionalValues.value) {
        return defaultConditionalValue.value || props.value;
      } else {
        return props.value;
      }
    }
  
    return props.pageData?.applyConditionalValue?.(conditionalValues.value, props.value) || props.value;
  }

  if (props.isEditorMode) {
    watch(
      () => props.pageData?.activeCustomization?.value?.[props.element.id],
      () => {
        defaultConditionalValue.value = getDefaultText();
      }
    );
  }

  const condition = computed(getActiveCondition);
  const defaultConditionalValue = ref(getDefaultText());
  const conditionalValues = computed(() => parseConditionalValue());
  const textValue = computed(() => getConditionalValue());

  return {
    textValue,
    condition,
    conditionalValues,
    defaultConditionalValue
  }
}
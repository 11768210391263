<!-- eslint-disable vue/no-v-html -->
<template>
  <component
    :is="linkValue ? 'a' : 'div'"
    class="tw-image"
    :class="{ 'tw-live': !isEditorMode }"
    :style="styleWithoutWidthHeight"
    :target="targetValue"
    :href="linkValue"
  >
    <img
      :style="[
        `--imageH: ${style.height}`,
        `--imageW: ${style.width}`,
        styleWithoutWidthHeight
      ]"
      :src="imageUrl"
      v-bind="{ ...attr, ...element.attr}"
      role="presentation"
      :alt="element.id"
    />
  </component>
</template>

<script lang="ts" setup>
import { type PropType, computed, toRef } from 'vue';
import type { Element as ElementType } from '@shared/types/model';
import { getAssetFromCdn } from '@shared/utils/helpers';
import type { ScreenSize } from '@shared/types/options';

const props = defineProps({
  tagName: { type: String, default: 'img' },
  value: { type: String, default: '' },
  style: { type: Object, default: () => ({}) },
  attr: { type: Object as PropType<ElementType<'image'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'image'>>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  sizes: { type: Object, default: () => ({}) },
});

const element = toRef(props, 'element');

const linkValue = computed(() => {return props.element?.attr?.href || props.element?.attr['data-href']})
const targetValue = computed(() => {return props.element?.attr?.target })

const styleWithoutWidthHeight = computed(() => {
  const style = JSON.parse(JSON.stringify(props.style));
  delete style.width;
  delete style.height;
  delete style.objectFit;
  return style;
})

function isURL(str: string) {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
}

const elStyle = computed(() => {
  if (!props.element.options.sizes) {
    return {
      mobile: {
        height: 'auto',
        width: props.style.width || '',
        objectFit: 'contain',
      },
      tablet: {
        height: 'auto',
        width: props.style.width || '',
        objectFit: 'contain',
      },
      desktop: {
        height: props.style.height || '',
        width: props.style.width || '',
        objectFit: 'contain',
      },
    }
  }
  return Object.entries(props.element.options.sizes).reduce((acc, [key, value]) => {
    acc[key as ScreenSize] = {
      height: value.autoHeight ? 'auto' : value.height + 'px',
      width: value.width + 'px',
      objectFit: value.objectFit || 'contain',
    }
    return acc;
  }, {} as Record<ScreenSize, {height: string | number; width: string | number; objectFit: string}>);
});


const imageUrl = computed(() => {
  return isURL(props.value) ? props.value : getAssetFromCdn(props.value)
});
</script>

<style lang="postcss" scoped>
.tw-image {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: v-bind('elStyle?.mobile?.height');
  width: v-bind('elStyle?.mobile?.width');
  object-fit: v-bind('elStyle?.mobile?.objectFit');

  --oldObjectfit: v-bind('props.style.objectFit');

  &:not(.ignore-h-auto) {
    img {
      width: 100%;
      object-fit: inherit;
    }
  }

  &.ignore-h-auto {
    img {
      object-fit: var(--oldObjectfit, 'contain');
    }
  }

  /**
  * If element has this class, containing img will be fixed height as percent,
  * it is necessary trick for equal height columns
  */
  &.has-fixed-height-image img {
    height: 100% !important;
  }

  @media (min-width: 768px) {
    height: v-bind('elStyle?.tablet?.height');
    width: v-bind('elStyle?.tablet?.width');
    object-fit: v-bind('elStyle?.tablet?.objectFit');
  }

  @media (min-width: 1024px) {
    height: v-bind('elStyle?.desktop?.height');
    width: v-bind('elStyle?.desktop?.width');
    object-fit: v-bind('elStyle?.desktop?.objectFit');
  }
}

.desktop {
  .tw-image {
    height: v-bind('elStyle?.desktop?.height');
    width: v-bind('elStyle?.desktop?.width');
    object-fit: v-bind('elStyle?.desktop?.objectFit');
  }
}

.tablet {
  .tw-image {
    height: v-bind('elStyle?.tablet?.height');
    width: v-bind('elStyle?.tablet?.width');
    object-fit: v-bind('elStyle?.tablet?.objectFit');
  }
}

.mobile {
  .tw-image {
    height: v-bind('elStyle?.mobile?.height');
    width: v-bind('elStyle?.mobile?.width');
    object-fit: v-bind('elStyle?.mobile?.objectFit');
  }
}
</style>

<template>
  <div class="tw-selection-element" :class="wrapperClass">
    <div
      class="tw-selection-element-options mx-auto flex flex-wrap justify-center md:my-0"
      :class="{
        'gap-[20px] py-[10px]': imageSelection,
        [config.imageAlign || '']: imageSelection && !!config.imageAlign
      }"
    >
      <div
        v-for="(option, index) in options"
        :key="option?.id || index"
        class="tw-selection-element-col py-[5px] md:p-[10px]"
        :class="{ '!p-0': imageSelection }"
      >
        <div class="tw-selection-option__wrapper" data-editable-parent="true">
          <div
            role="button"
            tabindex="0"
            class="tw-selection-option"
            :class="{
              selected: !!getSelected(option),
              supersized: config.supersizeImages,
              hideLabels: !config.showLabels
            }"
            :[optionEditableAttr]="index"
            @[btnClick]="toggleItem(option)"
          >
            <template v-if="imageSelection">
              <div
                class="tw-selection-option__image-box"
                :class="{ '!pb-0': !config.showLabels, 'w-full': config.supersizeImages }"
              >
                <img :src="option.image ? option.image : imagePlaceholder" :alt="option.name" />
              </div>
              <div
                v-show="config.showLabels"
                :key="index"
                :data-option="index"
                class="tw-selection-option__text-box focus-visible:outline-none"
              >
                {{ option.name }}
              </div>
            </template>
            <template v-else>
              {{ option.name }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$slots.default && canShowButton"
      class="tw-selection-element-bottom md:mx-auto md:my-0 md:flex md:items-center md:justify-center"
    >
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, onBeforeMount } from 'vue';
import type { PageOptions, Element as ElementType } from '../../types/model';
import type { ElementOptions } from '../../types/options';
import { getAssetFromCdn, globalEmit } from '@shared/utils/helpers';
import { type QuestionOption, type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';

const props = defineProps({
  element: { type: Object as PropType<ElementType<'selection'>>, default: () => ({}) },
  config: { type: Object as PropType<ElementOptions['selection'] & ElementOptions['imageselection']>, default: () => ({}) },
  options: { type: Array as PropType<QuestionOption[]>, default: () => [] },
  isEditorMode: { type: Boolean, default: false },
  imageSelection: { type: Boolean, default: false },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const selected = ref<QuestionOption[]>([]);
const optionEditableAttr = computed(() => (props.imageSelection ? '' : 'data-option'));
const multiple = computed(() => props.config.multiple);
const fontSize = computed(() => `${props.config.buttons?.fontSize || 16}px`);
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const wrapperClass = computed(() => {
  return {
    editormode: props.isEditorMode,
    [`tw-columns-${props.config.columns?.default}`]: true,
    imageSelection: props.imageSelection
  };
});

const optionStyle = computed(() => {
  const fontFamily = props.config.buttons?.fontFamily || props.pageOptions.fontFamily;
  const color = {
    regular: props.config.buttons?.color?.regular || props.pageOptions.colors.text[0],
    hover: props.config.buttons?.color?.hover || props.pageOptions.colors.text[0],
    selected: props.config.buttons?.color?.selected || props.pageOptions.colors.text[0],
  }
  const background = {
    regular: getColor(props.config.buttons?.background?.regular || ''),
    hover: getColor(props.config.buttons?.background?.hover || ''),
    selected: getColor(props.config.buttons?.background?.selected || props.pageOptions.colors.theme[2]),
  }

  const border = {
    regular: getColor(props.config.buttons?.border?.regular || 'transparent'),
    hover: getColor(props.config.buttons?.border?.hover || props.pageOptions.colors.theme[0]),
    selected: getColor(props.config.buttons?.border?.selected || props.pageOptions.colors.theme[0]),
    width: `${props.config.buttons?.border?.width || 1}px`,
    radius: `${props.config.buttons?.border?.radius || props.pageOptions.borderRadius || 1}px`
  }

  const textAlign = props.config.buttons?.textAlign || 'center';

  return {
    ...props.config.buttons,
    fontFamily,
    color,
    background,
    border,
    boxAlign: textAlign === 'left' ? 'flex-start' : textAlign === 'right' ? 'flex-end' : textAlign,
    textStyle: {
      bold: props.config.buttons?.textStyle?.bold ? 'bold' : '',
      italic: props.config.buttons?.textStyle?.italic ? 'italic' : '',
      underline: props.config.buttons?.textStyle?.underline ? 'underline' : '',
      strikeThrough: props.config.buttons?.textStyle?.strikeThrough ? 'line-through' : ''
    }
  }
})

function getColor(color: string){
  if (/#[a-fA-F0-9]{3,6}/.test(color)) {
    return color
  }
  return color.replace(/#transp/g, 'transparent');
}

const textDecoration = computed(() => {
  return `${optionStyle.value.textStyle.underline} ${optionStyle.value.textStyle.strikeThrough}`.trim();
});

const canShowButton = computed(() => {
  if (props.config.required) {
    return !!multiple.value?.active;
  }

  return true;
});

const canSubmit = computed(() => {
  if (props.isEditorMode) return true;
  if (props.config.required) {
    if (multiple.value?.active) {
      return isReachedMinSelection();
    }
    return false;
  } else {
    if (multiple.value?.active) {
      return isReachedMinSelection();
    }
  }
  return true;
});

const imagePlaceholder = computed(
  () => getAssetFromCdn(`/editor/imageselection-default-${props.config.supersizeImages ? 'large' : 'small'}.jpg`)
);

function finish(event?: any) {
  if (canShowButton.value && !canSubmit.value) return;
  globalEmit('nextQuestion', [event, selected.value]);
}

function getSelected(option: QuestionOption) {
  return selected.value.find((item) => item === option);
}

function isReachedMinSelection() {
  const totalSelection = selected.value.length;
  if (!multiple.value?.active) return false;
  if (!multiple.value?.min.active) {
    return totalSelection >= 1;
  }
  const min = multiple.value?.min.limit;
  return totalSelection >= min;
}

function isReachedMaxSelection() {
  if (!multiple.value?.active || !multiple.value?.max.active) return false;
  const max = multiple.value?.max.limit;
  const totalSelection = selected.value.length;
  return totalSelection >= max;
}

function removeItem(option: QuestionOption) {
  const hasSame = getSelected(option);
  if (!hasSame) return;
  const index = selected.value.indexOf(hasSame);
  selected.value.splice(index, 1);
  return false;
}

function selectItem(option: QuestionOption) {
  const hasSame = getSelected(option);
  if (!hasSame && !isReachedMaxSelection()) {
    selected.value.push(option);
  }
}

function toggleItem(option: QuestionOption) {
  if (!multiple.value?.active) {
    selected.value = [option];
    finish();
    return;
  }

  const hasSame = getSelected(option);
  if (hasSame) {
    removeItem(option);
  } else {
    selectItem(option);
  }
}

function setDefaultSelections() {
  if (props.pageData?.answers?.length) {
    selected.value = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: props.imageSelection ? 'imageselection' : 'selection',
      questionOptions: props.options
    });
  }
}

onBeforeMount(() => {
  setDefaultSelections();
})
</script>

<style lang="postcss" scoped>
.is-mobile-like-device .tw-selection-element {
  --selection-borderColorRegular: v-bind('optionStyle.border?.regular');
  --selection-colorRegular: v-bind('optionStyle.color.regular');
  --selection-backgroundRegular: v-bind('optionStyle.background.regular');
}
.tw-selection-element {
  --selection-fontFamily: v-bind('optionStyle.fontFamily');
  --selection-fontSize: v-bind('fontSize');
  --selection-colorRegular: v-bind('optionStyle.color.regular');
  --selection-colorHover: v-bind('optionStyle.color.hover');
  --selection-colorSelected: v-bind('optionStyle.color.selected');
  --selection-borderColorRegular: v-bind('optionStyle.border?.regular');
  --selection-borderColorHover: v-bind('optionStyle.border?.hover');
  --selection-borderColorSelected: v-bind('optionStyle.border?.selected');
  --selection-borderRadius: v-bind('optionStyle.border.radius');
  --selection-borderWidth: v-bind('optionStyle.border.width');
  --selection-backgroundRegular: v-bind('optionStyle.background.regular');
  --selection-backgroundHover: v-bind('optionStyle.background.hover');
  --selection-backgroundSelected: v-bind('optionStyle.background.selected');
  --selection-textAlign: v-bind('optionStyle.textAlign');
  --selection-boxAlign: v-bind('optionStyle.boxAlign');
  --selection-bold: v-bind('optionStyle.textStyle.bold');
  --selection-italic: v-bind('optionStyle.textStyle.italic');
  --selection-textDecoration: v-bind('textDecoration');

  &-options {
    .tw-selection-option {
      padding: 14px;
      width: 100%;
      min-height: 48px;
      border-radius: var(--selection-borderRadius, 6px);
      font-weight: var(--selection-bold, normal);
      font-style: var(--selection-italic, normal);
      text-decoration: var(--selection-textDecoration, none);
      text-align: var(--selection-textAlign, center);
      font-family: var(--selection-fontFamily, inherit);
      font-size: var(--selection-fontSize, 16px);
      color: var(--selection-colorRegular, #000000);
      border-width: var(--selection-borderWidth, 1px);
      border-style: solid;
      border-color: var(--selection-borderColorRegular, #b4bfc5);
      background-color: var(--selection-backgroundRegular, #ffffff);
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: var(--selection-boxAlign);
      word-wrap: break-word;
      word-break: break-word;

      &:hover {
        color: var(--selection-colorHover, #000000);
        border-color: var(--selection-borderColorHover, #b4bfc5);
        background-color: var(--selection-backgroundHover, #cccccc);
      }

      &.selected {
        color: var(--selection-colorSelected, #000000);
        border-color: var(--selection-borderColorSelected, #b4bfc5);
        background-color: var(--selection-backgroundSelected, #cccccc);
      }

      &__wrapper {
        height: 100%;
      }
    }
  }

  &-bottom {
    padding-top: 38px;
    padding-bottom: 20px;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.editormode & {
    &-options {
      .tw-selection-option {
        cursor: initial;
      }
    }
  }

  @media (max-width: 768px) {
    &-bottom {
      .tw-slot-wrapper {
        :deep(.tw-element-wrapper) {
          display: block;
        }
      }
    }
  }
}

.tw-selection-element.imageSelection {
  --option-fullWidth: 150px;
  --option-width: 130px;
  --option-padding: 16px 16px 8px;
  --option-image-width: 56px;
  --option-image-height: 56px;
  --option-image-radius: 12px;
  --option-image-padding: 0;
  --option-image-object-fit: contain;
  --option-text-padding: 0 8px;
  --option-text-min-height: 40px;
  --option-textbox-align: center;
  .tw-selection-element {
    &-col {
      width: auto;
    }
    &-options {
      .tw-selection-option {
        display: flex;
        flex-flow: column;
        width: var(--option-width);
        padding: var(--option-padding);
        &.supersized {
          --option-image-width: 100%;
          --option-image-height: 130px;
          --option-padding: 0;
          --option-image-radius: 4px;
          --option-image-object-fit: cover;
          --option-textbox-align: center;
        }
        &.hideLabels:not(.supersized) {
          --option-image-padding: 0 0 8px 0;
        }
        &__image-box {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: var(--option-image-padding);
          img {
            width: var(--option-image-width);
            height: var(--option-image-height);
            border-radius: var(--option-image-radius);
            object-fit: var(--option-image-object-fit);
          }
        }
        &__text-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          width: 100%;
          justify-content: center;
          align-items: var(--selection-boxAlign);
          padding: var(--option-text-padding);
          min-height: var(--option-text-min-height);
        }
      }

      &.left {
        --option-fullWidth: 280px;
        --option-width: 260px;
        --option-image-radius: 6px;

        .tw-selection-option {
          --option-image-width: 40px;
          --option-image-height: 40px;
          flex-direction: row;

          &.supersized {
            --option-image-width: 62px;
            --option-image-height: 62px;
            padding: 0;
          }

          &:not(.supersized) {
            padding-top: 11px;
            padding-bottom: 11px;
          }

          &__image-box {
            flex: 0 0 var(--option-image-width);
          }

          &__text-box {
            flex: 1;
            margin-top: 0;
            align-items: var(--selection-boxAlign);
            padding-right: 8px;
            padding-left: 16px;
            word-break: break-word;
          }
        }
      }
    }
  }
  &.tw-columns {
    &-1 {
      .tw-selection-element-options {
        max-width: calc(var(--option-fullWidth) * 1);
      }
    }
    &-2 {
      .tw-selection-element-options {
        max-width: calc(var(--option-fullWidth) * 2);
      }
    }
    &-3 {
      .tw-selection-element-options {
        max-width: calc(var(--option-fullWidth) * 3);
      }
    }
    &-4 {
      .tw-selection-element-options {
        max-width: calc(var(--option-fullWidth) * 4);
      }
    }
    &-5 {
      .tw-selection-element-options {
        max-width: calc(var(--option-fullWidth) * 5);
      }
    }
    &-6 {
      .tw-selection-element-options {
        max-width: calc(var(--option-fullWidth) * 6);
      }
    }
  }
}

.is-mobile-like-device {
  .tw-selection-element {
    &-options {
      .tw-selection-option {
        &:not(.selected):hover {
          color: var(--selection-colorRegular);
          border-color: var(--selection-borderColorRegular);
          background-color: var(--selection-backgroundRegular);
        }
      }
    }
  }
}

/*
  In order to provide editor mobile view,
  we wrote extra styles that we cannot write with tailwind
  for this specific situation
*/
.tw-editor__screen {
  &.mobile {
    .tw-selection-element {
      &-col {
        width: 100%;
      }
      &-bottom {
        .tw-slot-wrapper {
          :deep(.tw-element-wrapper) {
            display: block;
          }
        }
      }
    }
    .imageSelection.tw-selection-element {
      .tw-selection-element-col {
        width: auto;
      }
    }
  }
}

.mobile {
  .tw-selection-element {
    &.tw-columns {
      &-1, &-2, &-3 {
        .tw-selection-element-bottom {
          max-width: none;
        }
      }
    }
  }
}

.tw-selection-element {
  &-col {
    width: 100%;
  }

  @media (min-width: 768px) {
    &-col {
      width: calc(100% / v-bind('config.columns?.default'));
    }

    &.tw-columns {
      &-1 {
        .tw-selection-element-options,
        .tw-selection-element-bottom {
          max-width: 300px;
        }
      }
      &-2 {
        .tw-selection-element-options,
        .tw-selection-element-bottom {
          max-width: 600px;
        }
      }
      &-3 {
        .tw-selection-element-options,
        .tw-selection-element-bottom {
          max-width: 900px;
        }
      }
    }
  }
}
</style>

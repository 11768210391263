<template>
  <div class="tw-numericinput-element !m-0 md:!mx-8 md:!mb-8 md:!mt-0 md:max-w-[400px]">
    <div class="mx-auto md:max-w-[280px]" :class="{ 'pointer-events-none': isEditorMode }">
      <WebInput
        v-if="renderComponent"
        ref="inputRef"
        v-model="textValue"
        name="numericinput"
        autocomplete="off"
        force-mask-update
        :validate-on-update="true"
        v-bind="getAttrsByConfig()"
        :value="`${textValue}`"
        :label="labelText"
        :label-style="labelStyle"
        :page-options="pageOptions"
        :rules="rules"
      />
    </div>

    <InfoTag
      v-if="canVisibleInfoTag"
      :icon="infoTag?.icon"
      :title="purify(infoTag?.title || '')"
      :description="purify(infoTag?.description || '')"
      :color="infoTag?.color"
      class="mx-auto mb-16 md:max-w-[280px]"
      :style="infoStyle"
    />

    <div v-if="$slots.default" class="tw-numericinput-element__bottom mx-auto md:max-w-[280px]">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, toRefs, onBeforeMount, watch, onMounted, nextTick } from 'vue';
import { globalEmit, getClientNumber, getClientNumberFormat, getRealNumber, isClient } from '@shared/utils/helpers';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import WebInput from '@shared/components/input/index.vue';
import InfoTag from '@shared/components/infotag/index.vue';
import { purify } from '@shared/utils';

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['numericinput']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  element: { type: Object as PropType<ElementType<'numericinput'>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const clientLang = computed(() => {
  return globalThis?.navigator?.language || props.pageData.countryCode || '';
});
const renderComponent = ref(true);
const inputRef = ref<any>(null);
const textValue = ref<string|number>(getDefaultValue());
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const { element, pageOptions } = toRefs(props);
const { labelStyle, labelText, values } = useLabelOptions({ element, pageOptions });

const infoTag = computed(() => values.value?.infoTag || {});
const infoStyle = computed(() => {
  return {
    fontFamily: props.pageOptions.fontFamily,
    titleColor: element.value.options.customizeTagTextColor ? element.value.options.infoTagTitleColor : props.pageOptions.colors?.text[0],
    descColor: element.value.options.customizeTagTextColor ?  element.value.options.infoTagDescColor : props.pageOptions.colors?.text[1],
  }
});
const rules = computed(() => {
  const { min, max } = props.config.input || {};
  const isRequired = props.config.required;
  const defaultMinValue = props.config.zero ? undefined : 1;
  const defaultMaxValue = undefined;
  const minValue = min?.active ? parseFloat(`${min?.limit || ''}`) || defaultMinValue : defaultMinValue;
  const maxValue = max?.active ? parseFloat(`${max?.limit || ''}`) || defaultMaxValue : defaultMaxValue;
  let str = isRequired ? 'required' : '';

  if (minValue !== undefined && maxValue !== undefined) {
    str += `|between:${minValue},${maxValue}`;
  }

  if (minValue !== undefined && maxValue === undefined) {
    str += `|min_value:${minValue}`
  }

  if (maxValue !== undefined && minValue === undefined) {
    str += `|max_value:${maxValue}`
  }

  return str;
});

const isEmpty = computed(() => textValue.value === '');

const canVisibleInfoTag = computed(() => {
  return (
    element.value.options.showInfoTag &&
    (infoTag.value?.title || infoTag.value?.description) &&
    (props.isEditorMode || (canSubmit.value && !isEmpty.value))
  )
})

const canSubmit = computed(() => {
  const allowZero = props.config.zero;
  const value = getClientNumber(textValue.value as number, clientLang.value) || textValue.value;
  const currentValue = getRealNumber(`${value}`, clientLang.value);
  
  if (props.config?.input) {
    const { min, max } = props.config.input || {};
    const minLength = getRealNumber(`${min?.limit || ''}`, clientLang.value);
    const maxLength = getRealNumber(`${max?.limit || ''}`, clientLang.value);

    if (min?.active && min.limit !== null && currentValue < minLength) {
      return false;
    }

    if (max?.active && max.limit !== null && currentValue > maxLength) {
      return false;
    }
  }

  if (props.config.required) {
    return textValue.value !== '' && (allowZero ? currentValue >= 0 : currentValue > 0);
  }

  return value === '' || (allowZero ? currentValue >= 0 : true);
});

if (isClient()) {
  watch(
    () => props.config.defaultValue,
    (val) => textValue.value = val || ''
  );

  watch(
    () => props.config.decimal,
    () => forceRender()
  )
}

function getAttrsByConfig() {
  if (props.config.decimal) {
    return {
      type: 'number',
      mask: getClientNumberFormat(clientLang.value)
    }
  }

  return {
    type: 'number',
    mask: {
      mask: Number,
      scale: 0
    }
  }
}

async function forceRender() {
  renderComponent.value = false;
  await nextTick();
  renderComponent.value = true;
}

function getDefaultValue(value?: string | number) {
  let defaultValue = props.config.defaultValue;
  if (value !== undefined) defaultValue = value;

  if (defaultValue !== undefined && defaultValue !== '') {
    if (props.isEditorMode) {
      defaultValue = getRealNumber(defaultValue, clientLang.value);
    }

    if (parseFloat(`${defaultValue || '0'}`) <= 0 && !props.config.zero) {
      defaultValue = 1
    }
  }

  return defaultValue || '';
}

function finish(event?: any) {
  if (!canSubmit.value) return;
  let value = parseFloat(`${textValue.value}`);
  if (!props.config.decimal) value = parseInt(`${value}`);
  globalEmit('nextQuestion', [event, value]);
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    textValue.value = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'numericinput',
    });
  }
}

onBeforeMount(() => {
  setPreviousAnswer();
});

onMounted(() => {
  if (props.isEditorMode) return;
  if ((import.meta as any)?.client) {
    const input = inputRef.value?.el;
    if (!input?.value) return;
    input?.focus();
    // For update real number on input
    setTimeout(() => {input?.blur(); input?.focus()}, 200);
  }
})
</script>

<style lang="postcss" scoped>
.tw-numericinput-element {
  :deep(label) {
    word-break: break-word;
  }
}
</style>

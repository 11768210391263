<template>
  <component
    :is="activeComponent"
    class="tw-media"
    :tag-name="tagName"
    :value="value"
    :style="style"
    :attr="attr"
    :element="element"
    :is-editor-mode="isEditorMode"
    :sizes="sizes"
  />
</template>

<script lang="ts" setup>
import { type PropType, computed, toRef } from 'vue';
import type { Element as ElementType } from '../../types/model';
import { useSpacing } from '@shared/composable/useSpacing';
import { useSizing } from '@shared/composable/useSizing';
import Image from './components/image.vue';
import Video from './components/video.vue';
import Embed from './components/embed.vue';

const props = defineProps({
  tagName: { type: String, default: 'img' },
  value: { type: String, default: '' },
  style: { type: Object, default: () => ({}) },
  attr: { type: Object as PropType<ElementType<'image'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'image'>>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
});

const element = toRef(props, 'element');
const { margin } = useSpacing(element);

const { sizes } = useSizing(element, {
  sizes: {
    desktop: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      fullSize: false,
    },
    tablet: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      fullSize: false,
      autoHeight: true,
    },
    mobile: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      fullSize: false,
      autoHeight: true,
    },
  },
});

const options = computed(() => props.element.options);

const activeComponent = computed(() => {
  if (options.value.mediaType === 'video') return Video;
  if (/^embed_/.test(options.value.mediaType || '')) return Embed;
  return Image
})
</script>

<style lang="postcss" scoped>
.tw-media {
  /* MARGIN */
  --media-desktop-margin-top: v-bind('margin?.desktop?.top');
  --media-desktop-margin-left: v-bind('margin?.desktop?.left');
  --media-desktop-margin-bottom: v-bind('margin?.desktop?.bottom');
  --media-desktop-margin-right: v-bind('margin?.desktop?.right');

  --media-tablet-margin-top: v-bind('margin?.tablet?.top');
  --media-tablet-margin-left: v-bind('margin?.tablet?.left');
  --media-tablet-margin-bottom: v-bind('margin?.tablet?.bottom');
  --media-tablet-margin-right: v-bind('margin?.tablet?.right');

  --media-mobile-margin-top: v-bind('margin?.mobile?.top');
  --media-mobile-margin-left: v-bind('margin?.mobile?.left');
  --media-mobile-margin-bottom: v-bind('margin?.mobile?.bottom');
  --media-mobile-margin-right: v-bind('margin?.mobile?.right');

  margin-top: var(--media-mobile-margin-top, 0px);
  margin-left: var(--media-mobile-margin-left, 0px);
  margin-bottom: var(--media-mobile-margin-bottom, 0px);
  margin-right: var(--media-mobile-margin-right, 0px);
  
  @media (min-width: 768px) {
    margin-top: var(--media-tablet-margin-top, 0px);
    margin-left: var(--media-tablet-margin-left, 0px);
    margin-bottom: var(--media-tablet-margin-bottom, 0px);
    margin-right: var(--media-tablet-margin-right, 0px);
  }

  @media (min-width: 1024px) {
    margin-top: var(--media-desktop-margin-top, 0px);
    margin-left: var(--media-desktop-margin-left, 0px);
    margin-bottom: var(--media-desktop-margin-bottom, 0px);
    margin-right: var(--media-desktop-margin-right, 0px);
  }
}

.desktop {
  .tw-media {
    margin-top: var(--media-desktop-margin-top, 0px);
    margin-left: var(--media-desktop-margin-left, 0px);
    margin-bottom: var(--media-desktop-margin-bottom, 0px);
    margin-right: var(--media-desktop-margin-right, 0px);
  }
}

.tablet {
  .tw-media {
    margin-top: var(--media-tablet-margin-top, 0px);
    margin-left: var(--media-tablet-margin-left, 0px);
    margin-bottom: var(--media-tablet-margin-bottom, 0px);
    margin-right: var(--media-tablet-margin-right, 0px);
  }
}

.mobile {
  .tw-media {
    margin-top: var(--media-mobile-margin-top, 0px);
    margin-left: var(--media-mobile-margin-left, 0px);
    margin-bottom: var(--media-mobile-margin-bottom, 0px);
    margin-right: var(--media-mobile-margin-right, 0px);
  }
}
</style>

<template>
  <div class="flex justify-between px-16 py-12" :class="toastVariants[variant].backgroundColor">
    <div class="flex">
      <WebIcon
        :name="toastVariants[variant].icon"
        :class="toastVariants[variant].iconColor"
        class="min-w-[20px] shrink-0"
      />

      <div class="pl-12 pr-24">
        <span v-if="title" class="break-words text-14 font-medium text-neutral-900">
          {{ title }}
        </span>
        <p v-if="subtitle" class="break-words text-14 font-normal text-neutral-500">
          {{ subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import WebIcon from '@shared/components/icon/index.vue';
import type { PropType } from 'vue';

export type ToastVariant = 'primary' | 'success' | 'warning' | 'error';

defineProps({
  variant: {
    type: String as PropType<ToastVariant>,
    default: 'primary'
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  }
});

const toastVariants: Record<ToastVariant, Record<'icon' | 'iconColor' | 'backgroundColor', any>> = {
  success: {
    icon: 'TickCircle',
    iconColor: 'text-success-500',
    backgroundColor: 'bg-success-100'
  },
  error: {
    icon: 'Warning2',
    iconColor: 'text-error-500',
    backgroundColor: 'bg-error-100'
  },
  warning: {
    icon: 'Danger',
    iconColor: 'text-warning-500',
    backgroundColor: 'bg-warning-100'
  },
  primary: {
    icon: 'Info2',
    iconColor: 'text-primary-500',
    backgroundColor: 'bg-primary-100'
  }
};
</script>

<style lang="postcss">
.Vue-Toastification__toast--default {
  &.tw-toast-container {
    @apply min-h-[auto] w-[400px] max-w-[400px] bg-transparent p-0 rounded-4;

    .Vue-Toastification__close-button {
      @apply absolute top-16 right-16 opacity-100;
    }

    @media only screen and (max-width: 600px) {
      @apply max-w-full;
    }
  }
  [dir='rtl'] & {
    &.tw-toast-container {
      @apply !ml-[unset];
    }
  }
}
</style>

<template>
  <div class="tw-payment-result-element">
    <div>
      <img
        :src="isSuccessMode ? config.icons?.success : config.icons?.warning"
        alt="icon"
        class="mx-auto !mb-[8px] max-h-[60px] max-w-[47px] md:!mb-[14px] md:max-w-[60px]"
      />

      <div class="mb-24 flex flex-col gap-24 md:gap-32">
        <!-- Title Text Slot -->
        <template v-if="isEditorMode">
          <div v-if="$slots.titleText" class="tw-payment-result-element__title-text">
            <slot name="titleText"></slot>
          </div>
        </template>
        <div v-else-if="slotTitleText.length" class="tw-payment-result-element__title-text">
          <template v-for="subEl of slotTitleText" :key="subEl.id">
            <ElementWrapper
              v-if="subEl.options.visible"
              :el="subEl"
              :page-options="pageOptions"
              :page-data="pageData"
            />
          </template>
        </div>

        <!-- Title Desc Slot -->
        <template v-if="isEditorMode">
          <div v-if="$slots.titleDesc" class="tw-payment-result-element__desc-text">
            <slot name="titleDesc"></slot>
          </div>
        </template>
        <div v-else-if="slotTitleDesc.length" class="tw-payment-result-element__desc-text">
          <template v-for="subEl of slotTitleDesc" :key="subEl.id">
            <ElementWrapper
              v-if="subEl.options.visible"
              :el="subEl"
              :page-options="pageOptions"
              :page-data="pageData"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="tw-payment-result-element__cards">
      <div class="tw-payment-result-element__cards-item">
        <!-- Access Link Info Text -->
        <template v-if="isEditorMode">
          <div v-if="$slots.accessLinkInfoText" class="flex flex-col">
            <slot name="accessLinkInfoText"></slot>
          </div>
        </template>
        <div v-else-if="accessLinkInfoText.length">
          <template v-for="subEl of accessLinkInfoText" :key="subEl.id">
            <ElementWrapper
              v-if="subEl.options.visible"
              :el="subEl"
              :page-options="pageOptions"
              :page-data="pageData"
            />
          </template>
        </div>

        <!-- Access Links -->
        <div class="tw-app-links-element">
          <!-- Go to app button -->
          <template v-if="isEditorMode">
            <div
              v-if="$slots.default && !!config.appLinks?.show?.generic"
              class="tw-app-links-element__main-button-wrapper md:!flex"
            >
              <slot />
            </div>
          </template>
          <div v-else-if="slotDefault.length" class="tw-app-links-element__main-button-wrapper md:!flex">
            <template v-for="subEl of slotDefault" :key="subEl.id">
              <ElementWrapper
                v-if="
                  subEl.options.visible && !!config.appLinks?.show?.generic && !!appLinks[storeLinkDefs.generic.urlKey]
                "
                :el="subEl"
                :page-options="pageOptions"
                :page-data="pageData"
              />
            </template>
          </div>

          <!-- Store buttons -->
          <div
            v-if="hasActiveStoreButtons"
            class="tw-app-links-element__store-buttons"
            :class="{ 'pointer-events-none': isEditorMode }"
          >
            <template v-for="(storeLinkDef, key) in storeLinkDefs" :key="key">
              <a
                v-if="getStoreButtonShowStatus(key)"
                :href="appLinks[storeLinkDef.urlKey] || 'javascript:;'"
                target="_blank"
              >
                <img
                  :src="getStoreBadgeImage(key, { isDark: !!config.appLinks?.dark })"
                  :alt="storeLinkDef.storeName"
                />
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="tw-payment-result-element__cards-item-seperator h-48 md:h-[62.5px]"></div>

      <template v-if="isEpinViewActive">
        <div class="tw-payment-result-element__cards-item flex flex-col gap-16 !px-16 md:!p-32">
          <!-- epinTitleText Slot -->
          <template v-if="isEditorMode">
            <div v-if="$slots.epinTitleText" class="flex flex-col">
              <slot name="epinTitleText"></slot>
            </div>
          </template>
          <div v-else-if="epinTitleText.length">
            <template v-for="subEl of epinTitleText" :key="subEl.id">
              <ElementWrapper
                v-if="subEl.options.visible"
                :el="subEl"
                :page-options="pageOptions"
                :page-data="pageData"
              />
            </template>
          </div>

          <div class="text-center">
            <PincodeCard @[btnClick]="copyPinCode(epinCode)">
              {{ epinCode }}
            </PincodeCard>
          </div>

          <div>
            <!-- epinInstructionTitleText Slot -->
            <template v-if="isEditorMode">
              <div v-if="$slots.epinInstructionTitleText" class="flex flex-col">
                <slot name="epinInstructionTitleText"></slot>
              </div>
            </template>
            <div v-else-if="epinInstructionTitleText.length">
              <template v-for="subEl of epinInstructionTitleText" :key="subEl.id">
                <ElementWrapper
                  v-if="subEl.options.visible"
                  :el="subEl"
                  :page-options="pageOptions"
                  :page-data="pageData"
                />
              </template>
            </div>

            <!-- epinInstructionDescription Slot -->
            <template v-if="isEditorMode">
              <div v-if="$slots.epinInstructionDescription" class="flex flex-col">
                <slot name="epinInstructionDescription"></slot>
              </div>
            </template>
            <div v-else-if="epinInstructionDescription.length">
              <template v-for="subEl of epinInstructionDescription" :key="subEl.id">
                <ElementWrapper
                  v-if="subEl.options.visible"
                  :el="subEl"
                  :page-options="pageOptions"
                  :page-data="pageData"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="tw-payment-result-element__cards-item-seperator h-48 md:h-[62.5px]"></div>
      </template>
      <template v-else>
        <template v-if="userActivationType === UserActivationType.ZOTLO_ACTIVATION_CODE">
          <div class="tw-payment-result-element__cards-item flex flex-col gap-16 !px-16 md:!p-32">
            <!-- zotloActivationInfo Slot -->
            <template v-if="isEditorMode">
              <div v-if="$slots.zotloActivationInfo" class="flex flex-col">
                <slot name="zotloActivationInfo"></slot>
              </div>
            </template>
            <div v-else-if="zotloActivationInfo.length">
              <template v-for="subEl of zotloActivationInfo" :key="subEl.id">
                <ElementWrapper
                  v-if="subEl.options.visible"
                  :el="subEl"
                  :page-options="pageOptions"
                  :page-data="pageData"
                />
              </template>
            </div>

            <div class="text-center">
              <PincodeCard @[btnClick]="copyPinCode(activationCode)">
                {{ activationCode }}
              </PincodeCard>
            </div>

            <div>
              <!-- zotloActivationCodeInstructionsTitle Slot -->
              <template v-if="isEditorMode">
                <div v-if="$slots.zotloActivationCodeInstructionsTitle" class="flex flex-col">
                  <slot name="zotloActivationCodeInstructionsTitle"></slot>
                </div>
              </template>
              <div v-else-if="zotloActivationCodeInstructionsTitle.length">
                <template v-for="subEl of zotloActivationCodeInstructionsTitle" :key="subEl.id">
                  <ElementWrapper
                    v-if="subEl.options.visible"
                    :el="subEl"
                    :page-options="pageOptions"
                    :page-data="pageData"
                  />
                </template>
              </div>

              <!-- zotloActivationCodeInstructionsDescription Slot -->
              <template v-if="isEditorMode">
                <div v-if="$slots.zotloActivationCodeInstructionsDescription" class="flex flex-col">
                  <slot name="zotloActivationCodeInstructionsDescription"></slot>
                </div>
              </template>
              <div v-else-if="zotloActivationCodeInstructionsDescription.length">
                <template v-for="subEl of zotloActivationCodeInstructionsDescription" :key="subEl.id">
                  <ElementWrapper
                    v-if="subEl.options.visible"
                    :el="subEl"
                    :page-options="pageOptions"
                    :page-data="pageData"
                  />
                </template>
              </div>
            </div>
          </div>
          <div class="tw-payment-result-element__cards-item-seperator h-48 md:h-[62.5px]"></div>
        </template>
        <template v-else-if="userActivationType === UserActivationType.OWN_ACTIVATION_CODE">
          <div class="tw-payment-result-element__cards-item flex flex-col gap-16 !px-16 md:!p-32">
            <!-- yourOwnActivationInfo Slot -->
            <template v-if="isEditorMode">
              <div v-if="$slots.yourOwnActivationInfo" class="flex flex-col">
                <slot name="yourOwnActivationInfo"></slot>
              </div>
            </template>
            <div v-else-if="yourOwnActivationInfo.length">
              <template v-for="subEl of yourOwnActivationInfo" :key="subEl.id">
                <ElementWrapper
                  v-if="subEl.options.visible"
                  :el="subEl"
                  :page-options="pageOptions"
                  :page-data="pageData"
                />
              </template>
            </div>

            <div>
              <!-- yourOwnActivationCodeInstructionsTitle Slot -->
              <template v-if="isEditorMode">
                <div v-if="$slots.yourOwnActivationCodeInstructionsTitle" class="flex flex-col">
                  <slot name="yourOwnActivationCodeInstructionsTitle"></slot>
                </div>
              </template>
              <div v-else-if="yourOwnActivationCodeInstructionsTitle.length">
                <template v-for="subEl of yourOwnActivationCodeInstructionsTitle" :key="subEl.id">
                  <ElementWrapper
                    v-if="subEl.options.visible"
                    :el="subEl"
                    :page-options="pageOptions"
                    :page-data="pageData"
                  />
                </template>
              </div>

              <!-- yourOwnActivationCodeInstructionsDescription Slot -->
              <template v-if="isEditorMode">
                <div v-if="$slots.yourOwnActivationCodeInstructionsDescription" class="flex flex-col">
                  <slot name="yourOwnActivationCodeInstructionsDescription"></slot>
                </div>
              </template>
              <div v-else-if="yourOwnActivationCodeInstructionsDescription.length">
                <template v-for="subEl of yourOwnActivationCodeInstructionsDescription" :key="subEl.id">
                  <ElementWrapper
                    v-if="subEl.options.visible"
                    :el="subEl"
                    :page-options="pageOptions"
                    :page-data="pageData"
                  />
                </template>
              </div>
            </div>
          </div>
          <div class="tw-payment-result-element__cards-item-seperator h-48 md:h-[62.5px]"></div>
        </template>
        <template v-else>
          <div class="tw-payment-result-element__cards-item">
            <!-- Sign in info Slot -->
            <template v-if="isEditorMode">
              <div v-if="$slots.signInInfo" class="flex flex-col">
                <slot name="signInInfo"></slot>
              </div>
            </template>
            <div v-else-if="signInInfo.length">
              <template v-for="subEl of signInInfo" :key="subEl.id">
                <ElementWrapper
                  v-if="subEl.options.visible"
                  :el="subEl"
                  :page-options="pageOptions"
                  :page-data="pageData"
                />
              </template>
            </div>

            <!-- SubscriberId Slot -->
            <template v-if="isEditorMode">
              <div v-if="$slots.subscriberId" class="flex flex-col">
                <slot name="subscriberId"></slot>
              </div>
            </template>
            <div v-else-if="subscriberId.length">
              <template v-for="subEl of subscriberId" :key="subEl.id">
                <ElementWrapper
                  v-if="subEl.options.visible"
                  :el="subEl"
                  :page-options="pageOptions"
                  :page-data="pageData"
                />
              </template>
            </div>
          </div>
          <div class="tw-payment-result-element__cards-item-seperator h-48 md:h-[62.5px]"></div>
        </template>
      </template>

      <div class="tw-payment-result-element__cards-item">
        <!-- Extra Info -->
        <template v-if="isEditorMode">
          <div v-if="$slots.extraInfo" class="flex flex-col">
            <slot name="extraInfo"></slot>
          </div>
        </template>
        <div v-else-if="extraInfo.length">
          <template v-for="subEl of extraInfo" :key="subEl.id">
            <ElementWrapper
              v-if="subEl.options.visible"
              :el="subEl"
              :page-options="pageOptions"
              :page-data="pageData"
            />
          </template>
        </div>
      </div>

      <PaymentDetails
        v-if="isSuccessMode"
        :config="config"
        :is-editor-mode="isEditorMode"
        :page-data="pageData"
        :page-options="pageOptions"
        :locale="locale"
        class="tw-payment-result-element__cards-item mt-48 !px-16 md:mt-[62.5px] md:!px-32"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { getElementsBySlot } from '@shared/utils';
import { getAssetFromCdn, hexToRgba, globalEmit, copyToClipboard } from '@shared/utils/helpers';
import PaymentDetails from '@shared/elements/payment-result/components/payment-details.vue';
import PincodeCard from '@shared/elements/payment-result/components/pincode-card.vue';
import { storeLinkDefs, type StoreLinkKey } from './definitions';
import { UserActivationType } from '@shared/types';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['payment-result']>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'payment-result'>>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const cardsItemStyle = computed(() => {
  const cardOptions = props.config.cards || {};

  return {
    backgroundColor: hexToRgba(cardOptions.background?.color || '', cardOptions.background?.opacity),
    backgroundOpacity: `${cardOptions.background?.opacity || 0}%`,
    borderRadius: `${cardOptions.border?.radius || 0}px`,
    borderWidth: `${cardOptions.border?.width || 0}px`,
    borderColor: cardOptions.border?.color
  };
});

const appLinks = computed(() => props.pageData.applicationLinks || {});
const isSuccessMode = computed(() => !!props.pageData.isSuccessMode);
const isEpinViewActive = computed(() => props.pageData?.isEpinViewActive || false);
const userActivationType = computed(() => props.pageData?.userActivationType || UserActivationType.EMAIL_AND_PHONE);
const defaultEpinCode = '------';
const defaultActivationCode = '---------';
const epinCode = computed(() => (props.isEditorMode ? defaultEpinCode : props.pageData?.epinCode || defaultEpinCode));
const activationCode = computed(() => (props.isEditorMode ? defaultActivationCode : props.pageData?.activationCode || defaultActivationCode));
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));

const hasActiveStoreButtons = computed(() => {
  const links: Record<string, any> = props.element.options?.appLinks?.show || {};
  return !!Object.keys(links || {}).filter((key: string) => (key === 'generic' ? false : !!links[key])).length;
});

function getStoreButtonShowStatus(key: StoreLinkKey) {
  const hasLink = props.isEditorMode ? true : !!appLinks.value?.[storeLinkDefs[key].urlKey];
  return key !== 'generic' && !!props.config.appLinks?.show?.[key] && hasLink;
}

function getStoreBadgeImage(key: string, options?: { isDark: boolean }) {
  const { isDark = false } = options || {};
  const dark_suffix = isDark ? '_dark' : '';
  return getAssetFromCdn(`editor/store-badges/${key}${dark_suffix}.png`);
}

function copyPinCode(code: string) {
  copyToClipboard(code);
  globalEmit('pinCodeCopied', code);
}

// SLOTS
const slotTitleText = computed(() => getElementsBySlot(props.element.elements, 'titleText'));
const slotTitleDesc = computed(() => getElementsBySlot(props.element.elements, 'titleDesc'));
// Epin Specific Card Slots
const epinTitleText = computed(() => getElementsBySlot(props.element.elements, 'epinTitleText'));
const epinInstructionTitleText = computed(() => getElementsBySlot(props.element.elements, 'epinInstructionTitleText'));
const epinInstructionDescription = computed(() =>
  getElementsBySlot(props.element.elements, 'epinInstructionDescription')
);
// Zotlo Activation Card Slots
const zotloActivationInfo = computed(() => getElementsBySlot(props.element.elements, 'zotloActivationInfo'));
const zotloActivationCodeInstructionsTitle = computed(() => getElementsBySlot(props.element.elements, 'zotloActivationCodeInstructionsTitle'));
const zotloActivationCodeInstructionsDescription = computed(() =>
  getElementsBySlot(props.element.elements, 'zotloActivationCodeInstructionsDescription')
);
// Your Own Activation Card Slots
const yourOwnActivationInfo = computed(() => getElementsBySlot(props.element.elements, 'yourOwnActivationInfo'));
const yourOwnActivationCodeInstructionsTitle = computed(() => getElementsBySlot(props.element.elements, 'yourOwnActivationCodeInstructionsTitle'));
const yourOwnActivationCodeInstructionsDescription = computed(() =>
  getElementsBySlot(props.element.elements, 'yourOwnActivationCodeInstructionsDescription')
);
// Card 1 Slots
const accessLinkInfoText = computed(() => getElementsBySlot(props.element.elements, 'accessLinkInfoText'));
const slotDefault = computed(() => getElementsBySlot(props.element.elements));
// Card 2 Slots
const signInInfo = computed(() => getElementsBySlot(props.element.elements, 'signInInfo'));
const subscriberId = computed(() => getElementsBySlot(props.element.elements, 'subscriberId'));
// Card 3 Slots
const extraInfo = computed(() => getElementsBySlot(props.element.elements, 'extraInfo'));
</script>

<style lang="postcss" scoped>
.tw-payment-result-element {
  --card-bgColor: v-bind(cardsItemStyle.backgroundColor);
  --card-bgOpacity: v-bind(cardsItemStyle.backgroundOpacity);
  --card-borderWidth: v-bind(cardsItemStyle.borderWidth);
  --card-borderColor: v-bind(cardsItemStyle.borderColor);
  --card-borderRadius: v-bind(cardsItemStyle.borderRadius);

  &__cards {
    &-item {
      @apply relative mx-auto max-w-[587px] p-32;
      border-width: var(--card-borderWidth, '1px');
      border-radius: var(--card-borderRadius, '6px');
      border-color: var(--card-borderColor, '#C3C9D5');
      background-color: var(--card-bgColor);
      &-seperator {
        @apply m-auto flex w-[1px] items-center justify-center;
        background-color: var(--card-borderColor, '#C3C9D5');
        &:before {
          @apply h-[10px] w-[10px] shrink-0 rounded-full content-[''];
          background-color: var(--card-borderColor, '#C3C9D5');
        }
      }
    }
  }

  .tw-app-links-element {
    &__main-button-wrapper {
      @apply block justify-center;
    }
    &__store-buttons {
      @apply relative mt-16 flex flex-wrap justify-center gap-16;
      img {
        @apply max-h-[34px];
      }
    }
  }
}
</style>

import type { QuizResponse } from "@/types/quiz";
import { api } from "./api";

export type ResponseMeta = {
  requestId: string;
  httpStatus: number;
  errorCode?: string;
  message?: string;
}

export type ResponseObject = {
  meta: ResponseMeta;
  result: QuizResponse;
}

export type FileResponse = {
  meta: ResponseMeta
  result: {
    expireAt: string;
    fileName: string;
    fileUrl: string;
    size: number;
    type: string;
  }[]
}

export type LocationResponse = {
  meta: ResponseMeta;
  result: {
    expireAt: string;
    data: {
      id: number;
      value: string;
      mainValue: string;
      type: string;
      longitude: string;
      latitude: string;
    }[];
  }
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Quiz {
  static BASE_PATH = 'quiz'

  static async init(headers?: Record<string, any>) {
    const response = await api.get(`${Quiz.BASE_PATH}/init`, headers);
    return response.data as ResponseObject
  }

  static async nextQuestion(currentQuestionId: number, headers?: Record<string, any>) {
    const response = await api.get(`${Quiz.BASE_PATH}/next?currentQuestionId=${currentQuestionId}`, headers);
    return response.data as ResponseObject;
  }

  static async prevQuestion(currentQuestionId: number, headers?: Record<string, any>) {
    const response = await api.get(`${Quiz.BASE_PATH}/previous?currentQuestionId=${currentQuestionId}`, headers);
    return response.data as ResponseObject;
  }

  static async fileUpload(payload: FormData, headers?: Record<string, any>) {
    const questionId = payload?.get('questionId');
    const endpoint = `media/upload/${questionId}`
    const response = await api.post(`${Quiz.BASE_PATH}/${endpoint}`, payload, headers);
    return response.data as FileResponse;
  }

  static async saveAnswers(payload: {
    questionId: number;
    answer: {
      options: number[];
      input: string;
    }
  }, headers?: Record<string, any>) {
    const response = await api.post(`${Quiz.BASE_PATH}/answer`, payload, headers);
    return response.data as ResponseObject;
  }

  static async getLocation(payload: {
    text: string;
    type: 'city' | 'country';
  }, headers?: Record<string, any>) {
    const { text, type } = payload;
    const response = await api.get(`${Quiz.BASE_PATH}/location?text=${text}&type=${type}`, headers);
    return {
      ...response.data,
      result: Array.isArray(response.data?.result)
        ? { data: response.data.result }
        : response.data?.result,
    } as LocationResponse;
  }
}

export default Quiz;

<template>
  <div class="tw-moneyback-element">
    <img
      :src="config.icon"
      alt="icon"
      class="mx-auto mb-24 max-h-[120px] max-w-[160px] lg:mb-32"
    />

    <div class="flex flex-col gap-16">
      <!-- Title Text Slot -->
      <template v-if="isEditorMode">
        <div v-if="$slots.titleText">
          <slot name="titleText"></slot>
        </div>
      </template>
      <div v-else-if="slotTitleText.length">
        <template v-for="subEl of slotTitleText" :key="subEl.id">
          <ElementWrapper
            v-if="subEl.options.visible"
            :el="subEl"
            :page-options="pageOptions"
            :page-data="pageData"
          />
        </template>
      </div>

      <!-- Title Desc Slot -->
      <template v-if="isEditorMode">
        <div v-if="$slots.titleDesc">
          <slot name="titleDesc"></slot>
        </div>
      </template>
      <div v-else-if="slotTitleDesc.length">
        <template v-for="subEl of slotTitleDesc" :key="subEl.id">
          <ElementWrapper
            v-if="subEl.options.visible"
            :el="subEl"
            :page-options="pageOptions"
            :page-data="pageData"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { hexToRgba } from '@shared/utils/helpers';
import { getElementsBySlot } from '@shared/utils';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['moneyback']>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'moneyback'>>, default: () => ({}) },
});

const cardItemStyle = computed(() => {
  const cardOptions = props.config.card || {};

  return {
    backgroundColor: hexToRgba(cardOptions.background?.color || '', cardOptions.background?.opacity),
    backgroundOpacity: `${cardOptions.background?.opacity || 0}%`,
    borderRadius: `${cardOptions.border?.radius || 0}px`,
    borderWidth: `${cardOptions.border?.width || 0}px`,
    borderColor: cardOptions.border?.color
  };
});

const slotTitleText = computed(() => getElementsBySlot(props.element.elements, 'titleText'));
const slotTitleDesc = computed(() => getElementsBySlot(props.element.elements, 'titleDesc'));
</script>

<style lang="postcss" scoped>
.tw-moneyback-element {
  --card-bgColor: v-bind(cardItemStyle.backgroundColor);
  --card-bgOpacity: v-bind(cardItemStyle.backgroundOpacity);
  --card-borderWidth: v-bind(cardItemStyle.borderWidth);
  --card-borderColor: v-bind(cardItemStyle.borderColor);
  --card-borderRadius: v-bind(cardItemStyle.borderRadius);

  @apply !mx-auto max-w-[940px] p-32;
  border-width: var(--card-borderWidth, '1px');
  border-radius: var(--card-borderRadius, '6px');
  border-color: var(--card-borderColor, '#C3C9D5');
  background-color: var(--card-bgColor) !important;
}
</style>

import { ref, computed } from 'vue'
import { useTranslate } from "@shared/composable/useTranslate";

export function useApplyLinks(props: Record<string, any>) {
  const { translate } = useTranslate();
  const countryCode = props.pageData?.countryCode;
  const locale = props.locale;

  const isRussia = countryCode === 'RU';
  const russianKey = 'generate.legalDocInfoElement.expandedRussiaAdditional';
  const RUSSIA_DOCUMENTS = {
    privacy_policy: 'https://web-assets.cdnztl.com/docs/jigle-privacy-policy-ru-2025-01-24.docx.pdf',
    terms_of_service: 'https://web-assets.cdnztl.com/docs/jigle-terms-of-service-ru-v2.docx.pdf',
    payment_aggregator: 'https://web-assets.cdnztl.com/docs/jigle-payment-terms-ru.pdf',
  }

  const LINKS_BY_ATTR = {
    "data-privacy-policy-link": props.pageData?.applicationLinks?.privacyUrl,
    "data-terms-of-use-link": props.pageData?.applicationLinks?.termsOfServiceUrl,
    "data-customer-service-link": props.pageData?.applicationLinks?.customerSupportUrl,
    "data-generic-app-link": props.pageData?.applicationLinks?.genericDownloadUrl,
    "data-zotlo-privacy-policy-link": isRussia ? RUSSIA_DOCUMENTS.privacy_policy : props.pageData?.docLinks?.[locale]?.privacy_policy,
    "data-zotlo-terms-of-use-link": isRussia ? RUSSIA_DOCUMENTS.terms_of_service : props.pageData?.docLinks?.[locale]?.terms_of_service,
    "data-payment-aggregator": RUSSIA_DOCUMENTS.payment_aggregator,
  }  

  const params = {
    additional: isRussia ? `<p>${translate(russianKey, props.locale)}</p>` : '',
    ...props.element.options?.params
  }

  const translated = translate(props.value, props.locale, params);
  
  const textValue = ref(
    props.isEditorMode
      ? translated
      : Object.entries(LINKS_BY_ATTR).reduce((acc, [key, value]) => {
          return acc.replace(new RegExp(key, 'g'), `${key} href="${value || '#'}" target="_blank"`)
        }, translated)
  );

  const linksAttrs = computed(() => {
    if (props.isEditorMode || props.element.tagName !== 'a') return {};

    return Object.entries(props.element.attr)
      .filter(([key]) => /^data-/.test(key))
      .reduce((acc, item) => {
        const key = item[0] as keyof typeof LINKS_BY_ATTR;
        const val = LINKS_BY_ATTR[key];
        if (val) {
          acc['href'] = val || '#';
          acc['target'] = '_blank'
        }
        return acc;
      }, {} as Record<string, any>)
  })

  return {
    textValue,
    linksAttrs,
  }
}

export type StoreLinkKey = 'generic' | 'apple' | 'google' | 'amazon' | 'microsoft' | 'huawei';

export const storeLinkDefs: Record<StoreLinkKey, { urlKey: string; storeName: string }> = {
  generic: { urlKey: 'genericDownloadUrl', storeName: 'Generic App' },
  apple: { urlKey: 'appStoreUrl', storeName: 'App Store' },
  google: { urlKey: 'googlePlayStoreUrl', storeName: 'Google Play Store' },
  amazon: { urlKey: 'amazonStoreUrl', storeName: 'Amazon Store' },
  microsoft: { urlKey: 'microsoftStoreUrl', storeName: 'Microsoft Store' },
  huawei: { urlKey: 'huaweiAppGalleryUrl', storeName: 'Huawei App Gallery' }
};

export default storeLinkDefs;

<template>
  <component
    :is="buttonTagName"
    :id="element.id"
    role="button"
    class="tw-element-button"
    :class="buttonClass"
    :style="buttonStyle"
    :disabled="isDisabled || isLoading"
    v-bind="{ ...$attrs, ...attr, ...linksAttrs }"
  >
    <template v-if="isLoading">
      <WebSpinner
        size="20"
        color="transparent"
        background="currentColor"
        class="absolute left-[50%] ml-[-10px]"
      />
      <span class="opacity-0">{{ textValue }}</span>
    </template>
    <template v-else>
      {{ textValue }}
    </template>
  </component>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import type { Element as ElementType, PageOptions } from '../../types/model';
import { WebSpinner } from '@shared/components';
import { $wait } from '@shared/utils/wait';
import { useConditionalValue } from '@shared/composable/useConditionalValue';
import { useApplyLinks } from '@shared/composable/useApplyLinks';

const props = defineProps({
  tagName: { type: String, default: 'a' },
  value: { type: String, default: '' },
  attr: { type: Object as PropType<ElementType<'button'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'button' | 'conditional-button'>>, default: () => ({}) },
  hover: { type: Object as PropType<ElementType<'button'>['style']['hover']>, default: () => ({}) },
  style: { type: Object as PropType<ElementType<'button'>['style']>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  pageData: { type: Object, default: () => ({ isLoading: false, isDisabled: undefined }) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const { textValue, condition } = useConditionalValue(props);
const { linksAttrs } = useApplyLinks(props);

const isLoading = computed(() =>
  props.pageData.isLoading ||
  props.pageData.isSubmitLoading ||
  $wait?.is(['nextQuestion', 'prevQuestion']) ||
  false
);
const isDisabled = computed(() => props.pageData.isDisabled || props.pageData.isSubmitDisabled);

const currentConditon = computed(() => {
  return (props.element as ElementType<'conditional-button'>).options.conditionalOptions?.[condition.value];
});

const buttonClass = computed(() => {
  return {
    'tw-element-button--loading': isLoading.value,
    'tw-element-button--disabled': isDisabled.value && !isLoading.value
  };
});

const buttonStyle = computed(() => {
  let color = props.style.color || props.pageOptions.colors?.text[2];
  const backgroundColor = props.style.backgroundColor || props.pageOptions.colors?.theme[0];
  const borderRadius = props.style.borderRadius || `${props.pageOptions.borderRadius}px`;
  let fontFamily = props.style.fontFamily || props.pageOptions.fontFamily;
  let fontSize = props.style.fontSize || props.pageOptions.fontFamily;
  let fontWeight = props.style.fontWeight;
  let fontStyle = props.style.fontStyle;
  let textDecoration = props.style.textDecoration;
  let textAlign = props.style.textAlign;
  let justifyContent = props.style.justifyContent;

  if (props.element.type === 'conditional-button') {
    color = currentConditon.value?.style.color || props.pageOptions.colors?.text[2];
    fontFamily = currentConditon.value?.style.fontFamily || props.pageOptions.fontFamily;
    fontSize = currentConditon.value?.style.fontSize || props.pageOptions.fontFamily;
    fontWeight = currentConditon.value?.style.fontWeight;
    fontStyle = currentConditon.value?.style.fontStyle;
    textDecoration = currentConditon.value?.style.textDecoration;
    textAlign = currentConditon.value?.style.textAlign;
    justifyContent = currentConditon.value?.style.justifyContent;
  }

  return {
    ...props.style,
    color,
    backgroundColor,
    borderRadius,
    fontFamily,
    fontSize,
    fontWeight,
    fontStyle,
    textDecoration,
    textAlign,
    justifyContent
  };
});

const buttonHoverStyle = computed(() => {
  let color = props.hover?.color || props.pageOptions.colors?.text[2];
  const backgroundColor = props.hover?.backgroundColor || props.pageOptions.colors?.theme[1];

  if (props.element.type === 'conditional-button') {
    color = currentConditon.value?.style?.hover?.color || props.pageOptions.colors?.text[2];
  }

  return {
    ...props.hover,
    color,
    backgroundColor
  };
});

/**
 * In editor mode we must always use 'a' tag instead of 'button',
 * Because contenteditable for 'button' tags is not working properly.
 */
const buttonTagName = computed(() => (props.isEditorMode ? 'a' : props.tagName));
</script>

<style lang="postcss" scoped>
.tw-element-button {
  display: inline-flex;
  border-style: solid;
  padding: 8px 16px;
  color: #818a9c;
  text-decoration: none;
  min-height: 48px;
  max-width: 100%;
  word-break: break-word;
  overflow: hidden;
  transition: color 0.125s linear, background-color 0.125s linear, border-color 0.125s linear;
  white-space: break-spaces;

  &:not(.tw-element-button--disabled, .tw-element-button--loading):hover {
    color: v-bind('buttonHoverStyle?.color') !important;
    background-color: v-bind('buttonHoverStyle?.backgroundColor') !important;
    border-color: v-bind('buttonHoverStyle?.borderColor') !important;
  }

  &.tw-element-button--disabled {
    @apply !cursor-not-allowed !bg-neutral-200 !text-white;
  }

  &.tw-element-button--loading {
    @apply !cursor-not-allowed;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
</style>

<style lang="postcss">
/* This style must be global for styling buttons under disabled slots.
 * tw-slot-wrapper--disabled class can be used for slots.
 * For example continue buttons on questions
 */
.tw-slot-wrapper--disabled {
  .tw-element-button,
  .tw-element-button:not(.tw-element-button--disabled, .tw-element-button--loading):hover {
    @apply !cursor-not-allowed !select-none !bg-neutral-200 !text-white;
  }
}
</style>

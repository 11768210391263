import { COOKIE } from "../cookie";

const logSyle =
  "background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";

type GoogleAds = {
  isActive: 0 | 1;
  gTag: string;
  conversionId: string;
  conversionLabel: string;
}

export const GA4 = {
  options: {
    gaCode: null as null | string,
    googleAds: {} as GoogleAds,
    debug: false,
  },
  hasConsent: () => {
    const consent = getNamedCookie(COOKIE.COOKIE_CONSENT);
    return consent === 'true'
  },

  log(...args: any[]) {
    if (this.options.debug && import.meta.client) {
      console.log(`%c${(this as any).name || 'GA4'}`, logSyle, ...args);
    }
  },

  gtag(...args: any[]) {
    if (!this.options.gaCode && !this.options.googleAds.gTag) return;
    if ((globalThis as any).gtag) {
      (globalThis as any).gtag(...args);
    }
    this.log.bind({ ...this, name: 'GA4' })('gtag', args)
  },

  consent(granted: boolean) {
    const value = granted ? 'granted': 'denied';
    this.gtag('consent', 'update', {
      ad_storage: value,
      ad_user_data: value,
      ad_personalization: value,
      analytics_storage: value,
    });
  },

  getConversionLabel() {
    const { conversionId, conversionLabel } = this.options.googleAds || {};
    return `${conversionId}/${conversionLabel}`;
  },

  init(payload: { gaCode: string, debug?: boolean, googleAds?: GoogleAds; }) {
    if (import.meta.server) return false;
    if (this.options.gaCode) return false
    if (!payload.gaCode) {
      console.warn('GA4 cannot be installed because there is no GA4 Code!');
      if (!payload.googleAds?.isActive) return;
    }

    if (payload.googleAds?.isActive && !payload.googleAds.gTag) {
      console.warn('GTag cannot be installed because there is no Gtag Code!');

      if (!payload.gaCode) return;
    }

    this.options.gaCode = payload.gaCode;
    this.options.debug = !!payload.debug;
    if (payload.googleAds) this.options.googleAds = payload.googleAds;

    if (this.options.debug) {
      this.log.bind({ ...this, name: 'GA4' })('init', this.options.gaCode);
      return;
    }

    if (globalThis.document) {
      (globalThis as any).dataLayer = (globalThis as any).dataLayer || [];

      (function (w: any, d, s, l, i) {
        w[l] = w[l] || [];
        w.gtag = function gtag(...args: any[]){ w[l].push(args); }
        w.gtag('js', new Date());
        // eslint-disable-next-line no-var
        for (var n = 0; n < i.length; n++) {
          if (i[n]) w.gtag('config', i[n]);
        }

        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s) as HTMLScriptElement;

        j.async = true;
        j.src = `https://www.googletagmanager.com/gtag/js?id=${i[0] || i[1]}`;
        f.parentNode?.insertBefore(j, f);
      })(globalThis, globalThis.document, "script", "dataLayer", [this.options.gaCode, this.options.googleAds?.gTag]);
    }
  },

  initOnSSR(payload: {
    gaCode: string;
    granted?: boolean;
    debug?: boolean;
    googleAds?: GoogleAds;
  }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (!payload.gaCode) {
      console.warn('GA4 cannot be installed because there is no GA4 Code!');
      if (!payload.googleAds?.isActive) return headScripts;
    }
    
    if (payload.googleAds?.isActive && !payload.googleAds.gTag) {
      console.warn('GTag cannot be installed because there is no Gtag Code!');

      if (!payload.gaCode) return headScripts;
    }

    this.options.gaCode = payload.gaCode;
    this.options.debug = !!payload.debug;
    if (payload.googleAds) this.options.googleAds = payload.googleAds;

    const initTagId = this.options.gaCode || this.options.googleAds?.gTag;

    if (this.options.debug) {
      this.log.bind({ ...this, name: 'GA4' })('init', initTagId);
      return headScripts;
    }

    let isDefatulGranted = 'denied';
    if (payload.granted || this.hasConsent()) isDefatulGranted = 'granted';

    const configs = [this.options.gaCode, this.options.googleAds?.gTag]
      .filter(Boolean)
      .map((code) => `gtag('config', '${code}');`)
      .join('\n');

    headScripts.script = [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${initTagId}`,
        async: true,
      },
      {
        innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        ${
          payload.granted
           ? ''
           : `gtag('consent', 'default', {
          'ad_storage': '${isDefatulGranted}',
          'ad_user_data': '${isDefatulGranted}',
          'ad_personalization': '${isDefatulGranted}',
          'analytics_storage': '${isDefatulGranted}'
        });`
        }
        gtag('js', new Date());
        ${configs}`
      }
    ];

    return headScripts;
  }
};

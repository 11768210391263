<template>
  <div v-click-outside="onClickOutside" class="tw-select relative">
    <div>
      <WebInput
        ref="locationPickerInput"
        v-model.trim="searchValue"
        type="search"
        class="mb-0"
        autocomplete="off"
        :name="name"
        :placeholder="placeholder"
        :rules="isRequired"
        :page-options="pageOptions"
        :label="label"
        :label-style="labelStyle"
        :validate-on-update="true"
        @focus="onFocus"
      >
        <template #right>
          <svg
            v-if="isArrowUp"
            version="1.1"
            role="presentation"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            class="text-neutral-500"
          >
            <path
              d="M15.8727 15.0025L11.9927 11.1225L8.11266 15.0025C7.72266 15.3925 7.09266 15.3925 6.70266 15.0025C6.31266 14.6125 6.31266 13.9825 6.70266 13.5925L11.2927 9.00246C11.6827 8.61246 12.3127 8.61246 12.7027 9.00246L17.2927 13.5925C17.6827 13.9825 17.6827 14.6125 17.2927 15.0025C16.9027 15.3825 16.2627 15.3925 15.8727 15.0025Z"
              fill="currentColor"
            />
          </svg>
          <svg
            v-else
            version="1.1"
            role="presentation"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            class="text-neutral-500"
          >
            <path
              d="M8.12266 9.00246L12.0027 12.8825L15.8827 9.00246C16.2727 8.61246 16.9027 8.61246 17.2927 9.00246C17.6827 9.39246 17.6827 10.0225 17.2927 10.4125L12.7027 15.0025C12.3127 15.3925 11.6827 15.3925 11.2927 15.0025L6.70266 10.4125C6.31266 10.0225 6.31266 9.39246 6.70266 9.00246C7.09266 8.62246 7.73266 8.61246 8.12266 9.00246Z"
              fill="currentColor"
            />
          </svg>
        </template>
      </WebInput>
    </div>

    <div
      v-if="isArrowUp"
      class="absolute top-full z-10 flex max-h-[320px] w-full flex-col overflow-auto rounded-8 bg-white px-8 py-12 shadow-md"
    >
      <div v-if="searchValue.length < MIN_SEARCH_LENGTH" class="tw-select-disabled">
        {{ translate('generate.templates.questions.locationPicker.inputWarning', locale) }}
      </div>
      <WebSpinner
        v-if="isLoading && searchValue.length >= MIN_SEARCH_LENGTH"
        class="mt-4 flex justify-center"
        size="20"
      />

      <template v-if="isOptionList">
        <div
          v-for="item in optionList"
          :key="item.id"
          type="button"
          variant="text"
          class="tw-select-item"
          @click="handleSelection(item)"
        >
          {{ item.value }}
        </div>
      </template>
      <template v-if="noResults">
        <div class="tw-select-disabled">
          {{ translate('generate.common.noResult', locale) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed, type PropType, nextTick, onBeforeMount, onBeforeUnmount } from 'vue';
import type { Option } from './types';
import { useTranslate } from '@shared/composable/useTranslate';
import WebSpinner from '@shared/components/spinner/index.vue'
import WebInput from '@shared/components/input/index.vue';
import { globalEmit, globalListener, globalRemoveListener, debounce, isClient } from '@shared/utils/helpers';
import type { PageOptions } from '@shared/types/model';

const emit = defineEmits(['update:modelValue'])
const { translate } = useTranslate();
const props = defineProps({
  locale: { type: String, default: '' },
  modelValue: { type: Object, default: () => ({}) },
  type: { type: String, default: 'country' },
  name: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  label: { type: String, default: '' },
  labelStyle: { type: Object, default: () => ({})},
});

const isArrowUp = ref(false);
const isFirstLoad = ref(true);
const optionList = ref<Option[]>([]);
const searchValue = ref('');
const MIN_SEARCH_LENGTH = 3;
const isLoading = ref(false);
const selectedItem = ref<Option>({
  id: 0,
  value: ''
});
const locationPickerInput = ref();

const showDropdown = computed(() => {
  return searchValue.value && isArrowUp.value;
});

const isRequired = computed(() => {
  if (isFirstLoad.value && !selectedItem.value.value) {
    return 'required';
  }
  return '';
});

const onFocus = () => {
  isArrowUp.value = true;
  searchValue.value = '';
};

const noResults = computed(() => {
  return (
    !isLoading.value &&
    !isFirstLoad.value &&
    optionList.value.length === 0 &&
    searchValue.value.length >= MIN_SEARCH_LENGTH
  );
});

const isOptionList = computed(() => {
  return (
    optionList.value.length > 0 &&
    showDropdown.value &&
    !isLoading.value &&
    searchValue.value.length >= MIN_SEARCH_LENGTH
  );
});

const selectStyle = computed(() => {
  return {
    spinner: {
      bgColor: props.pageOptions?.colors?.theme[0]
    }
  }
})

function onClickOutside() {
  nextTick(() => {
    locationPickerInput.value?.el?.blur();
  });
  isArrowUp.value = false;
  if (selectedItem.value.value) {
    searchValue.value = selectedItem.value.value;
  } else {
    searchValue.value = '';
  }
  isLoading.value = false;
}

const waitForSearch = debounce(() => {
  globalEmit('searchLocation', [{}, {
    text: searchValue.value,
    type: props.type
  }])
}, 400);

function onGetLocation(result: Option[]) {
  optionList.value = result;
  isLoading.value = false;
}

if (isClient()) {
  watch(
    () => [searchValue.value],
    async () => {
      if (searchValue.value === selectedItem.value.value) {
        return false;
      }
  
      if (searchValue.value.length < MIN_SEARCH_LENGTH) {
        return;
      } else {
        isLoading.value = true;
        await waitForSearch();
        isFirstLoad.value = false;
      }
    }
  );
}

const handleSelection = (item: Option) => {
  selectedItem.value = item;
  isArrowUp.value = false;
  searchValue.value = item.value;
  emit('update:modelValue', selectedItem.value);
};

function checkModelValue() {
  const item = props.modelValue as Option;
  if (item?.value) {
    selectedItem.value = item;
    isArrowUp.value = false;
    searchValue.value = item.value;
  }
}

onBeforeMount(() => {
  globalListener('getLocation', onGetLocation);
  checkModelValue();
});

onBeforeUnmount(() => {
  globalRemoveListener('getLocation', onGetLocation);
})
</script>

<style lang="postcss" scoped>
.tw-select {
  --spinner-bgColor: v-bind(selectStyle.spinner.bgColor);

  :deep(.tw-spinner) {
    border-color: var(--spinner-bgColor, #56C4D6);
    border-left-color: transparent;
  }

  :deep(.tw-state-normal-input) {
    @apply h-full;
  }

  &-item {
    @apply flex flex-[0_0_auto] h-auto max-h-[inherit] min-h-[48px] cursor-pointer
      items-center rounded-8 p-12 text-left text-14 text-neutral-900 hover:no-underline
      focus:border-0 focus-visible:outline-none disabled:text-neutral-300;

    &:not(:disabled) {
      @apply hover:bg-neutral-100 hover:text-neutral-900 focus:bg-neutral-100 focus:text-neutral-900;
    }
  }

  &-disabled {
    @apply items-center rounded-8 p-12 text-left text-neutral-900 text-14;
  }
}
::-webkit-scrollbar {
  @apply w-4;
}

::-webkit-scrollbar-thumb {
  @apply rounded-8 bg-neutral-200;
}
</style>

export enum ConditionType {
  REGISTER = 'register',
  EMAIL_OTP = 'register_with_email_otp',
  SMS_OTP = 'register_with_sms_otp'
}

export enum OTP_ERROR {
  TIMEOUT_IS_NOT_FINISHED = "403242",
  REACHED_SENDING_OTP_LIMIT = "429001"
}

export const OTP_MAX_TIME = 180;

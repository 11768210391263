<template>
  <div class="tw-plan-selection-element">
    <div id="plans" class="tw-plan-selection-element-wrapper">
      <div class="mb-32 mt-16 flex flex-col gap-24">
        <PlanItem
          v-for="(order, index) in plansCount"
          :key="`PlanItem_${order}`"
          :order="order"
          :selected="index === selectedPlanIndex"
          :element="element"
          :config="config"
          :page-options="pageOptions"
          :page-data="pageData"
          :is-editor-mode="isEditorMode"
          :package-price-info="packagePriceInfo"
          @[btnClick]="handleSelect(index)"
        />
      </div>

      <!-- Continue Button -->
      <div class="md:flex md:justify-center">
        <template v-if="isEditorMode">
          <div v-if="$slots.default" class="tw-plan-selection-element__submit-btn">
            <slot />
          </div>
        </template>
        <div v-else-if="slotDefault.length" class="tw-plan-selection-element__submit-btn">
          <template v-for="subEl of slotDefault" :key="subEl.id">
            <ElementWrapper
              v-if="subEl.options.visible"
              :el="subEl"
              :page-data="submitButtonProps"
              :page-options="pageOptions"
              @click="handleSubmit"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, type PropType, watch, nextTick, onMounted } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { $wait } from '@shared/utils/wait';
import ElementWrapper from '@shared/elements/wrapper.vue';
import PlanItem from './components/PlanItem.vue';
import { globalEmit, isClient } from '@shared/utils/helpers';
import { getElementsBySlot } from '@shared/utils';
import { applyTemplateVariables } from '@shared/utils/applyTemplateVariables';
import { getPackagesPrices } from '@shared/elements/common/payment/utils';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['plan-selection']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'plan-selection'>>, default: () => ({}) },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const selectedPlan = ref(props.config.plans?.defaultSelection);
const plansCount = computed(() => props.config.plans?.count?.current || 1);
const packagesArr = computed(() => props.pageData.packages || []);
const packagePriceInfo = computed(() => getPackagesPrices(packagesArr.value));
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const selectedPlanIndex = computed(() => {
  if (props.isEditorMode) return props.config.plans?.defaultSelection;
  return selectedPlan.value;
});

const slotDefault = computed(() => {
  return getElementsBySlot(props.element.elements);
});

const isSubmitLoading = computed(() => props.pageData?.isSubmitLoading || false);
const isSubmitDisabled = computed(() => props.pageData?.isSubmitDisabled || false);
const submitButtonProps = computed(() => ({
  isLoading: isSubmitLoading.value,
  isDisabled: isSubmitDisabled.value
}));

function handleSelect(planIndex: number) {
  selectedPlan.value = planIndex;
}

function prepareTemplateVariables(packagePriceInfo: Record<number, any>) {
  const selectedPackages = props.config.plans?.selectedPackages;
  return selectedPackages?.reduce((acc, packageId, index) => {
    const order = index + 1;
    const currentPackage = packagePriceInfo?.[packageId] || {};
    acc[`PRICE_${order}`] = String(currentPackage?.price) || "";
    acc[`TRIAL_PRICE_${order}`] = String(currentPackage?.trialPrice) || "";
    acc[`SAVING_${order}`] = String(currentPackage?.savePercent) || "";
    acc[`DAILY_PRICE_${order}`] = String(currentPackage?.dailyPrice) || "";
    acc[`WEEKLY_PRICE_${order}`] = String(currentPackage?.weeklyPrice) || "";
    return acc;
  }, {} as Record<string, string>);
}

function handleSubmit() {
  const planIndex = selectedPlanIndex.value || 0;
  const selectedPackageId = props.config.plans?.selectedPackages?.[planIndex];
  globalEmit('planSelectionSubmitted', selectedPackageId);
}

async function handleApplyTemplateVariables() {
  const hasPriceInfo = Object.keys(packagePriceInfo.value).length;
  if (hasPriceInfo) {
    const templateVariables = prepareTemplateVariables(packagePriceInfo.value);
    applyTemplateVariables(templateVariables || {}, [
      '.tw-plan-item__ribbon-box',
      '.tw-plan-item__container',
      '.tw-plan-item__highlight-text'
    ]);
  }
}

if (isClient()) {
  watch(
    () => props.pageData.packagesLoaded,
    async (isLoaded) => {
      if (isLoaded) {
        $wait.start('applyingTemplateVariables');
        setTimeout(() => {
          nextTick(handleApplyTemplateVariables)
          $wait.end('applyingTemplateVariables');
        }, 0);
      }
    }
  );
}

onMounted(() => {
  nextTick(handleApplyTemplateVariables)
})
</script>

<style lang="postcss" scoped>
.tw-plan-selection-element {
  @apply overflow-hidden;

  &-wrapper {
    scroll-margin-top: 100px;
    scroll-snap-align: center;
  }
}
</style>

import { api } from './api';

export type PageViewPayload = {
  fbp: string;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class FacebookCAPI {
  static BASE_PATH = ''

  static async send(payload: PageViewPayload, headers?: Record<string, any>) {
    const response = await api.post(`${FacebookCAPI.BASE_PATH}facebookCapi`, payload, headers);
    return response.data;
  }
}

import { type Ref, computed } from 'vue';
import { useSessionAnswers } from "@shared/composable/useSessionAnswers";
import { feetToCm, cmToFeet } from "../../heightinput/utils";
import type { ElementOptions } from '@shared/types/options';
import { getClientNumber, getRealNumber } from "@shared/utils/helpers";
import { Weights } from '../definitions';

export function kgToLb(value: number | string, countryCode: string) {
  const val = typeof value === 'string' ? getRealNumber(value, countryCode) : value;
  return val * 2.20462;
}

export function lbToKg(value: number | string, countryCode: string) {
  const val = typeof value === 'string' ? getRealNumber(value, countryCode) : value;
  return val * 0.45359;
}

export function useBMICalculation(payload: {
  textValue: Ref<string | number>;
  options: ElementOptions['weightinput'];
  countryCode: Ref<string>;
  sessionInfo: { sessionId: string; pageSlug: string; flowId: string | number; },
  form: Ref<{ activeImperial: boolean; type: Weights }>
}) {
  const { getSavedSessionAnswers } = useSessionAnswers(payload.sessionInfo);
  const answers = getSavedSessionAnswers();
  const { textValue, options, countryCode, form } = payload;
  const questionId = options.bmiCalculation?.heightinput || 0;
  const answer = JSON.parse(answers?.[questionId] || '{}') as {
    options: number[];
    input: string;
  };
  const answerObj = JSON.parse(answer?.input || '{}') as { unit: 'cm' | 'ft', value: number };
  const weight = computed(() => {
    let value = getRealNumber(textValue.value, countryCode.value);

    if (form.value.activeImperial) {
      value = lbToKg(value, countryCode.value);
    }

    return value
  });

  const height = computed(() => {
    let value = answerObj.value;

    if (answerObj.unit === 'ft') {
      const [ft, inch] = `${value}`.split('.');
      value = feetToCm(+(ft || 0), +(inch || 0));
    }

    return value || 0;
  });

  const calculatedBmi = computed(() => {
    return +calcBmiWithUnit(weight.value, height.value).toFixed(2);
  });

  const classifyByBmi = computed(() => {
    if (calculatedBmi.value < 18.5) {
      return 'underweight';
    }
    if (calculatedBmi.value >= 18.5 && calculatedBmi.value < 25) {
      return 'normal';
    }
    if (calculatedBmi.value >= 25 && calculatedBmi.value < 30) {
      return 'overweight';
    }
    if (calculatedBmi.value >= 30 && calculatedBmi.value < 35) {
      return 'obese_class_1';
    }
    if (calculatedBmi.value >= 35 && calculatedBmi.value < 40) {
      return 'obese_class_2';
    }
    if (calculatedBmi.value >= 40) {
      return 'obese_class_3';
    }
    return undefined;
  });

  const bmiResult = computed(() => {
    const unit = form.value.activeImperial ? 'lb' : 'kg';

    let maxWeight = getWeightByBmi(24.9, height.value);
    let minWeight = getWeightByBmi(18.5, height.value);

    if (form.value.activeImperial) {
      maxWeight = kgToLb(maxWeight, countryCode.value);
      minWeight = kgToLb(minWeight, countryCode.value);
    }

    return {
      bmi: +calcBmiWithUnit(weight.value, height.value).toFixed(2),
      class: classifyByBmi.value as (typeof classifyByBmi.value),
      maxWeight: getClientNumber(+maxWeight.toFixed(2), countryCode.value)+unit,
      minWeight: getClientNumber(+minWeight.toFixed(2), countryCode.value)+unit
    }
  });

  function getWeightByBmi(bmi: number, heightInCm: number) {
    return +(bmi * ((heightInCm / 100) ** 2));
  }

  function calcBmiWithUnit(weight: number, heightInCm: number) {
    if (weight === 0 || heightInCm === 0) return 0;
    return +(weight / ((heightInCm / 100) ** 2));
  }

  function hasTargetHeightInput() {
    return !!Object.prototype.hasOwnProperty.call(answerObj, 'value');
  }

  return {
    answers,
    feetToCm,
    cmToFeet,
    calculatedBmi,
    classifyByBmi,
    bmiResult,
    hasTargetHeightInput
  }
}

export function useWeightComparison(payload: {
  textValue: Ref<string | number>;
  original: Ref<{
    unit: Weights,
    value: number;
  }>;
  options: ElementOptions['weightinput'];
  countryCode: Ref<string>;
  sessionInfo: { sessionId: string; pageSlug: string; flowId: string | number; },
  form: Ref<{ activeImperial: boolean; type: Weights }>
}) {
  const { getSavedSessionAnswers } = useSessionAnswers(payload.sessionInfo);
  const answers = getSavedSessionAnswers();
  const { original, options, countryCode, form } = payload;
  const { weightinput } = options.weightComparison || {};
  const questionId = weightinput || 0;
  const answer = JSON.parse(answers?.[questionId] || '{}') as {
    options: number[];
    input: string;
  };
  const answerObj = JSON.parse(answer?.input || '{}') as { unit: 'kg' | 'lb', value: number };

  const weight = computed(() => {
    let value = 0;

    if (form.value.activeImperial) {
      value = original.value.unit === Weights.LB
        ? lbToKg(original.value.value, countryCode.value)
        : original.value.value;
    } else {
      value = original.value.unit === Weights.KG
        ? original.value.value
        : lbToKg(original.value.value, countryCode.value);
    }

    return value
  });

  const prevWeight = computed(() => {
    let value = answerObj.value;

    if (answerObj.unit === 'lb') {
      value = lbToKg(value, countryCode.value);
    }
    return value
  });

  const weightDiff = computed(() => {
    const result = {
      value: '',
      percent: 0,
      status: '' as 'gain' | 'loss',
    };

    if (!options.weightComparison?.active) return result;
    
    const value = +(prevWeight.value - weight.value);

    let percent = +(((value) / prevWeight.value) * 100);
    if (percent < 0) percent = -1 * percent;

    result.value = `${(value < 0 ? -1*value : value).toFixed(2) || 0}${form.value.activeImperial ? 'lb' : 'kg'}`;
    result.status = value < 0 ? 'gain' : 'loss';
    result.percent = +percent.toFixed(2);

    return result;
  })

  function hasTargetWeightInput() {
    return !!Object.prototype.hasOwnProperty.call(answerObj, 'value');
  }

  return {
    weight,
    prevWeight,
    weightDiff,
    hasTargetWeightInput
  }
}

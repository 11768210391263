<template>
  <div class="relative flex items-center justify-center">
    <svg
      viewBox="-50,-50,100,100"
      class="size-[27px]"
      :class="{ 'scale-x-[-1]': reversed }"
    >
      <circle class="fill-none stroke-neutral-200 stroke-[8px]" r="46" />
      <path
        class="fill-none stroke-[#007AFF] stroke-[8px]"
        :d="path"
      />
    </svg>
    <span class="absolute inline-block text-center align-middle text-[11px] font-normal text-[#007AFF]">{{ value }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from "vue";

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 1,
  },
  reversed: {
    type: Boolean,
    default: false
  }
});

const { value, min, max } = toRefs(props);

const path = computed(() => {
  const frac =  (value.value - min.value) / (max.value - min.value) || 0;
  const theta = frac * 2 * Math.PI;
  const large = theta > Math.PI;
  const endX = Math.cos(theta - Math.PI * 0.5) * 46;
  const endY = Math.sin(theta - Math.PI * 0.5) * 46;

  return `M0,-46 A46,46,0,${large ? 1 : 0},1,${endX},${endY}`;
});
</script>

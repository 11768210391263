<template>
  <div class="flex justify-center">
    <div>
      <div class="flex gap-12">
        <WebInput
          v-for="(singlePinValue, index) in currentPin"
          ref="inputRefs"
          :key="`test_${index}`"
          :model-value="singlePinValue"
          :name="`pin_${index}`"
          :disabled="disabled"
          :error="hasError || hasCodeLengthError"
          :page-options="pageOptions"
          type="mask"
          mask="0"
          force-mask-update
          inputmode="numeric"
          placeholder="•"
          class-name="tw-otp-input w-[36px] !mb-0"
          autocomplete="off"
          @keyup="handleKeyup($event, index)"
          @paste="onPaste"
        />
      </div>
      <div v-if="hasCodeLengthError" class="mt-8 flex w-full flex-1 text-12 font-normal text-error-500">
        {{ translate('generate.pages.register.pleaseEnterNumberOfDigitCode', locale, { count: codeLength }) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, type PropType } from 'vue';
import type { PageOptions } from '@shared/types/model';
import WebInput from '@shared/components/input/index.vue';
import { useTranslate } from '@shared/composable/useTranslate';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  codeLength: { type: Number, default: 6 },
  modelValue: { type: String, default: '' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  disabled: { type: Boolean, default: false },
  hasCodeLengthError: { type: Boolean, default: false },
  hasError: { type: Boolean, default: false },
  locale: { type: String, default: '' },
});

const { translate } = useTranslate();

const inputRefs = ref<any[]>([]);
const currentPin = ref<string[]>(getDefaultValue(String(props.modelValue).split('')));

function getDefaultValue(list = [] as any[]) {
  return [
    ...list.map(Number).filter((a) => !isNaN(a)),
    ...new Array(props.codeLength).fill('')
  ].slice(0, props.codeLength);
}

function getFirstEmptyFieldIndex() {
  return currentPin.value.findIndex((item) => isNaN(parseInt(item as string)));
}

function getInput(index: number) {
  return inputRefs.value?.[index]?.el;
}

async function handleKeyup(evt: KeyboardEvent, index: number) {
  const keyCode = evt.key;
  const shiftKey = evt.shiftKey;
  let indexValue = index;

  if (keyCode === 'Backspace' || (keyCode === 'ArrowLeft' && !shiftKey)) {
    // Jump to previous field
    if (keyCode === 'Backspace') {
      currentPin.value[indexValue] = '';
    }
    indexValue -= 2;
  } else if (['Backspace', 'Meta', 'Shift', 'Tab', 'Enter'].includes(keyCode)) return;

  if (!isNaN(parseInt(keyCode))) {
    currentPin.value[indexValue] = keyCode;
  } else if (keyCode.length === 1) return;

  if (indexValue >= props.codeLength) {
    // Jump to first empty field if user is on last field
    const findFirstEmptyField = getFirstEmptyFieldIndex();
    indexValue = findFirstEmptyField;
  }

  // Jump next input
  const nextInput = getInput(indexValue + 1);
  nextInput?.focus();
  nextInput?.select();
}

function parseStringToPin(value: string) {
  const list = value.replace(/\D/g, '').split('').map(Number).slice(0, props.codeLength) as number[];

  if (list.length > 0) currentPin.value = getDefaultValue(list as any);
}

function parsePinToString(value: string[]) {
  return value.filter((a: any) => !isNaN(a)).join('');
}

function onPaste(e: ClipboardEvent) {
  e.preventDefault();
  const value = e.clipboardData?.getData('text/plain');
  if (!value) return;
  parseStringToPin(value);
}

watch(
  () => currentPin.value,
  (val) => {
    emit('update:modelValue', parsePinToString(val));
  },
  { deep: true }
);
</script>

<style scoped lang="postcss">
:deep(.tw-otp-input) {
  input {
    @apply text-center !text-[#000000] text-16 font-semibold leading-5 placeholder-[#D9D9D9];
  }
}
</style>


import Toast, { type PluginOptions, POSITION, useToast } from "vue-toastification";
import { WebToast } from '@/shared/components';
import CloseButton from "@/shared/components/toast/components/close-button.vue";
import type { ToastVariant } from '@/shared/components/toast/index.vue';
import "vue-toastification/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {

  const options:PluginOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: false,
    icon: false,
    closeButton: CloseButton,
    closeOnClick: false,
    toastClassName: "tw-toast-container",
    transition: "Vue-Toastification__fade",
  };

  nuxtApp.vueApp.use(Toast, options);

  // Helpers are included here to avoid build errors on server
  const toast = useToast();
  function showToast(title: string, subtitle: string, variant: ToastVariant, options?: PluginOptions) {
    toast({
      component: WebToast,
      props: {
        title,
        subtitle,
        variant
      }
    }, options);
  }

  function clearToasts() {
    toast.clear();
  }

  return {
    provide: {
      toast: {
        toast,
        showToast,
        clearToasts
      }
    }
  }
});

<template>
  <div class="tw-payment-form-element w-full flex-col overflow-hidden px-16 py-[22px] md:p-24">
    <FormError
      v-if="pageData.paymentFormError"
      :is-editor-mode="isEditorMode"
      :page-data="pageData"
      :page-options="pageOptions"
      :locale="locale"
    />
    <div
      v-if="!pageData.paymentFormError"
      :class="{'flex flex-1': !hasPaymentMethod || pageData.isSuccessLoading}"
    >
      <component
        :is="isEditorMode ? 'div' : WebForm"
        ref="paymentFormElement"
        @before-submit="() => { pageData.paymentMethod = pageData.providerStatus[STRIPE] ? STRIPE : CREDIT_CARD }"
        @submit="clickProvider(pageData.providerStatus[STRIPE] ? STRIPE : CREDIT_CARD)"
      >
        <WebSpinner v-if="pageData.isSuccessLoading" class="flex min-h-[530px] flex-col items-center justify-center" />
        <template v-else-if="hasPaymentMethod">
          <div class="mb-24 grid gap-24">
            <div v-if="(getProviderStatus(STRIPE) || getProviderStatus(CREDIT_CARD))" :style="{order: handleStripeCreditCardOrder()}">
              <div v-if="!isCreditCardOrderFirst">
                <Hr>
                  {{ translate("generate.common.or", locale) }}
                </Hr>
                <p class="mb-16 mt-24 text-center">
                  {{ translate('generate.pages.payment.payWithCreditCard') }}
                </p>
              </div>

              <PaymentStripeInputs
                v-if="getProviderStatus(STRIPE)"
                ref="stripeForm"
                v-model="form"
                :element="element"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
                :consent-style="consentStyle"
                :is-editor-mode="isEditorMode"
                :stripe-token="pageData.stripeToken"
                :show-register-accept-policy="isCreditCardOrderFirst"
              />
              <PaymentFormInputs
                v-else-if="getProviderStatus(CREDIT_CARD)"
                v-model="form"
                :page-options="pageOptions"
                :is-label-visible="isLabelVisible"
                :label-style="labelStyle"
                :consent-style="consentStyle"
                :locale="locale"
                :is-editor-mode="isEditorMode"
                :page-data="pageData"
                :element="element"
                :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
                :show-register-accept-policy="isCreditCardOrderFirst"
              />

              <PriceInfo
                v-if="creditCardOrder === 1"
                class="mb-24 mt-32 lg:mb-32"
                :page-options="pageOptions"
                :label-style="labelStyle"
                :total-price="totalPrice"
                :locale="locale"
                :is-editor-mode="isEditorMode"
                :class="{ '!mt-8 md:!mt-0': !getProviderStatus(STRIPE) && !getProviderStatus(CREDIT_CARD) }"
              />

              <!-- DEFAULT SLOT (SUBMIT BUTTON) -->
              <template v-if="isEditorMode">
                <div v-if="$slots.default" class="tw-payment-form-element__button">
                  <slot />
                </div>
              </template>
              <div
                v-else-if="slotDefault.length"
                class="tw-payment-form-element__button"
              >
                <template v-for="subEl of slotDefault" :key="subEl.id">
                  <ElementWrapper
                    v-if="subEl.options.visible"
                    :el="subEl"
                    :page-data="submitButtonProps"
                    :page-options="pageOptions"
                    :locale="locale"
                  />
                </template>
              </div>

              <!-- PAYMENT METHOD ICONS -->
              <PaymentMethodIcons class="my-8" />

              <!-- IF CREDIT CARD IS NOT ON THE LAST ORDER -->
              <Hr v-if="creditCardOrder !== findMaxOrder || checkOrBorder">
                {{ translate('generate.common.or', locale) }}
              </Hr>
            </div>

            <!-- IF CREDIT CARD IS NOT ON THE FIRST ORDER -->
            <template v-if="!isCreditCardOrderFirst || (!getProviderStatus(CREDIT_CARD) && !getProviderStatus(STRIPE))">
              <div :style="{ order: creditCardOrder !== 1 ? 1 : 2 }">
                <RegisterInput
                  v-if="pageData.registerOnPayment"
                  v-model:subscriber-id="form.email"
                  hide-full-name-input
                  :disabled="pageData.bypassRegister"
                  :class="[creditCardOrder !== 1 ? 'mt-0' : 'mt-16']"
                  :locale="locale"
                  :is-editor-mode="isEditorMode"
                  :element="element"
                  :page-data="pageData"
                  :page-options="pageOptions"
                  @error="handleInputError"
                />

                <PriceInfo
                  class="mb-24 mt-16 lg:mb-32"
                  :page-options="pageOptions"
                  :label-style="labelStyle"
                  :total-price="totalPrice"
                  :locale="locale"
                  :is-editor-mode="isEditorMode"
                  :class="{ '!mt-8 md:!mt-0': !getProviderStatus(STRIPE) && !getProviderStatus(CREDIT_CARD), '!mb-16': creditCardOrder !== 1}"
                />

                <AcceptPolicy
                  v-if="isEditorMode || pageData.isPolicyRequired"
                  v-model="form.acceptPolicy"
                  :page-options="pageOptions"
                  :page-data="pageData"
                  :locale="locale"
                  :consent-style="consentStyle"
                  :input-class="{ 'pointer-events-none' : isEditorMode }"
                  :is-editor-mode="isEditorMode"
                  :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
                  class="mb-8 mt-16"
                />
              </div>
            </template>

            <!-- OTHER PAYMENT METHODS -->
            <div
              v-if="getProviderStatus(PAYPAL)"
              :style="{order: getOrder(PAYPAL)}"
            >
              <ProviderButton
                :provider="PAYPAL"
                :loading="$wait.is(`${PAYPAL}:paymentProcessing`)"
                :disabled="isSubmitLoading"
                :page-data="pageData"
                :is-editor-mode="isEditorMode"
                :is-preview-mode="isPreviewMode"
                @[btnClick]="clickProvider(PAYPAL)"
              />
              <div class="mt-4 text-center text-12 font-semibold">
                {{ translate('generate.pages.payment.paypalMotto', locale) }}
              </div>
            </div>

            <div
              v-if="getProviderStatus(GOOGLE_PAY)"
              :style="{order: getOrder(GOOGLE_PAY)}"
            >
              <ProviderButton
                :provider="GOOGLE_PAY"
                :loading="$wait.is(`${GOOGLE_PAY}:paymentProcessing`)"
                :disabled="isSubmitLoading"
                :dark-mode="!!element.options?.darkModeOnTabButtons"
                :page-data="pageData"
                :is-editor-mode="isEditorMode"
                :is-preview-mode="isPreviewMode"
                @[btnClick]="clickProvider(GOOGLE_PAY)"
              />
            </div>

            <div v-if="getProviderStatus(APPLE_PAY)" :style="{order: getOrder(APPLE_PAY)}">
              <ProviderButton
                :provider="APPLE_PAY"
                :loading="$wait.is(`${APPLE_PAY}:paymentProcessing`)"
                :disabled="isSubmitLoading"
                :dark-mode="!!element.options?.darkModeOnTabButtons"
                :page-data="pageData"
                :is-editor-mode="isEditorMode"
                :is-preview-mode="isPreviewMode"
                @[btnClick]="clickProvider(APPLE_PAY)"
              />
            </div>
          </div>


          <!-- INFO TEXT -->
          <template v-if="isEditorMode">
            <div v-if="$slots.infoText" class="tw-payment-form-element__info-text order-last mt-8">
              <slot name="infoText"></slot>
            </div>
          </template>
          <div v-else-if="slotInfoText.length" class="tw-payment-form-element__info-text order-last mt-8">
            <template v-for="subEl of slotInfoText" :key="subEl.id">
              <ElementWrapper
                v-if="subEl.options.visible"
                :el="subEl"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </template>
          </div>

          <!-- INFO TEXT 2 -->
          <template v-if="isEditorMode">
            <div v-if="$slots.infoText2" class="tw-payment-form-element__info-text order-last">
              <slot name="infoText2"></slot>
            </div>
          </template>
          <div v-else-if="slotInfoText2.length" class="tw-payment-form-element__info-text order-last">
            <template v-for="subEl of slotInfoText2" :key="subEl.id">
              <ElementWrapper
                v-if="subEl.options.visible"
                :el="subEl"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </template>
          </div>
        </template>
        <template v-else>
          <NoPaymentMethodInfo
            :is-editor-mode="isEditorMode"
            :locale="locale"
            :page-options="pageOptions"
          />
        </template>
      </component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType, toRefs, watch } from 'vue';
import {
  PaymentMethodIcons,
  PaymentFormInputs,
  PaymentStripeInputs,
  ProviderButton,
  PriceInfo,
  NoPaymentMethodInfo,
  Hr
} from '@shared/elements/common/payment/components';
import { PaymentProvider, type PaymentPageData } from '@shared/elements/common/payment/types';
import { WebForm, WebSpinner } from '@shared/components';
import { getElementsBySlot } from '@shared/utils';
import { $wait } from '@shared/utils/wait';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { useTranslate } from '@shared/composable/useTranslate';
import ElementWrapper from '../wrapper.vue';
import { usePaymentElement } from '@shared/elements/common/payment/composables/usePaymentElement';
import FormError from '@shared/elements/common/payment/components/form-error/index.vue';
import AcceptPolicy from '@shared/elements/common/payment/components/accept-policy/index.vue';
import RegisterInput from '@shared/elements/common/registration/components/registerInput.vue';
import { globalEmit } from '@shared/utils/helpers';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  isPreviewMode: { type: Boolean, default: false },
  config: { type: Object as PropType<ElementOptions['payment']>, default: () => ({}) },
  pageData: { type: Object as PropType<PaymentPageData>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'payment'>>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const { translate } = useTranslate();

const { CREDIT_CARD, PAYPAL, GOOGLE_PAY, APPLE_PAY, STRIPE } = PaymentProvider;

const { element, pageOptions, pageData } = toRefs(props);
const {
  form,
  stripeForm,
  paymentFormElement,
  clickProvider,
  labelStyle,
  consentStyle,
  isLabelVisible,
  getProviderStatus,
  totalPrice,
  isSubmitLoading,
  isSubmitDisabled,
  isLoadingPackage,
  hasPaymentMethod
} = usePaymentElement({ element, pageData, pageOptions });

  const defaultOrder = {
    [PaymentProvider.STRIPE]: 1,
    [PaymentProvider.CREDIT_CARD]: 1,
    [PaymentProvider.PAYPAL]: 2,
    [PaymentProvider.GOOGLE_PAY]: 3,
    [PaymentProvider.APPLE_PAY]: 4
  };

const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));

const submitButtonProps = computed(() => ({
  isLoading: $wait.is(`${CREDIT_CARD}:paymentProcessing`),
  isDisabled: isSubmitDisabled.value,
  applyConditionalValue: props.pageData?.applyConditionalValue,
  getActiveCondition: props.pageData?.getActiveCondition
}));

const slotDefault = computed(() => {
  return getElementsBySlot(props.element.elements);
});
const slotInfoText = computed(() => {
  return getElementsBySlot(props.element.elements, 'infoText');
});
const slotInfoText2 = computed(() => {
  return getElementsBySlot(props.element.elements, 'infoText2');
});
function handleInputError(errMessage: string) {
  globalEmit('paymentFormInputError', errMessage);
}

const creditCardOrder = computed(() => {
  if (element.value.options.order?.creditCard === -1) {
    return element.value.options.order?.stripe
  }
  return element.value.options.order?.creditCard || element.value.options.order?.stripe || defaultOrder[CREDIT_CARD];
});

const isCreditCardOrderFirst = computed(() => {
  return creditCardOrder.value === 1;
});

const findMaxOrder = computed(() => {
  const order = element.value.options.order || defaultOrder as any;
  return Object.values(order).reduce((max, value) => Math.max(max as number, value as number), -Infinity);
});

const checkOrBorder = computed(() => {
  const order = element.value.options.order || defaultOrder;
  const validOrders = Object.entries(order).filter(([, value]) => value > 0);

  return validOrders.some(([key, value]) =>
    (key === PaymentProvider.CREDIT_CARD || key === PaymentProvider.STRIPE) && value === 1
  ) &&
  validOrders.some(([key, value]) =>
    ![PaymentProvider.CREDIT_CARD, PaymentProvider.STRIPE].includes(key as PaymentProvider) && value === 1
  );
});

const handleStripeCreditCardOrder = () => {
  if (getProviderStatus(CREDIT_CARD)) {
    return getOrder(CREDIT_CARD);
  }
  else {
    return getOrder(STRIPE);
  }
};

const getOrder = (item: PaymentProvider) => {
  const order = element.value.options.order || defaultOrder as any;
  if (!order) return;
  return order[item];
};


watch(
  () => [pageData.value.providerStatus],
  () => {
    const providerStatus = pageData.value.providerStatus;
    const order = element.value.options?.order;

    if (order) {
      Object.entries(providerStatus).forEach(([provider, status]) => {
        if (!status) {
          order[provider as PaymentProvider] = -1;
        } else {
          if (order[provider as PaymentProvider] === -1 || order[provider as PaymentProvider] === undefined) {
            order[provider as PaymentProvider] = 1;
          }
        }
      });
    }
  },
  { deep: true }
);
</script>

<style lang="postcss" scoped>
.tw-payment-form-element {
  &__info-text {
    a {
      color: #007aff !important;
    }
  }
  &__button {
    @apply mt-32;
  }
}

@media (max-width: 640px) {
  .tw-payment-form-element {
    @apply border-none w-full;
  }
}

/* For editor screen only */
.tw-editor__screen {
  &.mobile {
    .tw-payment-form-element {
      @apply border-none w-full;
    }
  }
}
</style>

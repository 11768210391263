<template>
  <div class="w-full">
    <div
      v-show="isGooglePayButtonFromSdk && !loading && !disabled"
      :id="buttonId"
      class="h-48 w-full"
    />
    <button
      v-show="!isGooglePayButtonFromSdk || loading || disabled"
      :type="type"
      class="tw-provider__button"
      :class="{
        'tw-dark': darkMode,
        'tw-provider__button--disabled': disabled && !loading,
        'tw-provider__button--loading': loading,
        '!bg-[#F6C444]': provider === PaymentProvider.PAYPAL,
      }"
      :disabled="disabled || loading"
    >
      <template v-if="loading">
        <WebSpinner
          :style="spinnerStyle"
          size="20"
          color="transparent"
          background="currentColor"
          class="mr-[10px]"
        />
      </template>
      <img
        :src="getAssetFromCdn(`editor/payment-providers/${provider}${disabled && !loading ? '_disabled' : darkMode ? '_black' : ''}.png`)"
        :alt="provider"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed, onMounted } from 'vue';
import { PaymentProvider, type PaymentPageData } from '@shared/elements/common/payment/types';
import { WebSpinner } from '@shared/components';
import { getAssetFromCdn } from '@shared/utils/helpers';

const props = defineProps({
  type: { type: String as PropType<'button' | 'submit'>, default: 'button' },
  provider: { type: String as PropType<PaymentProvider>, default: PaymentProvider.PAYPAL },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  darkMode: { type: Boolean, default: false },
  isEditorMode: { type: Boolean, default: true },
  isPreviewMode: { type: Boolean, default: false },
  pageData: { type: Object as PropType<PaymentPageData>, default: () => ({}) },
});

const buttonId = computed(() => `${props.provider}Button`);
const isGooglePayButtonFromSdk = computed(() => props.provider === PaymentProvider.GOOGLE_PAY && !props.isEditorMode && !props.isPreviewMode);
const spinnerStyle = computed(() => {
  if ([PaymentProvider.APPLE_PAY, PaymentProvider.GOOGLE_PAY].includes(props.provider)) {
    return {
      color: props.darkMode ? 'black' : 'white'
    }
  }
  return null;
})

function renderGooglePayButton() {
  const container = document?.getElementById(buttonId.value);
  const button = props.pageData?.getGooglePayButton({
    buttonColor: props.darkMode ? 'white' : 'black',
  });
  if (button && container) container?.appendChild(button);
}

onMounted(() => {
  if (isGooglePayButtonFromSdk.value) renderGooglePayButton();
});
</script>

<style lang="postcss" scoped>
.tw-provider__button {
  @apply flex h-48 w-full items-center justify-center bg-[#000] rounded-[6px] p-8;
  img {
    @apply max-h-24;
  }

  &.tw-dark {
    @apply bg-white;
  }

  &.tw-provider__button {
    &--disabled {
      @apply !bg-neutral-200 !cursor-not-allowed;
    }
    &--loading {
      @apply !cursor-not-allowed;
    }
  }
}

:deep() {
  .gpay-card-info-container {
    @apply min-w-96;
    outline: none !important;
  }
  .gpay-card-info-animation-container {
    outline: none !important;
  }
}
</style>

<template>
  <div class="tw-registration-form-element overflow-hidden p-16 sm:p-32">
    <template v-if="isEditorMode">
      <div v-if="$slots.titleText" class="px-[20px] sm:px-40">
        <slot name="titleText"></slot>
      </div>
    </template>
    <div v-else-if="slotTitleText?.length" class="px-[20px] sm:px-40">
      <template v-for="subEl of slotTitleText" :key="subEl.id">
        <ElementWrapper
          v-if="subEl.options.visible"
          :el="subEl"
          :page-options="pageOptions"
          :page-data="pageData"
        />
      </template>
    </div>

    <component :is="isEditorMode ? 'div' : WebForm" @submit="handleSubmit">
      <template v-if="isOtpStepActive">
        <WebOtpInput
          v-model="otpCode"
          class="mb-32"
          :page-options="pageOptions"
          :locale="locale"
          :disabled="isSubmitLoading"
          :has-code-length-error="hasCodeLengthError"
          :has-error="hasCheckOtpError"
        />
      </template>
      <template v-else>
        <RegisterInput
          v-model:subscriber-id="form.subscriberId"
          v-model:full-name="form.fullName"
          :locale="locale"
          :is-editor-mode="isEditorMode"
          :config="config"
          :element="element"
          :page-data="pageData"
          :page-options="pageOptions"
          @error="handleInputError"
        />
      </template>

      <template v-if="isEditorMode">
        <div v-if="$slots.default" class="tw-registration-form-element__button">
          <slot />
        </div>
      </template>
      <div v-else-if="slotDefault.length" class="tw-registration-form-element__button">
        <template v-for="subEl of slotDefault" :key="subEl.id">
          <ElementWrapper
            v-if="subEl.options.visible"
            :el="subEl"
            :page-data="submitButtonProps"
            :page-options="pageOptions"
          />
        </template>
      </div>

      <div
        v-if="isSocialLoginPartVisible && registerType !== 'phoneNumber'"
        class="mt-8 grid gap-16 md:mt-24"
        :class="{ 'pointer-events-none': isEditorMode }"
      >
        <div class="tw-registration-form-element__hr">
          <span class="tw-registration-form-element__hr-text">{{ translate('generate.common.or', locale) }}</span>
        </div>

        <template v-for="loginType in socialLoginTypes" :key="loginType">
          <button
            v-if="getSocialLoginStatus(loginType)"
            class="tw-registration-form-element__social-login-button"
            :class="{'tw-registration-form-element__social-login-button--disabled': isSubmitLoading}"
            :style="socialLoginButtonStyle"
            type="button"
            :disabled="isSubmitLoading"
            @[btnClick]="handleSocialLogin(loginType)"
          >
            <img
              :src="getAssetFromCdn(`editor/social-logos/${loginType}.png`)"
              class="max-w-[16px]"
              :alt="loginType"
            />
            <span>
              {{ translate('generate.label.socialLoginWithType', locale, { loginType: toFirstUpperCase(loginType) }) }}
            </span>
          </button>
        </template>
      </div>
    </component>

    <div v-if="isOtpStepActive" class="mt-12 rounded-[6px] bg-white py-4 md:mt-16 md:py-20">
      <OtpActions
        :page-options="pageOptions"
        :page-data="pageData"
        :register-type="registerType"
        :locale="locale"
        @change-subscriber="handleChangeSubscriber"
        @resend-code="handleSendCode"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, type Ref, type PropType, toRefs, unref, watch } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import WebForm from '@shared/components/form/index.vue';
import ElementWrapper from '@shared/elements/wrapper.vue';
import { useTranslate } from '@shared/composable/useTranslate';
import { getAssetFromCdn, globalEmit, toFirstUpperCase } from '@shared/utils/helpers';
import { getElementsBySlot } from '@shared/utils';
import { type SocialLogins, socialLoginTypes, socialLoginSettingKeys } from './definitions';
import { ConditionType } from '@shared/elements/common/registration/types';
import { RegisterType } from '@shared/types/model'
import RegisterInput from '@shared/elements/common/registration/components/registerInput.vue';
import OtpActions from '@shared/elements/common/registration/components/otpActions.vue';
import WebOtpInput from '@shared/components/otp-input/index.vue';

const { translate } = useTranslate();

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: {
    type: Object as PropType<ElementOptions['registration']>,
    default: () => ({})
  },
  element: { type: Object as PropType<ElementType<'registration'>>, default: () => ({}) },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const form: Ref = ref({ subscriberId: '', fullName: '' });
const otpCode = ref('');
const hasCodeLengthError = ref(false);
const { element, pageOptions } = toRefs(props);
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const slotDefault = computed(() => getElementsBySlot(props.element.elements));
const slotTitleText = computed(() => getElementsBySlot(props.element.elements, 'titleText'));
const socialLoginStatusOnProjectSettings = computed(() => props.pageData?.socialLoginStatus || {});
const isSocialLoginPartVisible = computed(() => {
  return !!Object.keys(props.config.socialLogin || {}).filter((loginType: any) => getSocialLoginStatus(loginType))
    .length && !isOtpStepActive.value;
});
const activeCondition = computed(() => props.pageData?.activeCondition);
const registerHasOtp = computed(() => props.pageData?.registerOtpStatus);
const isOtpStepActive = computed(() => [ConditionType.EMAIL_OTP, ConditionType.SMS_OTP].includes(activeCondition.value));

function getSocialLoginStatus(type: SocialLogins) {
  return !!props.config.socialLogin?.[type] && !!(+socialLoginStatusOnProjectSettings.value?.[socialLoginSettingKeys[type]]);
}

const isSubmitLoading = computed(() => props.pageData?.isSubmitLoading || false);
const isSubmitDisabled = computed(() => props.pageData?.isSubmitDisabled || false);
const hasCheckOtpError = computed(() => props.pageData?.hasCheckOtpError || false);

const submitButtonProps = computed(() => ({
  isLoading: isSubmitLoading.value,
  isDisabled: isSubmitDisabled.value,
  applyConditionalValue: props.pageData?.applyConditionalValue,
  getActiveCondition: props.pageData?.getActiveCondition
}));

const registerType = computed(() => props.pageData?.registerType);

const socialLoginButtonStyle = computed(() => {
  return {
    borderRadius: `${props.element.options.socialLoginCustomize?.borderRadius || pageOptions?.value?.borderRadius}px`
  }
});

function handleSubmit() {
  if (registerHasOtp.value && isOtpStepActive.value) return handleOtpSubmit();
  return handleRegisterSubmit();
}

function handleOtpSubmit() {
  if (validateOtpCode()) return handleCheckCode(otpCode.value);
  return;
}

function handleRegisterSubmit() {
  const formValues = unref(form);
  if (registerType.value === RegisterType.PhoneNumber) formValues.subscriberId = `+${formValues.subscriberId}`;
  globalEmit('registrationFormSubmitted', formValues);
  setSubscriberId(formValues.subscriberId);
}

function handleSocialLogin(type: SocialLogins) {
  globalEmit('registrationSocialLogin', type);
}

function handleInputError(errMessage: string) {
  globalEmit('registrationInputError', errMessage);
}

function setActiveCondition(condition: ConditionType) {
  (props.pageData as any).activeCondition = condition;
}

function clearHasCheckOtpError() {
  (props.pageData as any).hasCheckOtpError = false;
}

function setSubscriberId(value: string) {
  (props.pageData as any).subscriberId = value;
}

function clearOtpInput() {
  otpCode.value = '';
  clearHasCheckOtpError();
  hasCodeLengthError.value = false;
}

function handleChangeSubscriber() {
  clearOtpInput();
  setActiveCondition(ConditionType.REGISTER);
}

function handleSendCode() {
  globalEmit('registrationSendOtpCode');
}

function handleCheckCode(otpCode:string) {
  clearHasCheckOtpError();
  globalEmit('registrationCheckOtpCode', otpCode);
}

function validateOtpCode() {
  const isValid = otpCode.value?.length === 6;
  hasCodeLengthError.value = !isValid;
  return isValid;
}

watch(() => otpCode.value, (newVal) => {
  if (newVal.length === 6) handleOtpSubmit();
});
</script>

<style lang="postcss" scoped>
.tw-registration-form-element {
  &__hr {
    @apply flex items-center before:h-[1px] before:grow before:bg-neutral-200 before:content-[''] after:h-[1px] after:grow after:bg-neutral-200 after:content-[''];
    &-text {
      @apply shrink-0 px-[6px] text-12 text-neutral-500;
    }
  }

  &__social-login-button {
    @apply flex h-48 cursor-pointer items-center justify-center gap-[10px] rounded-[6px] border border-neutral-200 bg-white text-14 font-semibold text-neutral-900;
    &--disabled {
      @apply cursor-not-allowed opacity-40;
    }
  }
}

@media (max-width: 640px) {
  .tw-registration-form-element {
    @apply border-none;
  }
}

/* For editor screen only */
.tw-editor__screen {
  &.mobile {
    .tw-registration-form-element {
      @apply border-none;
    }
  }
}
</style>

<template>
  <div :style="style" class="flex flex-col items-center gap-8">
    <div class="flex">
      <button class="tw-otp-action-button" @click="handleChangeSubscriber">
        {{ translate(`generate.pages.register.change_${registerType}`, locale) }}
      </button>
    </div>
    <div class="flex gap-8">
      <button
        class="tw-otp-action-button"
        :disabled="isResendDisabled"
        @click="handleResendCode"
      >
        {{ translate('generate.pages.register.resendTheCode', locale) }}
      </button>
      <ProgressRing
        v-if="isProgressVisible"
        :min="0"
        :max="OTP_MAX_TIME"
        :value="timeInSeconds"
        reversed
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, type PropType, ref } from 'vue';
import ProgressRing from "@shared/components/progress-ring/index.vue";
import type { PageOptions, RegisterType } from '@shared/types/model';
import { OTP_MAX_TIME } from "@shared/elements/common/registration/types";
import { useTranslate } from '@shared/composable/useTranslate';

const emit = defineEmits(['change-subscriber', 'resend-code']);

const props = defineProps({
  locale: { type: String, default: '' },
  isEditorMode: { type: Boolean, default: true },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  registerType: { type: String as PropType<RegisterType>, default: () => 'email' }
});

const { translate } = useTranslate();

const timeInSeconds = ref<number>(getDefaultTime());
const timerInterval = ref();
const isResendDisabled = computed(() => timeInSeconds.value > 0);
const isProgressVisible = computed(() => isResendDisabled.value);

function getDefaultTime() {
  const time = +props.pageData.remainingOtpSeconds - 1;
  return time > 0 ? time : 0;
}

function startTimer() {
  timerInterval.value = setInterval(() => {
    timeInSeconds.value -= 1;
    if (timeInSeconds.value <= 0) {
      clearInterval(timerInterval.value);
    }
  }, 1000);
}

function reStartTimer() {
  clearTimerInterval();
  timeInSeconds.value = OTP_MAX_TIME - 1;
  startTimer();
}

function clearTimerInterval() {
  clearInterval(timerInterval.value);
}

function handleChangeSubscriber() {
  emit('change-subscriber');
}

function handleResendCode() {
  reStartTimer();
  emit('resend-code');
}

const style = computed(() => {
  const fontFamily = props.pageOptions.fontFamily;
  return {
    fontFamily
  };
});

onMounted(startTimer);
onUnmounted(clearTimerInterval);
</script>

<style scoped lang="postcss">
.tw-otp-action-button {
  @apply text-[10px] leading-[15px] underline text-[#007AFF] font-semibold;
  &[disabled] {
    @apply text-neutral-300 no-underline cursor-not-allowed;
  }
}
</style>

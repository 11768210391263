import type { PageType } from "@/types"
import { api } from "./api";

export type PageViewPayload = {
  pageType: PageType;
  platform: string;
  browser: string;
  browserVersion: string;
  osName: string;
  osVersion: string;
  deviceModel: string;
  deviceVendor: string;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class View {
  static BASE_PATH = 'event'

  static async page(payload: PageViewPayload, headers?: Record<string, any>) {
    const route = headers ? 'pageview' : 'page';
    const response = await api.post(`${View.BASE_PATH}/${route}`, payload, headers);
    return response.data;
  }

  static async question(payload: {
    quizId: number;
    questionId: number;
    quizLanguage: string;
  } & PageViewPayload, headers?: Record<string, any>) {
    const route = headers ? 'quiz-question-view' : 'question';
    const response = await api.post(`${View.BASE_PATH}/${route}`, payload, headers);
    return response.data;
  }

  static async questionAnswer(payload: {
    quizId: number;
    questionId: number;
    answerId: string;
    quizLanguage: string;
  } & PageViewPayload, headers?: Record<string, any>) {
    const route = headers ? 'quiz-question-answer-click' : 'answer';
    const response = await api.post(`${View.BASE_PATH}/${route}`, payload, headers);
    return response.data;
  }
}

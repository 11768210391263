import { type Ref, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import Social from '@services/social';
import type { SocialLogins } from '@/shared/elements/registration/definitions';
import type { Page } from '@/shared/types/model';
import type { RegisterPayload, RegisterUserOptions } from '@/types/register';
import { useSiteData } from '@/composables/useSiteData';
import { globalListener, globalRemoveListener } from '@shared/utils/helpers';
import { GTM, GA4 } from '@/utils/integrations';
import { checkCurrentSession } from '@/utils/session';
import { useRegisterCommon } from './common/useRegisterCommon';
import { useDocLinks } from './useDocLinks';
import { clearToasts } from "@/utils/toast";

function registerGTMError(message: string) {
  GTM.push({
    event: 'error',
    errorCategory: 'Registration',
    errorType: message
  });

  GA4.gtag('event', 'error', {
    errorCategory: 'Registration',
    errorType: message
  });
}

function registerGTMSuccess() {
  GTM.push({
    event: 'success',
    pageType: 'Registration',
    pageSlug: globalThis?.location?.pathname
  });

  GA4.gtag('event', 'success', {
    pageType: 'Registration',
    pageSlug: globalThis?.location?.pathname
  });
}

function registerGTMpageView() {
  GA4.gtag('event', 'page_view', {
    page_title: document?.title || '',
    page_location: window.location.pathname || '',
    previous_page_path: new URL(document.referrer || window.location.origin)?.pathname || ''
  });
}

export function useRegister(page: Ref<Page>) {
  const domain = globalThis?.location?.origin;
  Social.BASE_PATH = `${domain}/api/social`;

  function applyConditionalValue(conditionalValue: Record<string, {
    label: string;
    value: string;
  }>, fallbackText: string) {
    const condition = getActiveCondition();
    const text = conditionalValue?.[condition]?.value || fallbackText || '';
    const subscriberId = pageData.value.subscriberId || '';

    return text
      .replace(new RegExp('{USER_MAIL}', 'g'), subscriberId)
      .replace(new RegExp('{USER_PHONE}', 'g'), subscriberId)
  }

  function getActiveCondition() {
    return pageData.value.activeCondition
  }

  const { isPreview, socialLoginStatus, nextPage, countryCode } = useSiteData();
  const { pageData: commonPageData, registerUser, startLoading, endLoading, handleSendOtpCode, handleCheckOtpCode } = useRegisterCommon();
  const pageData: Ref = ref({
    ...toRefs(commonPageData.value),
    socialLoginStatus: socialLoginStatus.value,
    docLinks: useDocLinks(),
    subscriberId: '',
    applyConditionalValue,
    getActiveCondition
  });

  async function handleRegister(form: RegisterPayload, options?: RegisterUserOptions) {
    try {
      startLoading();
      clearToasts();
      await registerUser(form, options);
    } catch (ex) {
      Promise.reject(ex);
    } finally {
      endLoading();
    }
  }

  async function handleSocialLogin(provider: SocialLogins) {
    if (isPreview.value) return goToPageWithPreviewCountry(nextPage.value, countryCode.value);
    const { result } = await Social.login({ provider });
    if (globalThis?.location?.href && result?.auth) globalThis.location.href = result?.auth;
  }

  async function handleSocialCallback(code: string, provider: 'google' | 'facebook' | 'apple') {
    if (!provider || !code) return;
    const { result } = await Social.callback({ provider, code });
    const { email = '', fullName = '' } = result || {};
    if (email) {
      handleRegister(
        {
          subscriberId: email,
          ...(fullName && { fullName })
        },
        { isFromSocialLoginCallback: true }
      );
    }
  }

  function onRegistrationFormSubmitted(form: RegisterPayload) {
    handleRegister(form);
  }

  function onRegistrationSocialLogin(provider: SocialLogins) {
    handleSocialLogin(provider);
  }

  function onRegistrationInputError(err: any) {
    if (isPreview.value) return;
    const [hasError, message] = err || [];
    if (hasError) {
      registerGTMError(message);
    } else {
      registerGTMSuccess();
    }
  }

  globalListener('registrationSendOtpCode', handleSendOtpCode);

  globalListener('registrationCheckOtpCode', function (code) {
    handleCheckOtpCode(code as string);
  });

  function checkSocialLoginCallbackUrlParams() {
    const route = useRoute();
    const code = route.query?.code as string;
    const provider = route.query?.provider as SocialLogins;
    if (code && provider) {
      handleSocialCallback(code, provider);
    }
  }

  onBeforeMount(() => {
    globalListener('registrationFormSubmitted', onRegistrationFormSubmitted);
    globalListener('registrationSocialLogin', onRegistrationSocialLogin);
    globalListener('registrationInputError', onRegistrationInputError);
  })

  onMounted(() => {
    checkCurrentSession();
    registerGTMpageView();
    checkSocialLoginCallbackUrlParams();
  });

  onBeforeUnmount(() => {
    globalRemoveListener('registrationFormSubmitted', onRegistrationFormSubmitted);
    globalRemoveListener('registrationSocialLogin', onRegistrationSocialLogin);
    globalRemoveListener('registrationInputError', onRegistrationInputError);
  })

  return { page, pageData };
}

<template>
  <div class="tw-file">
    <div :class="{ 'pointer-events-none': isEditorMode }">
      <File
        v-model:file="file"
        :locale="locale"
        :config="config"
        :page-options="pageOptions"
      />
      <div v-if="pageData.fileElementError">
        <ElementError :description="pageData.fileElementError" />
      </div>
    </div>
    <div v-if="$slots.default" class="tw-file-element__bottom">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !$wait?.is(['nextQuestion', 'prevQuestion']) }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, watch, onBeforeMount } from 'vue';
import { $wait } from '@shared/utils/wait';
import { globalEmit, isClient } from '@shared/utils/helpers';
import File from '@shared/components/file/index.vue';
import ElementError from './element-error/index.vue';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';
import type { PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['file']>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  locale: { type: String, default: '' }
});

const file = ref([]);

if (isClient()) {
  watch(
    () => [file.value],
    () => {
      globalEmit('fileElementErrorStatus', '');
    }
  );
}

const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const canSubmit = computed(() => {
  if (props.isEditorMode) return true;
  if (props.config.required) {
    const wrongType = file.value.some((item: any) => item.status === 8);
    return (
      (Number(props.config.minFileCount) <= file.value.length &&
      file.value.length <= Number(props.config.maxFileCount)) &&
      !wrongType &&
      !props.pageData.fileElementError &&
      !$wait?.is(['nextQuestion', 'prevQuestion'])
    );
  }
  return true;
});

function finish(event?: any) {
  if (!canSubmit.value) return;
  const files = [...new Set(file.value.map((obj: any) => {
    if (!obj.file) return obj.source;
    return obj.file;
  }))];
  if (!props.config.required && files.length === 0) {
    globalEmit('skipQuestion', [event])
  } else {
    globalEmit('nextQuestion', [event, files]);
  }
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    file.value = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'file',
    });
  }
}

onBeforeMount(() => {
  setPreviousAnswer();
})
</script>

<style lang="postcss" scoped>
.tw-file {
  @apply flex flex-col;
  &-element__bottom {
    .tw-slot-wrapper {
      @apply block w-full justify-center md:flex;
    }
  }
}
.tw-editor__screen {
  &.mobile {
    .tw-slot-wrapper {
      @apply block;
    }
  }
}
</style>

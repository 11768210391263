export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"innerHTML":"\n(function () {\n function isInAppBrowser(userAgent) {\n  var ua = userAgent || \"\";\n  var BROWSER = {\n    webview: /\\b(Android.*wv|WebView)/i,\n    messenger: /\\bFB[\\w_]+\\/(Messenger|MESSENGER)/,\n    facebook: /\\bFB[\\w_]+\\//,\n    twitter: /\\bTwitter/i,\n    instagram: /\\bInstagram/i,\n    tiktok: /\\b(TikTok|Musically|ZhiliaoApp|ByteLocale)\\//i,\n    pinterest: /\\bPinterest\\//i\n  };\n  return (\n    BROWSER.messenger.test(ua) ||\n    BROWSER.facebook.test(ua) ||\n    BROWSER.twitter.test(ua) ||\n    BROWSER.instagram.test(ua) ||\n    BROWSER.tiktok.test(ua) ||\n    BROWSER.pinterest.test(ua) ||\n    BROWSER.webview.test(ua)\n  );\n}\n\nfunction detectOS(userAgent) {\n  if (/android/i.test(userAgent || '')) {\n    return \"Android\";\n  }\n  if (/iPad|iPhone|iPod/.test(userAgent || '') && !window.MSStream) {\n    return \"iOS\";\n  }\n  return \"Unknown\";\n}\n\nfunction redirectInApp () {\n  var agent  = window.navigator.userAgent || '';\n  if (isInAppBrowser(agent)) {\n    if (detectOS(agent) === \"Android\") {\n      var url = window.location.href.replace(/https?:\\/\\//, \"\");\n      window.location.href = \"intent://\"+url+\"#Intent;scheme=https;end\";\n    } else if (detectOS(agent) === \"iOS\") {\n      window.location.href = \"x-safari-\" + window.location.href;\n    }\n  }\n}\nredirectInApp();\n})()\n"}],"noscript":[],"viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = "https://3p-assets.cdnztl.com/fe_media/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"
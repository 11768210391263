<template>
  <div class="tw-weightinput-element !m-0 md:!mx-8 md:!mb-8 md:!mt-0 md:max-w-[400px]">
    <div class="mx-auto md:max-w-[280px]" :class="{ 'pointer-events-none': isEditorMode }">
      <div v-if="config.imperial" class="tw-weightinput-element__tab">
        <button
          type="button"
          :class="{ active: form.type === Weights.LB }"
          @click="switchImperal(true)"
        >
          {{ Weights.LB }}
        </button>
        <button
          type="button"
          :class="{ active: form.type === Weights.KG }"
          @click="switchImperal(false)"
        >
          {{ Weights.KG }}
        </button>
      </div>
  
      <WebInput
        v-if="renderComponent"
        ref="inputRef"
        name="weightinput"
        type="number"
        autocomplete="off"
        v-bind="getAttrsByConfig()"
        force-mask-update
        :value="`${textValue}`"
        :page-options="pageOptions"
        :rules="rules"
        :validate-on-update="true"
        @update:model-value="updateValue($event)"
        @keyup="updateOriginalValue()"
      >
        <template #right>
          <span class="text-12 leading-[18px] text-neutral-500">{{ form.type }}</span>
        </template>
      </WebInput>
    </div>

    <InfoTag
      v-if="canInfotagVisible"
      :icon="infoTag?.icon"
      :title="purify(infoTag?.title || '')"
      :description="purify(infoTag?.description || '')"
      :color="infoTag?.color"
      class="mx-auto mb-16 md:max-w-[280px]"
      :style="infoStyle"
    />

    <div v-if="$slots.default" class="tw-heightinput-element__bottom mx-auto md:max-w-[280px]">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>  
</template>

<script lang="ts" setup>
import { type PropType, ref, nextTick, computed, toRefs, onBeforeMount, watch } from 'vue';
import type { PageOptions, Element as ElementType } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { getRealNumber, getClientNumberFormat, globalEmit, getClientNumber, isClient } from '@shared/utils/helpers';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';
import { Weights } from './definitions';
import WebInput from '@shared/components/input/index.vue';
import InfoTag from '@shared/components/infotag/index.vue';
import { purify } from '@shared/utils';
import { useBMICalculation, useWeightComparison, kgToLb, lbToKg } from './composables/useCalculation';

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['weightinput']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  element: { type: Object as PropType<ElementType<'weightinput'>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const { element, pageOptions } = toRefs(props);
const { values } = useLabelOptions({ element, pageOptions });

const clientLang = computed(() => {
  return globalThis?.navigator?.language || props.pageData.countryCode || '';
});

const activeType = getDefaultActiveTab() as Weights;
const renderComponent = ref(true);
const inputRef = ref<any>(null);
const form = ref({
  activeImperial: activeType === Weights.LB,
  type: activeType
});
const textValue = ref<string|number>(getDefaultValue());
const original = ref({
  unit: form.value.type,
  value: parseFloat(`${textValue.value}`)
});

const { bmiResult, hasTargetHeightInput } = useBMICalculation({
  textValue,
  options: element.value.options,
  countryCode: clientLang,
  sessionInfo: props.pageData.sessionInfo,
  form
});

const { weightDiff, hasTargetWeightInput } = useWeightComparison({
  textValue,
  original,
  options: element.value.options,
  countryCode: clientLang,
  sessionInfo: props.pageData.sessionInfo,
  form
})

const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const infoStyle = computed(() => {
  return {
    fontFamily: props.pageOptions.fontFamily,
    titleColor: element.value.options.customizeTagTextColor ? element.value.options.infoTagTitleColor : props.pageOptions.colors?.text[0],
    descColor: element.value.options.customizeTagTextColor ?  element.value.options.infoTagDescColor : props.pageOptions.colors?.text[1],
  }
});

const isEmpty = computed(() => textValue.value === '' || textValue.value === 'NaN');
const clientFormat = computed(() => getClientNumberFormat(clientLang.value));
const rules = computed(() => {
  const { min, max } = props.config.input || {};
  const lang = clientLang.value
  const isRequired = props.config.required;
  const defaultMinValue = undefined;
  const defaultMaxValue = undefined;
  let rules = isRequired ? 'required' : '';
  let minValue = min?.active ? parseFloat(`${min?.limit || ''}`) || defaultMinValue : defaultMinValue;
  let maxValue = max?.active ? parseFloat(`${max?.limit || ''}`) || defaultMaxValue : defaultMaxValue;

  if (form.value.type === Weights.LB) {
    if (minValue !== undefined) {
      minValue = +kgToLb(minValue, lang).toFixed(clientFormat.value.scale);
    }
    if (maxValue !== undefined) {
      maxValue = +kgToLb(maxValue, lang).toFixed(clientFormat.value.scale);
    }
  }

  if (minValue !== undefined && maxValue !== undefined) {
    rules += `|between:${minValue},${maxValue}`
  }
  if (minValue !== undefined && maxValue === undefined) {
    rules += `|min_value:${minValue}`
  }
  if (maxValue !== undefined && minValue === undefined) {
    rules += `|max_value:${maxValue}`
  }

  return rules;
});

const canSubmit = computed(() => {
  const lang = clientLang.value
  let currentValue = getRealNumber(textValue.value, lang);

  if (form.value.type === Weights.LB) {
    currentValue = getRealNumber(getClientNumber(lbToKg(textValue.value, lang), lang), lang);
  }

  if (props.config?.input) {
    const { min, max } = props.config.input || {};
    const defaultMinValue = 0;
    const defaultMaxValue = undefined;
    const minValue = min?.active ? parseFloat(`${min?.limit || ''}`) || defaultMinValue : defaultMinValue;
    const maxValue = max?.active ? parseFloat(`${max?.limit || ''}`) || defaultMaxValue : defaultMaxValue;

    if (!isEmpty.value && min?.active && min.limit !== null && currentValue < minValue) {
      return false;
    }
    if (!isEmpty.value && max?.active && max.limit !== null && maxValue !== undefined && currentValue > maxValue) {
      return false;
    }
  }
  if (props.config.required) return !isEmpty.value;
  return true
});

const infoTags = computed(() => {
  const infoType = props.config.infoTag?.type;
  if (!infoType) return {};
  return values.value?.infoTag[infoType]
});

const infoTag = computed(() => {
  const infoType = props.config.infoTag?.type;
  let tag = infoTags.value?.[0] || {};
  let title = tag?.title || '';
  let description = tag?.description || '';
  const isBmiCalculationActive = !!props.config.bmiCalculation?.active && hasTargetHeightInput();
  const isWeightComparsionActive = !!props.config.weightComparison?.active && hasTargetWeightInput();

  if (!props.isEditorMode) {
    if (isBmiCalculationActive && infoType === 'bmi') {
      if (bmiResult.value.class === 'underweight') {
        tag = infoTags.value?.[2] || {};
      } else if (bmiResult.value.class === 'normal') {
        tag = infoTags.value?.[0] || {};
      } else {
        tag = infoTags.value?.[1] || {};
      }
    }
  
    if (isWeightComparsionActive && infoType === 'comparison') {
      if (weightDiff.value.status === 'gain') {
        tag = infoTags.value?.[0] || {};
      } else {
        tag = infoTags.value?.[1] || {};
      }
    }

    if (
      isBmiCalculationActive &&
      isWeightComparsionActive &&
      infoType === 'comparisonBmi'
    ) {      
      if (bmiResult.value.class === 'normal') {
        if (weightDiff.value.status === 'gain') {
          tag = infoTags.value?.[0] || {};
        } else {
          tag = infoTags.value?.[1] || {};
        }
      } else {
        if (bmiResult.value.class === 'underweight') {
          tag = infoTags.value?.[3] || {};
        } else {
          tag = infoTags.value?.[2] || {};
        }
      }
    }

    if (isBmiCalculationActive || isWeightComparsionActive) {
      title = replaceTagsWithValue(tag?.title)
      description = replaceTagsWithValue(tag?.description)
    } else {
      title = '';
      description = '';
    }
  }
  

  return {
    ...tag,
    title,
    description
  };
});

const canInfotagVisible = computed(() => {
  return (
    props.config.infoTag?.show &&
    (infoTag.value?.title || infoTag.value?.description) &&
    (props.isEditorMode || (!isEmpty.value && canSubmit.value))
  )
});

if (isClient()) {
  watch(
    () => props.config.imperial,
    (val) => form.value.activeImperial = !!val
  );
  watch(
    () => props.config.defaultValue,
    (val) => {
      (textValue.value = `${val || ''}`);
      if (props.isEditorMode) forceRender();
    }
  );
}

function replaceTagsWithValue(text: string) {
  return text
    ?.replace(/{BMI}/g, ''+bmiResult.value.bmi)
    ?.replace(/{WEIGHT_DIFF}/g, weightDiff.value.value)
    ?.replace(/{WEIGHT_%DIFF}/g, ''+(weightDiff.value.percent || 0))
    ?.replace(/{MIN_WEIGHT}/g, bmiResult.value.minWeight)
    ?.replace(/{MAX_WEIGHT}/g, bmiResult.value.maxWeight)
}

function updateOriginalValue() {
  original.value.value = getRealNumber(`${textValue.value}`, clientLang.value) || 0;
  original.value.unit = form.value.type;
}

function updateValue(value: any) {
  textValue.value = value ? getClientNumber(parseFloat(value), clientLang.value) : '';
}

function getAttrsByConfig(integer = false) {
  const payload: Partial<{
    mask: { mask: typeof Number } & ReturnType<typeof getClientNumberFormat>;
    min: string | number;
    max: string | number | undefined;
  }> = {
    mask: {
      mask: Number,
      ...getClientNumberFormat(clientLang.value),
      scale: 2,
    },
    min: 0
  }
  if (integer && payload.mask) {
    payload.mask.scale = 0;
    payload.mask.normalizeZeros = false;
  }
  return payload;
}

function getDefaultValue(value?: string | number, weightType?: Weights) {
  let defaultValue = props.config.defaultValue;
  if (value !== undefined) defaultValue = value;
  if (!weightType) weightType = form.value.type;
  if (defaultValue === '') return ''

  // Format value if it is not by default
  if (!/\D/g.test(`${defaultValue}`)) {
    defaultValue = getClientNumber(parseFloat(`${defaultValue}`), clientLang.value);
  }

  if (defaultValue !== undefined && defaultValue !== '') {
    if (props.isEditorMode) {
      defaultValue = getRealNumber(defaultValue, clientLang.value);
    }
    if (defaultValue !== '' && parseFloat(`${defaultValue || '0'}`) < 0) {
      defaultValue = 0
    }

    if (weightType === Weights.LB) {
      defaultValue = getClientNumber(kgToLb(defaultValue, clientLang.value), clientLang.value);
    }
  }
  return defaultValue || '';
}

function getDefaultActiveTab() {
  if (props.config.imperial) {
    if (props.isEditorMode) return Weights.KG;
    if (props.config.selectionByCountry) {
      const isImperial = ['UK', 'GB', 'US'].includes(props.pageData?.countryCode);
      return isImperial ? Weights.LB : Weights.KG;
    }
  }
  return Weights.KG;
}

function triggerInputValidation() {
  inputRef.value?.el?.focus();
  inputRef.value?.el?.blur();
}

async function switchImperal(active = false) {
  if (form.value.activeImperial === active) return;
  form.value.activeImperial = !!active;

  let value = 0;

  if (form.value.activeImperial) {
    form.value.type = Weights.LB;

    value = original.value.unit === Weights.LB
      ? original.value.value
      : kgToLb(original.value.value, clientLang.value);
  } else {
    form.value.type = Weights.KG;

    value = original.value.unit === Weights.KG
      ? original.value.value
      : lbToKg(original.value.value, clientLang.value);
  }

  updateValue(value);

  await forceRender();
  triggerInputValidation();
}

function finish(event?: any) {
  if (!canSubmit.value) return;

  triggerInputValidation();

  const value = +getRealNumber(`${textValue.value}`, clientLang.value)
    .toFixed(clientFormat.value.scale);

  globalEmit('nextQuestion', [event, JSON.stringify({
    unit: form.value.type,
    value: (isNaN(value) || value === null) ? '' : value
  })]);
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    const val = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'weightinput',
    }) || '';

    if (val) {
      const obj = JSON.parse(val);
      form.value.type = obj.unit;
      form.value.activeImperial = obj.unit === Weights.LB;
      textValue.value = getClientNumber(obj.value, clientLang.value);
      updateOriginalValue();
    }
  }
}

async function forceRender() {
  renderComponent.value = false;
  await nextTick();
  renderComponent.value = true;
}

onBeforeMount(() => {
  setPreviousAnswer();
});
</script>

<style lang="postcss" scoped>
.tw-weightinput-element {
  &__tab {
    @apply rounded-8 p-[2px] bg-neutral-100 flex mb-16 max-w-[196px] mx-auto;
    
    button {
      @apply block rounded-8 bg-transparent text-12 leading-5 text-neutral-900 h-[28px] flex-1;
      &.active {
        @apply bg-white shadow-[0px_6px_20px_0px_rgba(21,27,38,0.16)];
      }
    }
  }
}
</style>

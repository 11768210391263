<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="tw-text-element-wrapper">
    <DynamicComp
      :id="element.id"
      :contenteditable="contenteditable"
      :tag="tagName"
      :text="purify(textValue)"
      class="tw-text-element"
      :class="{ 'tw-text-maxW': hasAnyMaxWidth, hasMobileFontSize, hasTabletFontSize }"
      :style="textStyle"
      v-bind="{ ...$attrs, ...attr }"
    />
  </div>
</template>

<script lang="ts" setup>
import { type PropType, type Ref, computed, toRef } from 'vue';
import type { Element as ElementType, PageOptions } from '../../types/model';
import DynamicComp from '../../components/dynamic-comp/index.vue';
import { purify } from '../../utils';
import { useConditionalValue } from '@shared/composable/useConditionalValue';
import { useSpacing } from '@shared/composable/useSpacing';

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  tagName: { type: String, default: 'p' },
  value: { type: String, default: '' },
  attr: { type: Object as PropType<ElementType<'text' | 'conditional-text'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'text' | 'conditional-text' | 'translation'>>, default: () => ({}) },
  style: { type: Object as PropType<ElementType<'text' | 'conditional-text'>['style']>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  pageData: { type: Object, default: () => ({ isLoading: false, isDisabled: undefined }) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  contenteditable: { type: Boolean, default: false }
});

const element = toRef(props, 'element');
const { textValue, condition } = useConditionalValue(props);
const { padding, margin } = useSpacing(element as Ref<ElementType<'text'>>);

function getTextStyle() {
  const color = props.style.color || props.pageOptions.colors?.text[0];
  const fontFamily = props.style.fontFamily || props.pageOptions?.fontFamily;
  const obj = {
    ...props.style,
    color,
    fontFamily
  };

  delete obj.hover;

  return obj;
}

const textStyle = computed(() => getTextStyle());
const maxWidth = computed(() => {
  const unit = props.element.options.maxWidthUnit || '%';
  const maxWidth = props.element.options.maxWidth;
  const limit = unit === 'px' ? 1320 : 100;

  return {
    desktop: maxWidth?.desktop && maxWidth?.desktop <= limit && maxWidth?.desktop > 0 ? `${maxWidth.desktop}${unit}` : null,
    tablet: maxWidth?.tablet && maxWidth?.tablet <= limit && maxWidth?.tablet > 0 ? `${maxWidth.tablet}${unit}` : null,
    mobile: maxWidth?.mobile && maxWidth?.mobile <= limit && maxWidth?.mobile > 0 ? `${maxWidth.mobile}${unit}` : null,
  }
});
const hasAnyMaxWidth = computed(() => {
  return !!(maxWidth.value.desktop || maxWidth.value.tablet || maxWidth.value.mobile)
});

const currentResponsiveFontSizes = computed(() => {
  if (props.element.type === 'conditional-text') {
    return (props.element as ElementType<'conditional-text'>).options.conditionalOptions?.[condition.value]?.responsiveFontSize
  }

  return props.element.options.responsiveFontSize
})

const hasMobileFontSize = computed(() => {
  const fontSizes = currentResponsiveFontSizes.value;
  return fontSizes && fontSizes?.mobile.active && fontSizes?.mobile.size
})

const hasTabletFontSize = computed(() => {
  const fontSizes = currentResponsiveFontSizes.value;
  return fontSizes && fontSizes?.tablet.active && fontSizes?.tablet.size
})

const responsiveFontSizes = computed(() => {
  const fontSizes = currentResponsiveFontSizes.value;
  return {
    tablet: hasTabletFontSize.value ? `${fontSizes?.tablet.size}px` : 'inherit',
    mobile: hasMobileFontSize.value ? `${fontSizes?.mobile.size}px` : 'inherit',
  }
})

const lineHeight = computed(() => {
  return props.element.style.lineHeight || 'inherit'
})
</script>

<style lang="postcss" scoped>
.tw-text-element {
  --text-maxWidth-desktop: v-bind('maxWidth.desktop');
  --text-maxWidth-tablet: v-bind('maxWidth.tablet');
  --text-maxWidth-mobile: v-bind('maxWidth.mobile');

  --text-fontSize-tablet: v-bind('responsiveFontSizes.tablet');
  --text-fontSize-mobile: v-bind('responsiveFontSizes.mobile');

  --text-lineHeight: v-bind('lineHeight');

  /* PADDING */
  --text-desktop-padding-top: v-bind('padding?.desktop?.top');
  --text-desktop-padding-left: v-bind('padding?.desktop?.left');
  --text-desktop-padding-bottom: v-bind('padding?.desktop?.bottom');
  --text-desktop-padding-right: v-bind('padding?.desktop?.right');

  --text-tablet-padding-top: v-bind('padding?.tablet?.top');
  --text-tablet-padding-left: v-bind('padding?.tablet?.left');
  --text-tablet-padding-bottom: v-bind('padding?.tablet?.bottom');
  --text-tablet-padding-right: v-bind('padding?.tablet?.right');

  --text-mobile-padding-top: v-bind('padding?.mobile?.top');
  --text-mobile-padding-left: v-bind('padding?.mobile?.left');
  --text-mobile-padding-bottom: v-bind('padding?.mobile?.bottom');
  --text-mobile-padding-right: v-bind('padding?.mobile?.right');

  padding-top: var(--text-mobile-padding-top, 0px);
  padding-left: var(--text-mobile-padding-left, 0px);
  padding-bottom: var(--text-mobile-padding-bottom, 0px);
  padding-right: var(--text-mobile-padding-right, 0px);

  word-wrap: break-word;
  overflow-wrap: anywhere;
  min-height: 20px;
  min-width: 1px;
  width: 100%;
  line-height: var(--text-lineHeight) !important;

  * { line-height: inherit; }

  &[placeholder] {
    &:empty:before {
      content: attr(placeholder);
      color: #555;
      font-style: italic;
    }

    &:empty:focus::before {
      content: "";
    }
  }

  &:focus-visible{ outline: none; }

  a {
    color: currentColor;

    * {
      text-decoration: underline;
    }
  }

  &-wrapper {
    /* MARGIN */
  --text-desktop-margin-top: v-bind('margin?.desktop?.top');
  --text-desktop-margin-left: v-bind('margin?.desktop?.left');
  --text-desktop-margin-bottom: v-bind('margin?.desktop?.bottom');
  --text-desktop-margin-right: v-bind('margin?.desktop?.right');

  --text-tablet-margin-top: v-bind('margin?.tablet?.top');
  --text-tablet-margin-left: v-bind('margin?.tablet?.left');
  --text-tablet-margin-bottom: v-bind('margin?.tablet?.bottom');
  --text-tablet-margin-right: v-bind('margin?.tablet?.right');

  --text-mobile-margin-top: v-bind('margin?.mobile?.top');
  --text-mobile-margin-left: v-bind('margin?.mobile?.left');
  --text-mobile-margin-bottom: v-bind('margin?.mobile?.bottom');
  --text-mobile-margin-right: v-bind('margin?.mobile?.right');

    @apply flex justify-center;
    margin-top: var(--text-mobile-margin-top, 0px);
    margin-left: var(--text-mobile-margin-left, 0px);
    margin-bottom: var(--text-mobile-margin-bottom, 0px);
    margin-right: var(--text-mobile-margin-right, 0px);
  }
}

.desktop {
  .tw-text-element {
    padding-top: var(--text-desktop-padding-top, 0px);
    padding-left: var(--text-desktop-padding-left, 0px);
    padding-bottom: var(--text-desktop-padding-bottom, 0px);
    padding-right: var(--text-desktop-padding-right, 0px);

    &-wrapper {
      margin-top: var(--text-desktop-margin-top, 0px);
      margin-left: var(--text-desktop-margin-left, 0px);
      margin-bottom: var(--text-desktop-margin-bottom, 0px);
      margin-right: var(--text-desktop-margin-right, 0px);
    }

    &.tw-text-maxW {
      max-width: var(--text-maxWidth-desktop);
    }
  }
}

.tablet {
  .tw-text-element {
    padding-top: var(--text-tablet-padding-top, 0px);
    padding-left: var(--text-tablet-padding-left, 0px);
    padding-bottom: var(--text-tablet-padding-bottom, 0px);
    padding-right: var(--text-tablet-padding-right, 0px);

    &-wrapper {
      margin-top: var(--text-tablet-margin-top, 0px);
      margin-left: var(--text-tablet-margin-left, 0px);
      margin-bottom: var(--text-tablet-margin-bottom, 0px);
      margin-right: var(--text-tablet-margin-right, 0px);
    }

    &.tw-text-maxW {
      max-width: var(--text-maxWidth-tablet);
    }
    &.hasTabletFontSize, &.hasTabletFontSize * {
      font-size: var(--text-fontSize-tablet) !important;
    }
  }
}

.mobile {
  .tw-text-element {
    padding-top: var(--text-mobile-padding-top, 0px);
    padding-left: var(--text-mobile-padding-left, 0px);
    padding-bottom: var(--text-mobile-padding-bottom, 0px);
    padding-right: var(--text-mobile-padding-right, 0px);

    &-wrapper {
      margin-top: var(--text-mobile-margin-top, 0px);
      margin-left: var(--text-mobile-margin-left, 0px);
      margin-bottom: var(--text-mobile-margin-bottom, 0px);
      margin-right: var(--text-mobile-margin-right, 0px);
    } 

    &.tw-text-maxW {
      max-width: var(--text-maxWidth-mobile);
    }
    &.hasMobileFontSize, &.hasMobileFontSize * {
      font-size: var(--text-fontSize-mobile) !important;
    }
  }
}

.tw-text-element {
  @media (min-width: 768px) {
    padding-top: var(--text-tablet-padding-top, 0px);
    padding-left: var(--text-tablet-padding-left, 0px);
    padding-bottom: var(--text-tablet-padding-bottom, 0px);
    padding-right: var(--text-tablet-padding-right, 0px);

    &-wrapper {
      margin-top: var(--text-tablet-margin-top, 0px);
      margin-left: var(--text-tablet-margin-left, 0px);
      margin-bottom: var(--text-tablet-margin-bottom, 0px);
      margin-right: var(--text-tablet-margin-right, 0px);
    } 
  }

  @media (min-width: 1024px) {
    padding-top: var(--text-desktop-padding-top, 0px);
    padding-left: var(--text-desktop-padding-left, 0px);
    padding-bottom: var(--text-desktop-padding-bottom, 0px);
    padding-right: var(--text-desktop-padding-right, 0px);

    &-wrapper {
      margin-top: var(--text-desktop-margin-top, 0px);
      margin-left: var(--text-desktop-margin-left, 0px);
      margin-bottom: var(--text-desktop-margin-bottom, 0px);
      margin-right: var(--text-desktop-margin-right, 0px);
    }
  }

  &.tw-text-maxW {
    max-width: var(--text-maxWidth-mobile);

    @media (min-width: 768px) {
      max-width: var(--text-maxWidth-tablet);
    }

    @media (min-width: 1024px) {
      max-width: var(--text-maxWidth-desktop);
    }
  }
  &.hasMobileFontSize, &.hasMobileFontSize * {
    @media (max-width: 767px) {
      font-size: var(--text-fontSize-mobile) !important;
    }
  }
  &.hasTabletFontSize, &.hasTabletFontSize * {
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: var(--text-fontSize-tablet) !important;
    }
  }
}
</style>

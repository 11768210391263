import { computed, type Ref } from 'vue';
import type { Element as ElementType } from '@shared/types/model';
import type { ScreenSize, ScreenSizeStyle } from '@shared/types/options';

type DefaultObj = Record<'sizes', ScreenSizeStyle>;

export function prepareSizesObject(sizes?: ScreenSizeStyle) {
  return Object.entries(sizes || {}).reduce((acc, [screenSize, breaks]) => {
    acc[screenSize as ScreenSize] = Object.entries(breaks).reduce((acc2, [dir, value]) => {
      if (dir === 'width' || dir === 'height') {
        acc2[dir] = typeof value === 'number' ? `${value}px` : value;
      }else {
        acc2[dir] = value
      }
      return acc2;
    }, {} as Record<any, number | string | boolean>);
    return acc;
  }, {} as Record<ScreenSize, Record<any, number | string | boolean>>);
}


export function useSizes(element: Ref<ElementType<'image'>>, defaultObj?: DefaultObj) {
  return computed(() => prepareSizesObject(element.value.options.sizes || defaultObj?.sizes))
}

export function useSizing(element: Ref<ElementType<'image'>>, defaultObj?: DefaultObj) {
  return {
    sizes: useSizes(element, defaultObj)
  }
}

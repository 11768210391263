<template>
  <component
    :is="comp"
    :id="element.id"
    class="tw-element"
    :class="[element.attr?.class, { 'pointer-events-none': isEditorMode && /^(video|image)$/g.test(props.type) }]"
    :hover="hover"
    :style="style"
    :config="config"
    :is-editor-mode="isEditorMode"
    :is-preview-mode="isPreviewMode"
    :element="element"
    :page-data="pageData"
    :locale="locale"
    :page-options="pageOptions"
    v-bind="{ ...$attrs, ...attr }"
    v-on="eventsFromConfig"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </component>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, type PropType } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementTypes } from '@shared/types/options';
import { globalEmit, toFirstUpperCase } from '@shared/utils/helpers';

const props = defineProps({
  type: { type: String as PropType<ElementType<ElementTypes>['type']>, required: true },
  hover: { type: Object as PropType<ElementType<ElementTypes>['style']['hover']>, required: true },
  style: { type: Object as PropType<ElementType<ElementTypes>['style']>, default: () => ({}) },
  attr: { type: Object, default: () => ({}) },
  config: { type: Object as PropType<ElementType<ElementTypes>['options']>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  isPreviewMode: { type: Boolean, default: false },
  element: { type: Object as PropType<ElementType<ElementTypes>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  locale: { type: String, default: '' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const comp = computed(() => {
  if (!props.isEditorMode) {
    // Globally registered component on generate
    return `Element${toFirstUpperCase(props.type)}`;
  }
  return defineAsyncComponent(() => import(`./${props.type}/index.vue`));
});

const eventsFromConfig = computed(() => getEventsFromElement(props.element.on));

function getEventsFromElement(on = {}) {
  // Events will not be fired on editor mode
  if (props.isEditorMode) return {};
  return Object.entries(on).reduce((acc: any, [key, value]: any[]) => {
    acc[key] = (emitObj: any) => globalEmit(value, [emitObj, null]);
    return acc;
  }, {});
}
</script>

<style lang="postcss" scoped>
.tw-element {
  @apply relative focus-visible:outline-none;
}
</style>

<template>
  <div class="tw-heightinput-element !m-0 md:!mx-8 md:!mb-8 md:!mt-0 md:max-w-[400px]">
    <div class="mx-auto md:max-w-[280px]" :class="{ 'pointer-events-none': isEditorMode }">
      <div v-if="config.imperial" class="tw-heightinput-element__tab">
        <button
          type="button"
          :class="{ active: form.activeImperial }"
          @click="switchImperal(true)"
        >
          ft
        </button>
        <button
          type="button"
          :class="{ active: !form.activeImperial }"
          @click="switchImperal(false)"
        >
          cm
        </button>
      </div>

      <div v-if="isImperialActive" class="mb-16">
        <div class="flex">
          <div class="w-1/2 pr-8">
            <WebInput
              v-if="renderComponent"
              type="number"
              name="heightinput_feet"
              autocomplete="off"
              class-name="!mb-0 [&_.tw-state-error_.tw-unit]:text-error-500"
              force-mask-update
              v-bind="getAttrsByConfig(true)"
              :value="`${form.ft}`"
              :page-options="pageOptions"
              :validate-on-update="true"
              :error="!!Object.keys(errorForImperial).length"
              @update:model-value="onUpdateImperial('ft', $event)"
            >
              <template #right>
                <span class="tw-unit pl-[5px] text-12 leading-[18px] text-neutral-500">ft</span>
              </template>
            </WebInput>
          </div>
    
          <div class="w-1/2 pl-8">
            <WebInput
              v-if="renderComponent"
              type="number"
              name="heightinput_inches"
              autocomplete="off"
              class-name="!mb-0 [&_.tw-state-error_.tw-unit]:text-error-500"
              force-mask-update
              v-bind="getAttrsByConfig(true)"
              :min="0"
              :max="11"
              :value="`${form.in}`"
              :page-options="pageOptions"
              :validate-on-update="true"
              :error="!!Object.keys(errorForImperial).length"
              @update:model-value="onUpdateImperial('in', $event)"
            >
              <template #right>
                <span class="tw-unit pl-[5px] text-12 leading-[18px] text-neutral-500">in</span>
              </template>
            </WebInput>
          </div>
        </div>

        <div
          v-if="!initForm && Object.keys(errorForImperial).length"
          class="mt-8 block text-12 text-error-500"
        >
          <template v-if="errorForImperial?.between">
            {{
              translate('generate.form.validation.rule.between', locale, {
                min: errorForImperial.between.min,
                max: errorForImperial.between.max 
              }).replace(/(0|1):/g, '')
            }}
          </template>
          <template v-else-if="errorForImperial?.min_value">
            {{
              translate('generate.form.validation.rule.min_value', locale, {
                min: errorForImperial.min_value
              }).replace(/(0|1):/g, '')
            }}
          </template>
          <template v-else-if="errorForImperial?.max_value">
            {{
              translate('generate.form.validation.rule.min_value', locale, {
                min: errorForImperial.max_value
              }).replace(/(0|1):/g, '')
            }}
          </template>
          <template v-else-if="errorForImperial?.required">
            {{
              translate('generate.form.validation.rule.required', locale)
            }}
          </template>
        </div>
      </div>

      <WebInput
        v-else-if="renderComponent"
        ref="cmInputRef"
        type="number"
        name="heightinput_cm"
        autocomplete="off"
        class-name="[&_.tw-state-error_.tw-unit]:text-error-500"
        force-mask-update
        v-bind="getAttrsByConfig()"
        :value="`${form.cm}`"
        :page-options="pageOptions"
        :rules="rules"
        :validate-on-update="true"
        @error="onInputErr"
        @update:model-value="onUpdateCm($event)"
      >
        <template #right>
          <span class="tw-unit pl-[5px] text-12 leading-[18px] text-neutral-500">cm</span>
        </template>
      </WebInput>
    </div>

    <InfoTag
      v-if="canInfotagVisible"
      :icon="infoTag?.icon"
      :title="purify(infoTag?.title || '')"
      :description="purify(infoTag?.description || '')"
      :color="infoTag?.color"
      class="mx-auto mb-16 md:max-w-[280px]"
      :style="infoStyle"
    />

    <div v-if="$slots.default" class="tw-heightinput-element__bottom mx-auto md:max-w-[280px]">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, toRefs, onBeforeMount, watch, nextTick } from 'vue';
import { globalEmit, getClientNumber, getClientNumberFormat, getRealNumber, isClient } from '@shared/utils/helpers';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import WebInput from '@shared/components/input/index.vue';
import InfoTag from '@shared/components/infotag/index.vue';
import { useTranslate } from '@shared/composable/useTranslate';
import { purify } from '@shared/utils';
import { cmToFeet, feetToCm } from './utils';

const props = defineProps({
  locale: { type: String, default: '' },
  config: { type: Object as PropType<ElementOptions['heightinput']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  element: { type: Object as PropType<ElementType<'heightinput'>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const { translate } = useTranslate();

const clientLang = computed(() => {
  return globalThis?.navigator?.language || props.pageData.countryCode || '';
});
const renderComponent = ref(true);
const cmInputRef = ref<HTMLInputElement|any>(null)
const textValue = ref<string|number>(getDefaultValue());
const imperalDefaultValues = cmToFeet(parseFloat(`${textValue.value || 0}`));
const form = ref({
  activeImperial: getImperalTabActiveStatus(),
  ft: textValue.value ? imperalDefaultValues.feet : '',
  in: textValue.value ? imperalDefaultValues.inches : '',
  cm: textValue.value
});

const waitFor = ref({
  imperal: false,
  cm: false,
})

const isInputValid = ref(true);
const initForm = ref(true);
const { element, pageOptions } = toRefs(props);
const { values } = useLabelOptions({ element, pageOptions });
const isImperialActive = computed(() => props.config.imperial && form.value.activeImperial);
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const infoTag = computed(() => values.value?.infoTag || {});
const infoStyle = computed(() => {
  return {
    fontFamily: props.pageOptions.fontFamily,
    titleColor: element.value.options.customizeTagTextColor ? element.value.options.infoTagTitleColor : props.pageOptions.colors?.text[0],
    descColor: element.value.options.customizeTagTextColor ?  element.value.options.infoTagDescColor : props.pageOptions.colors?.text[1],
  }
});

const isEmpty = computed(() => {
  return form.value.cm === '' || form.value.cm === 'NaN' || isNaN(getRealNumber(form.value.cm, clientLang.value));
});

const errorForImperial = computed(() => {
  if (props.isEditorMode) return {};
  if (initForm.value) return {}

  const currentValue = cmToFeet(getRealNumber(`${form.value.cm}`, clientLang.value));
  const currentVal = getFeetValue(currentValue);

  if (props.config.required && isEmpty.value && !initForm.value) {
    return { required: true }
  } else if (!props.config.required && isEmpty.value) return {};
  
  const { min, max } = props.config.input || {};
  const defaultMinValue = 0;
  const defaultMaxValue = undefined;
  const minValue = min?.active ? parseFloat(`${min?.limit || ''}`) || defaultMinValue : defaultMinValue;
  const maxValue = max?.active ? parseFloat(`${max?.limit || ''}`) || defaultMaxValue : defaultMaxValue;
  const minFeet = min?.active ? cmToFeet(minValue) : undefined;
  const maxFeet = maxValue ? cmToFeet(maxValue) : undefined;

  const minValStr = `${minFeet?.feet}'${minFeet?.inches}"`;
  const maxValStr = `${maxFeet?.feet}'${maxFeet?.inches}"`;
  const minVal = minFeet ? getFeetValue(minFeet) : undefined;
  const maxVal = maxFeet ? getFeetValue(maxFeet) : undefined;

  // Feet rules
  if (min?.active && max?.active && maxVal !== undefined && minVal !== undefined) {
    if (currentVal > maxVal || currentVal < minVal) {
      return { between: { min: minValStr, max: maxValStr } }
    }
  }

  if (min?.active && minVal !== undefined) {
    if (currentVal < minVal) {
      return { min_value: minValStr }
    }
  }

  if (max?.active && maxVal !== undefined) {
    if (maxVal && currentVal > maxVal) {
      return { max_value: maxValStr }
    }
  }

  return {};
})

const rules = computed(() => {
  const { min, max } = props.config.input || {};
  const isRequired = props.config.required;
  const defaultMinValue = 0;
  const defaultMaxValue = undefined;
  const minValue = min?.active ? parseFloat(`${min?.limit || ''}`) || defaultMinValue : defaultMinValue;
  const maxValue = max?.active ? parseFloat(`${max?.limit || ''}`) || defaultMaxValue : defaultMaxValue;

  let cmRules = isRequired ? 'required' : '';

  if (minValue !== undefined && maxValue !== undefined) {
    cmRules += `|between:${minValue},${maxValue}`
  }

  if (minValue !== undefined && maxValue === undefined) {
    cmRules += `|min_value:${minValue}`
  }

  if (maxValue !== undefined && minValue === undefined) {
    cmRules += `|max_value:${maxValue}`
  }

  return cmRules;
});

const canInfotagVisible = computed(() => {
  return (
    element.value.options.showInfoTag &&
    (infoTag.value?.title || infoTag.value?.description) &&
    (props.isEditorMode || (canSubmit.value || (isInputValid.value && !Object.keys(errorForImperial).length)))
  )
});

const canSubmit = computed(() => {
  const { value, min, max } = getLimits();
  
  if (props.config?.input) {
    if (!isEmpty.value && min.active && min.limit !== undefined && value < min.limit) {
      return false;
    }

    if (!isEmpty.value && max.active && max.limit !== undefined && value > max.limit) {
      return false;
    }
  }

  if (props.config.required) return !isEmpty.value;

  return true
});

if (isClient()) {
  watch(
    () => props.config.imperial,
    (val) => form.value.activeImperial = !!val
  );

  watch(
    () => props.config.defaultValue,
    (val) => {
      (form.value.cm = `${val || ''}`)
      onHandleCm();
      if (props.isEditorMode) forceRender();
    }
  );
}

function getFeetValue(imperal: { feet: number, inches: number }) {
  return parseFloat(`${imperal.feet}.${imperal.inches < 10 ? 0 : ''}${imperal.inches}`);
}

function getLimits() {
  const { min, max } = props.config?.input || {};
  const defaultMinValue = 0;
  const defaultMaxValue = undefined;
  let currentValue = getRealNumber(`${form.value.cm}`, clientLang.value);
  let minValue = min?.active ? parseFloat(`${min?.limit || ''}`) || defaultMinValue : defaultMinValue;
  let maxValue = max?.active ? parseFloat(`${max?.limit || ''}`) || defaultMaxValue : defaultMaxValue;

  if (form.value.activeImperial) {
    minValue = getFeetValue(cmToFeet(minValue));
    if (maxValue) maxValue = getFeetValue(cmToFeet(maxValue));
    currentValue = getFeetValue(cmToFeet(currentValue))
  }

  return {
    value: currentValue,
    min: {
      active: !!min?.active,
      limit: minValue
    },
    max: {
      active: !!max?.active,
      limit: maxValue
    }
  }
}

function getImperalTabActiveStatus() {
  if (props.config.imperial) {
    if (props.isEditorMode) return true;
    if (props.config.selectionByCountry) return ['UK', 'GB', 'US'].includes(props.pageData?.countryCode);
  }
  return false;
}

function onInputErr(validation: any[]) {
  const [hasError] = validation;
  isInputValid.value = !hasError;
}

function onHandleImperial() {
  if (waitFor.value.cm) return;

  try {
    waitFor.value.imperal = true;

    const cmValue = feetToCm(+form.value.ft, +form.value.in);
    form.value.cm = (form.value.ft !== '' || form.value.in !== '')
      ? getClientNumber(cmValue, clientLang.value)
      : '';
  } finally {
    waitFor.value.imperal = false;
  }
}

function onHandleCm() {
  if (waitFor.value.imperal) return;

  try {
    waitFor.value.cm = true;
    const cmValue = getRealNumber(`${form.value.cm}`, clientLang.value);
    const impervalValues = cmToFeet(cmValue);

    form.value.ft = form.value.cm ? impervalValues.feet : '';
    form.value.in = form.value.cm ? impervalValues.inches : '';
  } finally {
    waitFor.value.cm = false;
  }
}

function onUpdateCm(value: any) {
  form.value.cm = value ? getClientNumber(parseFloat(value), clientLang.value) : '';
  onHandleCm();
}

function onUpdateImperial(type: 'ft' | 'in', value: any) {
  form.value[type] = value;
  initForm.value = false;
  onHandleImperial()
}

function getAttrsByConfig(integer = false) {
  const payload: Partial<{
    mask: { mask: typeof Number } & ReturnType<typeof getClientNumberFormat>;
    min: string | number;
    max: string | number | undefined;
  }> = {
    mask: {
      mask: Number,
      ...getClientNumberFormat(clientLang.value),
      scale: 2 // limited by request
    },
    min: 0
  }

  if (integer && payload.mask) {
    payload.mask.scale = 0;
    payload.mask.normalizeZeros = false;
  }

  return payload;
}

async function forceRender() {
  renderComponent.value = false;
  await nextTick();
  renderComponent.value = true;
}

function getDefaultValue(value?: string | number) {
  let defaultValue = props.config.defaultValue;
  if (value !== undefined) defaultValue = value;

  if (defaultValue !== undefined && defaultValue !== '') {
    if (props.isEditorMode) {
      defaultValue = getRealNumber(defaultValue, clientLang.value);
    }

    if (defaultValue !== '' && parseFloat(`${defaultValue || '0'}`) < 0) {
      defaultValue = 0
    }
  }

  return defaultValue || '';
}

function finish(event?: any) {
  if (!canSubmit.value) return;
  const payload = {
    unit: 'cm',
    value: getRealNumber(`${form.value.cm}`, clientLang.value) as number | string
  }

  if (form.value.activeImperial) {
    payload.unit = 'ft';
    payload.value = `${form.value.ft}.${form.value.in}`
  }

  if (isNaN(payload.value as number) || payload.value === null) {
    payload.value = '';
  }

  globalEmit('nextQuestion', [event, JSON.stringify(payload)]);
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    const val = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'heightinput',
    });

    if (val) {
      const obj = JSON.parse(val);

      form.value.activeImperial = obj.unit === 'ft';

      if (form.value.activeImperial) {
        const [ftVal, inVal] = `${obj.value}`.split('.');
        form.value.ft = parseFloat(ftVal);
        form.value.in = parseFloat(inVal);
        form.value.cm = getClientNumber(feetToCm(form.value.ft, form.value.in), clientLang.value);
      } else {
        form.value.cm = getClientNumber(obj.value, clientLang.value);
        const { feet, inches } = cmToFeet(obj.value);
        form.value.ft = feet;
        form.value.in = inches;
      }
    }
  }
}

function switchImperal(active = false) {
  if (form.value.activeImperial === active) return;
  form.value.activeImperial = !!active;
  if (!active && !canSubmit.value && !isEmpty.value) {
    nextTick(() => cmInputRef.value?.validate())
  }
}

onBeforeMount(() => {
  setPreviousAnswer();
});
</script>

<style lang="postcss" scoped>
.tw-heightinput-element {

  &__tab {
    @apply rounded-8 p-[2px] bg-neutral-100 flex mb-16 max-w-[196px] mx-auto;
    
    button {
      @apply block rounded-8 bg-transparent text-12 leading-5 text-neutral-900 h-[28px] flex-1;

      &.active {
        @apply bg-white shadow-[0px_6px_20px_0px_rgba(21,27,38,0.16)];
      }
    }
  }
}
</style>

import CloseCircle from "./CloseCircle.vue";
import Danger from "./Danger.vue";
import Info2 from "./Info2.vue";
import Loader from "./Loader.vue";
import Question from "./Question.vue";
import SearchNormal from "./SearchNormal.vue";
import SmallArrowDown from "./SmallArrowDown.vue";
import SmallArrowUp from "./SmallArrowUp.vue";
import Sms from "./Sms.vue";
import Tick2 from "./Tick2.vue";
import TickCircle from "./TickCircle.vue";
import Warning2 from "./Warning2.vue";
import XClose from "./XClose.vue";

const Icons = {
  CloseCircle,
  Danger,
  Info2,
  Loader,
  Question,
  SearchNormal,
  SmallArrowDown,
  SmallArrowUp,
  Sms,
  Tick2,
  TickCircle,
  Warning2,
  XClose
}

export type IconNames = keyof typeof Icons;

export default Icons;

<template>
  <div class="tw-scroll-picker">
    <div
      v-if="label && !hideLabel"
      :style="labelStyle"
      class="truncate text-center text-14 font-medium text-neutral-900"
    >
      {{ label }}
    </div>
    <VueScrollPicker
      v-bind="$attrs"
      v-model="vModelValue"
      :options="scrollPickerOptions"
      :placeholder="placeholder"
    />
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import { VueScrollPicker } from 'vue-scroll-picker';
import type { SelectOption } from '@shared/components/select/types';
import type { PageOptions } from '@shared/types/model';

const props = defineProps({
  modelValue: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  label: { type: String, default: '' },
  labelStyle: { type: Object, default: () => ({}) },
  hideLabel: { type: Boolean, default: false },
  options: { type: Array as PropType<SelectOption[]>, default: () => [] },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const emit = defineEmits(['update:modelValue']);

const vModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const scrollPickerOptions = computed(() => {
  return (props.options || []).map((item: any) => ({ name: item.label, value: item.value }));
});

const scrollPickerStyle = computed(() => {
  return {
    itemText: {
      color: props.pageOptions?.colors?.text?.[0]
    }
  };
});
</script>

<style src="vue-scroll-picker/lib/style.css"></style>
<style lang="postcss" scoped>
.tw-scroll-picker {
  --item-text-color: v-bind('scrollPickerStyle.itemText.color');

  :deep(.vue-scroll-picker) {
    @apply text-14;

    /* We replace vue-scroll-picker's gradient bg behaviour by adding mask-image gradient to its container
     * to be able to use user selected bg colors and images for section/page background
     */
    --layer-height: calc(50% - 1em - 1px); /* 1px is for the border width of layers */
    mask-image:
      linear-gradient(to bottom, transparent 0 var(--layer-height), black var(--layer-height), rgb(0 0 0 / 15%), transparent),
      linear-gradient(to top, transparent 0 var(--layer-height), black var(--layer-height), rgb(0 0 0 / 15%), transparent);

    &-item {
      @apply truncate;
      color: var(--item-text-color);
      &-selected {
        @apply font-bold;
        color: var(--item-text-color);
        &.vue-scroll-picker-item-placeholder {
          @apply font-normal text-neutral-500;
        }
      }
    }
    &-layer {
      &-top, &-bottom {
        @apply border-b border-t border-neutral-200;
        background: none;
      }
    }
  }
}
</style>

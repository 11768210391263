const defaultContainerSelectors = ['.tw-text-element', '.tw-element-button'];

/**
 * Finds all elements that in containerSelectors and replaces
 * the variables in the container with the given values by variablesObject
 * Ex: "This is mail: {USER_MAIL}" replaces to "This is mail: test@test.com"
 * @param variablesObject - {USER_MAIL: 'test@test.com', PRICE: 'TRY 10.00'}
 */
export function applyTemplateVariables(variablesObject: Record<string, string>, containerSelectors?:string[]) {
  const selectors = containerSelectors?.join() || defaultContainerSelectors?.join();
  const elements = document.querySelectorAll(selectors);

  for (const [key, value] of Object.entries(variablesObject)) {
    for (const elementItem of elements) {
      const fullKey = `{${key}}`;
      if (elementItem.innerHTML.includes(fullKey)) {
        const regex = new RegExp(fullKey, 'g');
        elementItem.innerHTML = elementItem.innerHTML.replace(regex, value);
      }
    }
  }
}

export default applyTemplateVariables;

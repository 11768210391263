export enum DOCS {
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_SERVICE = 'terms_of_service',
  COOKIE_POLICY = 'cookie_policy'
}

export enum UserActivationType {
  EMAIL_AND_PHONE = 'emailAndPhone',
  ZOTLO_ACTIVATION_CODE = 'zotloActivationCode',
  OWN_ACTIVATION_CODE = 'ownActivationCode',
}

export type UserActivationInstruction = {
  instruction: string;
  language: string;
}

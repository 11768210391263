<template>
  <div class="tw-payment-form-element w-full flex-col gap-16 overflow-hidden px-16 py-[22px] transition-all md:gap-32 md:p-24">
    <FormError
      v-if="pageData.paymentFormError"
      :is-editor-mode="isEditorMode"
      :page-data="pageData"
      :page-options="pageOptions"
      :locale="locale"
    />
    <template v-else>
      <ProviderTabs
        v-if="multiPaymentMethod"
        v-model="activeTab"
        :locale="locale"
        :element="element"
        :submit-button-element="submitButtonElement"
        :page-options="pageOptions"
        :get-provider-status="getProviderStatus"
        :dark-mode="!!element.options?.darkModeOnTabButtons"
        :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
      />

      <div :class="{ 'flex flex-1': !hasPaymentMethod || pageData.isSuccessLoading }">
        <component
          :is="isEditorMode ? 'div' : WebForm"
          ref="paymentFormElement"
          @[formSubmitEvent]="clickProvider(pageData.providerStatus[STRIPE] ? STRIPE : CREDIT_CARD)"
        >
          <WebSpinner
            v-if="pageData.isSuccessLoading"
            class="flex min-h-[530px] flex-col items-center justify-center"
          />
          <template v-else-if="hasPaymentMethod">
            <template v-if="activeTab === CREDIT_CARD">
              <PaymentStripeInputs
                v-if="getProviderStatus(STRIPE)"
                ref="stripeForm"
                v-model="form"
                :element="element"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
                :consent-style="consentStyle"
                :is-editor-mode="isEditorMode"
                :stripe-token="pageData.stripeToken"
                show-register-accept-policy
              />
              <PaymentFormInputs
                v-else-if="getProviderStatus(CREDIT_CARD)"
                v-model="form"
                :page-options="pageOptions"
                :is-label-visible="isLabelVisible"
                :label-style="labelStyle"
                :consent-style="consentStyle"
                :locale="locale"
                :is-editor-mode="isEditorMode"
                :page-data="pageData"
                :element="element"
                :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
                show-register-accept-policy
              />
            </template>
            <RegisterInput
              v-else-if="pageData.registerOnPayment"
              key="otherPaymentRegisterInput"
              v-model:subscriber-id="form.email"
              :disabled="!pageData.allowSubscriberIdEditing"
              name="OtherPayment_RegisterInput"
              hide-full-name-input
              class="mt-16"
              :locale="locale"
              :is-editor-mode="isEditorMode"
              :element="element"
              :page-data="pageData"
              :page-options="pageOptions"
              @error="(errMessage: string) => globalEmit('paymentFormInputError', errMessage)"
            />

            <template v-if="activeTab !== CREDIT_CARD">
              <AcceptPolicy
                v-if="isEditorMode || pageData.isPolicyRequired"
                v-model="form.acceptPolicy"
                :page-options="pageOptions"
                :page-data="pageData"
                :locale="locale"
                :consent-style="consentStyle"
                :input-class="{ 'pointer-events-none': isEditorMode }"
                :is-editor-mode="isEditorMode"
                :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
                class="mb-32 mt-16"
              />
            </template>

            <PriceInfo
              :page-options="pageOptions"
              :label-style="labelStyle"
              :total-price="totalPrice"
              :locale="locale"
              :is-editor-mode="isEditorMode"
              class="mb-32 mt-24 md:mt-32"
              :class="{ '!mt-8 md:!mt-0': activeTab !== CREDIT_CARD }"
            />
            <!-- DEFAULT SLOT (SUBMIT BUTTON) -->
            <div v-if="activeTab === CREDIT_CARD && $slots.default" class="tw-payment-form-element__button">
              <slot
                v-if="renderComponent"
                :locale="locale"
                :page-data="submitButtonProps"
                :page-options="pageOptions"
              />
              
              <!-- PAYMENT METHOD ICONS -->
              <PaymentMethodIcons class="my-8" />
            </div>

            <div
              v-if="isOtherPaymentMethodsActive"
              class="flex flex-col gap-16"
              :class="{ 'pointer-events-none': isEditorMode }"
            >
              <div v-if="activeTab === PAYPAL">
                <ProviderButton
                  :provider="PAYPAL"
                  :disabled="isSubmitLoading"
                  :loading="$wait.is(`${PAYPAL}:paymentProcessing`)"
                  :page-data="pageData"
                  :is-editor-mode="isEditorMode"
                  :is-preview-mode="isPreviewMode"
                  @[btnClick]="clickProvider(PAYPAL)"
                />
                <div class="mt-4 text-center text-12 font-semibold">
                  {{ translate('generate.pages.payment.paypalMotto', locale) }}
                </div>
              </div>

              <template v-if="activeTab === GOOGLE_PAY">
                <ProviderButton
                  :provider="GOOGLE_PAY"
                  :loading="$wait.is(`${GOOGLE_PAY}:paymentProcessing`)"
                  :disabled="isSubmitLoading"
                  :dark-mode="!!element.options?.darkModeOnTabButtons"
                  :page-data="pageData"
                  :is-editor-mode="isEditorMode"
                  :is-preview-mode="isPreviewMode"
                  @[btnClick]="clickProvider(GOOGLE_PAY)"
                />
              </template>

              <template v-if="activeTab === APPLE_PAY">
                <ProviderButton
                  :provider="APPLE_PAY"
                  :loading="$wait.is(`${APPLE_PAY}:paymentProcessing`)"
                  :disabled="isSubmitLoading"
                  :dark-mode="!!element.options?.darkModeOnTabButtons"
                  :page-data="pageData"
                  :is-editor-mode="isEditorMode"
                  :is-preview-mode="isPreviewMode"
                  @[btnClick]="clickProvider(APPLE_PAY)"
                />
              </template>
            </div>

            <!-- INFO TEXT -->
            <div v-if="$slots.infoText" class="tw-payment-form-element__info-text mt-24">
              <slot
                name="infoText"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </div>

            <!-- INFO TEXT 2 -->
            <div v-if="$slots.infoText2" class="tw-payment-form-element__info-text">
              <slot
                name="infoText2"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </div>
          </template>

          <template v-else>
            <NoPaymentMethodInfo
              :is-editor-mode="isEditorMode"
              :locale="locale"
              :page-options="pageOptions"
            />
          </template>
        </component>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, type PropType, toRefs, watch, onMounted } from 'vue';
import {
  PaymentMethodIcons,
  PaymentFormInputs,
  PaymentStripeInputs,
  ProviderButton,
  PriceInfo,
  ProviderTabs,
  NoPaymentMethodInfo
} from '@shared/elements/common/payment/components';
import { PaymentProvider, type PaymentElementTabs, type PaymentPageData } from '@shared/elements/common/payment/types';
import { WebForm, WebSpinner } from '@shared/components';
import { $wait } from '@shared/utils/wait';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { useTranslate } from '@shared/composable/useTranslate';
import { usePaymentElement } from '@shared/elements/common/payment/composables/usePaymentElement';
import FormError from '@shared/elements/common/payment/components/form-error/index.vue';
import RegisterInput from '@shared/elements/common/registration/components/registerInput.vue';
import { globalEmit, isClient } from '@shared/utils/helpers';
import AcceptPolicy from '@shared/elements/common/payment/components/accept-policy/index.vue';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  isPreviewMode: { type: Boolean, default: false },
  config: { type: Object as PropType<ElementOptions['payment-with-tabs']>, default: () => ({}) },
  pageData: { type: Object as PropType<PaymentPageData>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'payment-with-tabs'>>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const { translate } = useTranslate();

const { CREDIT_CARD, PAYPAL, GOOGLE_PAY, APPLE_PAY, STRIPE } = PaymentProvider;
const { element, pageOptions, pageData } = toRefs(props);
const activeTab = ref(setDefaultTab());

const formSubmitEvent = computed(() => {
  return activeTab.value !== CREDIT_CARD ? '' : 'submit';
});

if (isClient()) {
  watch(
    () => [pageData.value.providerStatus],
    () => {
      const providerStatus = pageData.value.providerStatus;
      const order = element.value.options?.order;

      if (order) {
        Object.entries(providerStatus).forEach(([provider, status]) => {
          if (!status) {
            order[provider as PaymentElementTabs] = -1;
          } else {
            if (order[provider as PaymentElementTabs] === -1 || order[provider as PaymentElementTabs] === undefined) {
              order[provider as PaymentElementTabs] = 1;
            }
          }
        });
      }
      activeTab.value = setDefaultTab();
    },
    { deep: true }
  );

  watch(
    () => [element.value.options?.order],
    () => {
      activeTab.value = setDefaultTab();
    },
    { deep: true }
  );
}

function setDefaultTab(): PaymentElementTabs {
  const order = element.value.options?.order;
  if (order) {
    const lowestOrderKey = Object.entries(order)
    .filter(([, value]) => value > 0)
    .sort((a,b) => a[1] - b[1])
    .map(([key]) => key)[0];
    if (lowestOrderKey === STRIPE) {
      return CREDIT_CARD;
    }
    return lowestOrderKey as PaymentElementTabs;
  }

  const priority: PaymentProvider[] = [
    CREDIT_CARD,
    STRIPE,
    PAYPAL,
    GOOGLE_PAY,
    APPLE_PAY,
  ];

  const defaultProvider = priority.find((provider) => pageData.value.providerStatus[provider]);

  if (defaultProvider === STRIPE) {
    return CREDIT_CARD;
  }

  return defaultProvider as PaymentElementTabs;
}

const {
  form,
  stripeForm,
  paymentFormElement,
  clickProvider,
  labelStyle,
  consentStyle,
  isLabelVisible,
  getProviderStatus,
  isOtherPaymentMethodsActive,
  totalPrice,
  isSubmitLoading,
  isSubmitDisabled,
  isLoadingPackage,
  submitButtonElement,
  hasPaymentMethod,
  forceRender,
  renderComponent
} = usePaymentElement({ element, pageData, pageOptions });

const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));

const submitButtonProps = computed(() => ({
  isLoading: $wait.is(`${CREDIT_CARD}:paymentProcessing`),
  isDisabled: isSubmitDisabled.value,
  applyConditionalValue: props.pageData?.applyConditionalValue,
  getActiveCondition: props.pageData?.getActiveCondition
}));

const multiPaymentMethod = computed(
  () => Object.values(pageData.value?.providerStatus).filter((item) => item).length > 1
);

if (isClient()) {
  watch(() => submitButtonProps.value, forceRender);

  watch(
    () => activeTab.value,
    (val) => {
      if (getProviderStatus(STRIPE) && val === CREDIT_CARD) {
        globalEmit('stripeLoaded', true);
      }
    }
  );
}

onMounted(() => {
  if (getProviderStatus(STRIPE) && activeTab.value !== CREDIT_CARD) {
    globalEmit('stripeLoaded', false);
  }
})
</script>

<style lang="postcss" scoped>
.tw-payment-form-element {
  &__info-text {
    a {
      color: #007aff !important;
    }
  }
}

@media (max-width: 640px) {
  .tw-payment-form-element {
    @apply w-full border-none;
  }
}

/* For editor screen only */
.tw-editor__screen {
  &.mobile {
    .tw-payment-form-element {
      @apply w-full border-none;
    }
  }
}
</style>

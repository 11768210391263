<template>
  <label :for="id" :class="wrapperClass">
    <input
      :id="id"
      v-model="inputvalue"
      :name="name"
      type="checkbox"
      role="checkbox"
      class="tw-checkbox"
      :disabled="disabled"
      :value="value"
      @change="handleChange"
    />
    <WebCheckmark
      :error="hasError"
      :disabled="disabled"
      :page-options="pageOptions"
    />
    <div v-if="isLabelOrHintExist" class="tw-checkbox-labelwrapper mx-12 flex flex-1 flex-col">
      <span v-if="isLabelExist" :class="labelTextClass">
        <template v-if="$slots.default">
          <slot />
        </template>
        <template v-else>{{ label }}</template>
      </span>
      <span v-if="isHintExist" :class="hintTextClass">
        <template v-if="hint">{{ hint }}</template>
        <template v-else>
          <slot name="hint" />
        </template>
      </span>
    </div>
  </label>
</template>

<script setup lang="ts">
import { computed, useSlots, toRef, watch, type Ref, type PropType } from 'vue';
import { useField } from 'vee-validate';
import WebCheckmark from '@shared/components/checkmark/index.vue';
import type { PageOptions } from '@shared/types/model';
import { isClient } from '@shared/utils/helpers';

const props = defineProps({
  modelValue: { type: [Number, Array, Boolean], default: false },
  value: { type: [Object, Boolean, String, Number], default: undefined },
  id: { type: String, default: undefined },
  disabled: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  block: { type: Boolean, default: false },
  checked: { type: Boolean, default: false },
  label: { type: String, default: '' },
  hint: { type: String, default: '' },
  name: { type: String, default: '' },
  rules: { type: [String, Object], default: undefined },
  variant: {
    type: String,
    default: 'primary',
    validator(value: string) {
      return ['primary', 'secondary'].includes(value);
    }
  },
  size: {
    type: String,
    default: 'md',
    validator(value: string) {
      return ['sm', 'md'].includes(value);
    }
  },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
});

const emit = defineEmits(['update:modelValue', 'change', 'error']);

const slots = useSlots();

const nameRef: Ref = toRef(props, 'name');
const rulesRef: Ref = toRef(props, 'rules');


const { handleChange, errors, value: inputvalue } = useField(nameRef, rulesRef, {
  type: 'checkbox',
  initialValue: !!props.modelValue || props.checked,
  checkedValue: true,
  uncheckedValue: false,
});

const hasError = computed(() => !!errors.value.length || props.error);
const wrapperClass = computed(() => [
  'relative',
  'tw-checkbox-component-wrapper',
  props.block ? 'flex' : 'inline-flex',
  `tw-checkbox--${props.variant}`,
  `tw-checkbox--${props.size}`,
  { 'tw-checkbox--is-disabled': props.disabled, 'tw-checkbox--has-error': hasError.value }
]);
const labelTextClass = computed(() => ['tw-checkbox-label', 'flex-1', { 'tw-checkbox-label--error': hasError.value }]);
const hintTextClass = computed(() => ['tw-checkbox-hint', { 'tw-checkbox-hint--error': hasError.value }]);

const isLabelExist = computed(() => slots.default || props.label);
const isHintExist = computed(() => slots.hint || props.hint);
const isLabelOrHintExist = computed(() => isLabelExist.value || isHintExist.value);

if (isClient()) {
  watch(
    () => inputvalue.value,
    (value) => {
      const val = Array.isArray(props.modelValue) ? value : (value ? props.value : false);
      emit('update:modelValue', val);
      emit('change', val);
    }
  )

  watch(
    () => hasError.value,
    () => emit('error', hasError.value)
  )
}
</script>

<style lang="postcss">
.tw-checkbox {
  @apply absolute left-0 top-0 h-20 w-20 opacity-0 items-center;
  
  &-component-wrapper {
    &:not(.tw-checkbox--secondary) {
      .tw-checkbox:not(:checked) {
        & + .tw-checkmark + div {
          .tw-checkbox-label {
            @apply text-neutral-500;
          }
        }
      }
    }
  }

  &:checked {
    & + .tw-checkmark + div {
      .tw-checkbox-label {
        @apply text-neutral-900;
      }
    }
  }

  &--has-error {
    .tw-checkbox-label,
    .tw-checkbox-hint,
    .tw-checkbox-hint a {
      @apply !text-error-500;
    }
  }

  &--is-disabled, &:disabled {
    @apply cursor-not-allowed;
    .tw-checkbox-label,
    .tw-checkbox-hint,
    .tw-checkbox-hint a {
      @apply !text-neutral-300;
    }
  }

  &-label {
    @apply text-14 font-medium;
  }
  &-hint {
    @apply text-12 font-normal text-neutral-500;
  }
  &--sm {
    .tw-checkbox {
      &-label {
        @apply mt-[2px] text-12;
      }
      &-hint {
        @apply text-12;
      }
    }
  }
}
</style>

import { type Ref, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { Payment, type PaymentPayload, type CreditCardDetails, PaymentResultStatus } from '@/services/payment';
import { Packages } from '@/services/packages';
import type { Page } from '@/shared/types/model';
import { PageType } from '@/types';
import { type PaymentPageData, PaymentProvider, PaymentFormErrors, PackagePaymentType, TrialPackageType } from '@/shared/elements/common/payment/types';
import { getPackagePrices, getPackagePeriodsInfo } from '@/shared/elements/common/payment/utils';
import { usePaymentStore } from '@/stores/payment';
import { useSiteData } from '@/composables/useSiteData';
import { COOKIE } from '@/utils/cookie';
import { clearToasts, showToast } from '@/utils/toast';
import { checkCurrentSession } from '@/utils/session';
import { GTM, GA4, Facebook } from '@/utils/integrations';
import { $wait } from '@/shared/utils/wait';
import type { StripeForm } from '@/shared/elements/common/payment/composables/usePaymentElement';
import { getBaseUrl } from '@/utils/getBaseUrl';
import { globalEmit, globalListener, globalRemoveListener } from '@shared/utils/helpers';
import { useRegisterCommon } from './common/useRegisterCommon';
import type { RegisterPayload } from '@/types/register';
import { useDocLinks } from './useDocLinks';

function paymentGTMClick(event: any) {
  GTM.push({
    event: 'customClick',
    clickCategory: 'Payment',
    clickName: event.target.innerText
  });

  GA4.gtag('event', 'customClick', {
    clickCategory: 'Payment',
    clickName: event.target.innerText
  });
}

function paymentGTMError(message: string) {
  GTM.push({
    event: 'error',
    errorCategory: 'Payment',
    errorType: message
  });

  GA4.gtag('event', 'error', {
    errorCategory: 'Payment',
    errorType: message
  });
}

function paymentGTMpageView() {
  GA4.gtag('event', 'page_view', {
    page_title: document?.title || '',
    page_location: window.location.pathname || '',
    previous_page_path: new URL(document.referrer || window.location.origin)?.pathname || '',
  });
}

export function usePayment(page: Ref<Page>) {
  const route = useRoute();
  const { slug } = getBaseUrl(route?.fullPath);
  const cookiePath = `/${slug}`;
  const subscriberIdCookie = useCookie(COOKIE.SUBSCRIBER_ID, { sameSite: 'none', secure: true, path: cookiePath });
  const paymentErrorCookie = useCookie(COOKIE.PAYMENT_ERROR, { sameSite: 'none', secure: true, path: cookiePath });
  const { isPreview, siteData, packages, agreement, epinDetails } = useSiteData();
  const paymentStore = usePaymentStore();
  const { providerConfigs, isGoogleReadyToPay, googlePayClient, isApplePaySdkLoaded } = storeToRefs(paymentStore);
  const { t: $t } = useI18n();
  const { pageData: commonRegisterPageData, registerUser } = useRegisterCommon();

  const domain = globalThis?.location?.origin;
  Payment.BASE_PATH = `${domain}/api/payment`;
  Packages.BASE_PATH = `${domain}/api/packages`;

  const isShownFailedMessage = ref(false);
  const selectedPackage = packages.value?.[0];

  function applyParameters(text: string) {
    if (!selectedPackage) return text;

    const { trialPrice, price, dailyPrice, weeklyPrice } = getPackagePrices(selectedPackage);
    const { period, periodType, trialPeriod, trialPeriodType } = getPackagePeriodsInfo(selectedPackage);

    return text
      .replace(new RegExp('{PRICE}', 'g'), price)
      .replace(new RegExp('{PERIOD}', 'g'), period === 0 ? '' : $t(`generate.periods.${periodType}`, { count: period }))
      .replace(new RegExp('{TRIAL_PRICE}', 'g'), trialPrice)
      .replace(new RegExp('{TRIAL_PERIOD}', 'g'), trialPeriod === 0 ? '' : $t(`generate.periods.${trialPeriodType}`, { count: trialPeriod }))
      .replace(new RegExp('{DAILY_PRICE}', 'g'), dailyPrice || price)
      .replace(new RegExp('{WEEKLY_PRICE}', 'g'), weeklyPrice || price);
  }

  function applyConditionalValue(conditionalValue: Record<string, {
    label: string;
    value: string;
  }>, fallbackText: string) {
    if (!selectedPackage) return '';

    const condition = getActiveCondition();
    return applyParameters(conditionalValue?.[condition]?.value || fallbackText || '');
  }

  function getActiveCondition() {
    let condition = 'package_with_trial';
    const isOneTimePayment = [PackagePaymentType.CONSUMABLE, PackagePaymentType.EPIN].includes(selectedPackage?.packageType);
    const isNoTrial = !selectedPackage?.trialPackageType || selectedPackage?.trialPackageType === TrialPackageType.NO;
    const isTrialUsed = selectedPackage?.trialPackageType && pageData.value.isTrialUsed;

    if (isOneTimePayment) {
      condition = 'onetime_payment';
    } else if (isNoTrial) {
      condition = 'plan_with_no_trial'
    } else if (isTrialUsed) {
      condition = 'package_with_trial_used';
    }

    return condition;
  }

  const pageData = ref<PaymentPageData>({
    isLoading: false,
    isSuccessLoading: true,
    selectedPackage: selectedPackage || {},
    isTrialUsed: false,
    subscriberId: siteData.value?.subscriberId || '',
    providerStatus: selectedPackage ? mergeProviderStatuses(selectedPackage?.providers, selectedPackage?.selectedProviders) : {},
    paymentFormError: !isPreview.value && packages.value.length === 0
      ? PaymentFormErrors.PACKAGE_LOAD_ERROR
      : PaymentFormErrors.NO_ERROR,
    paymentMethod: PaymentProvider.CREDIT_CARD,
    stripeToken: {
      token: '',
      initializeToken: '',
      amount: 0,
      currency: '',
      expireDate: '',
      transactionId: '',
      secureHash: ''
    },
    registerOnPayment: siteData.value?.registerOnPayment || false,
    ...commonRegisterPageData.value,
    isSubmitLoading: false,
    isSubmitDisabled: !isPreview.value,
    bypassRegister: !!siteData.value?.bypassRegister,
    allowSubscriberIdEditing: (
      !!siteData.value?.registerOnPayment &&
      (siteData.value?.bypassRegister
        ? !!siteData.value?.allowSubscriberIdEditingOnRegisterPayment
        : true)
    ),
    agreementData: {},
    isPolicyRequired: !!siteData.value?.isPolicyRequired,
    docLinks: useDocLinks(),
    countryCode: siteData.value?.countryCode || '',
    applyConditionalValue,
    getActiveCondition,
    getGooglePayButton: paymentStore.getGooglePayButton,
  });

  function handlePaymentErrorFromCookie() {
    if (paymentErrorCookie.value) {
      const errorMessage = paymentErrorCookie.value || '';
      showToast(errorMessage, '', 'error');
      paymentGTMError(errorMessage);
      paymentErrorCookie.value = '';
    }
  }

  function checkEpinPackageStatus() {
    if (selectedPackage?.packageType === PackagePaymentType.EPIN && !epinDetails.value?.epinCodeAvailable && !isPreview.value) {
      showToast($t('generate.errors.payment.epinCodeNotAvailable'), '', 'error');
      return false;
    }
    return true;
  }

  function checkEpinAutoRefundStatus(paymentResult: Record<string, any>) {
    // If the payment is successful but no epin is found, there will be an automatic refund in BE
    const isPaymentComplete = paymentResult?.status === PaymentResultStatus.COMPLETE;
    const isEpinPackage = selectedPackage?.packageType === PackagePaymentType.EPIN;
    const isEpinCodeAvailable = !!paymentResult?.epinStatus;
    if (!isPreview.value && isPaymentComplete && isEpinPackage && !isEpinCodeAvailable) {
      showToast($t('generate.errors.payment.epinCodeNotAvailableAndAutoRefunded'), '', 'error');
      return true;
    }
    return false;
  }

  function handleErrors() {
    if (paymentErrorCookie.value) return handlePaymentErrorFromCookie();
    if (!isStripeReturnFromPayment()) checkEpinPackageStatus();
  }

  function startProviderLoading(provider:PaymentProvider) {
    pageData.value.isSubmitLoading = true;
    pageData.value.isSubmitDisabled = true;
    $wait.start(`${provider}:paymentProcessing`);
  }

  function endProviderLoading(provider:PaymentProvider) {
    pageData.value.isSubmitLoading = false;
    pageData.value.isSubmitDisabled = false;
    $wait.end(`${provider}:paymentProcessing`);
  }

  function getFullUrl() {
    const { fullPath, pageType } = getBaseUrl();
    return [globalThis?.location?.origin || '', fullPath, pageType].join('/');
  }

  async function handleStripePayment(stripeForm: StripeForm, providerKey: PaymentProvider, acceptPolicy: boolean) {
    try {
      startProviderLoading(providerKey);
      const { error, paymentIntent } = await stripeForm.stripe.confirmPayment({
        elements: stripeForm.elements,
        redirect: 'if_required',
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: getFullUrl() + `?agree=${acceptPolicy}`,
        }
      });

      if (error) {
        if (error?.message) {
          const message = error?.message || '';
          showToast(message, '', 'error');
          paymentGTMError(message);
        } else {
          const message = "An unexpected error occurred.";
          showToast(message, '', 'error');
          paymentGTMError(message);
        }
        return false;
      }
      return paymentIntent;
    } finally {
      endProviderLoading(providerKey);
    }

  }

  function getUrlParam(key: string) {
    return new URLSearchParams(window.location.search).get(key);
  }

  function isStripeReturnFromPayment() {
    return getUrlParam('redirect_status');
  }

  // Fetches the payment intent status after payment submission
  function checkStripePaymentStatus() {
    const redirectStatus = getUrlParam('redirect_status') || '';

    switch (redirectStatus) {
      case 'requires_payment_method': {
        const message = $t('generate.stripe.messages.requires_payment_method');
        showToast(message, '', 'error');
        paymentGTMError(message);
        break;
      }
      case 'failed': {
        const message = $t('generate.stripe.messages.failed');
        showToast(message, '', 'error');
        paymentGTMError(message);
        break;
      }
    }

    isShownFailedMessage.value = true;

    return ['succeeded', 'processing'].includes(redirectStatus);
  }

  async function registerPaymentUser(payload: any, providerKey: PaymentProvider) {
    if (pageData.value.registerOnPayment) {
      if (!pageData.value.allowSubscriberIdEditing) return false;

      const registerPayload:RegisterPayload = {
        subscriberId: payload.creditCardDetails?.email || '',
      }

      if (![
        PaymentProvider.PAYPAL,
        PaymentProvider.APPLE_PAY,
        PaymentProvider.GOOGLE_PAY
      ].includes(providerKey)) {
        registerPayload.fullName = payload.creditCardDetails?.cardHolder || ''
      }
      const response = await registerUser(registerPayload) as Record<string, any>;
      if (!response || response?.meta?.errorCode) return false;
      delete payload.creditCardDetails?.email;
    }
  }

  async function handleSendPayment(paymentPayload: {
    form: CreditCardDetails,
    acceptPolicy: boolean,
    providerKey:PaymentProvider,
    stripeForm?: StripeForm
  }) {
    const { form, providerKey = PaymentProvider.CREDIT_CARD, stripeForm } = paymentPayload;
    startProviderLoading(providerKey);
    if (isPreview.value) return goToPage(PageType.PAYMENT_SUCCESS);
    clearToasts();
    if (!checkEpinPackageStatus()) return endProviderLoading(providerKey);
    const payload = preparePayload(form, providerKey, paymentPayload.acceptPolicy);

    try {
      checkCurrentSession();

      if (providerKey === PaymentProvider.APPLE_PAY) {
        return handleApplePayPayment(payload);
      } else if (providerKey === PaymentProvider.GOOGLE_PAY) {
        return handleGooglePayPayment(payload);
      } else {
        await registerPaymentUser(payload, providerKey);
      }

      if (providerKey === PaymentProvider.STRIPE) {
        if (!isShownFailedMessage.value && isStripeReturnFromPayment()) {
          if (!checkStripePaymentStatus()) return false;
        } else {
          const isCompleted = await handleStripePayment(stripeForm as StripeForm, providerKey, paymentPayload.acceptPolicy);
          if (!isCompleted) return false;
          startProviderLoading(providerKey);
        }
      }

      const { meta, result } = await Payment.sendPayment(payload);

      if (meta.httpStatus === 200) {
        setVisitedPages(PageType.PAYMENT);

        const { status, redirectUrl, payment } = result || {};
        if (status === PaymentResultStatus.REDIRECT && !!redirectUrl) {
          // Redirect to bank page
          if (globalThis?.location?.href) globalThis.location.href = redirectUrl;
        }
        if (checkEpinAutoRefundStatus(result)) return endProviderLoading(providerKey);
        if (status === PaymentResultStatus.COMPLETE && payment) {
          subscriberIdCookie.value = payment.subscriber?.subscriberId;
          goToPage(PageType.PAYMENT_SUCCESS, {
            includeQueryString: providerKey !== PaymentProvider.STRIPE
          });
        }
      }

      if (meta.errorCode) {
        paymentGTMError(meta?.message || '');
        endProviderLoading(providerKey);
      }
    } catch (ex) {
      endProviderLoading(providerKey);
      Promise.reject(ex);
    }
  }

  function preparePayload(form: CreditCardDetails, providerKey = PaymentProvider.CREDIT_CARD, acceptPolicy: boolean): PaymentPayload {
    const payload:PaymentPayload = {
      providerKey,
      packageId: pageData.value.selectedPackage?.id,
      creditCardDetails: { ...form },
      acceptPolicy,
    }

    if (providerKey === PaymentProvider.STRIPE) {
      payload.transactionId = pageData.value.stripeToken.transactionId;
      payload.secureHash = pageData.value.stripeToken.secureHash;
    }

    if (providerKey !== PaymentProvider.CREDIT_CARD) {
      if (pageData.value.registerOnPayment) {
        (payload.creditCardDetails as Partial<CreditCardDetails>) = {
          email: payload.creditCardDetails?.email || '',
        };
      } else {
        delete payload.creditCardDetails;
      }
    }
    return payload;
  }

  function handleCheckSubscriptionResponse(response: Record<string, any>) {
    if (isPreview.value) return;
    const { meta, result } = response;
    if (meta.httpStatus === 200) {
      pageData.value.isTrialUsed = result.isTrialUseBefore || false;
    }
  }

  async function getPaymentToken() {
    try {
      pageData.value.isLoading = true;

      if (!pageData.value.stripeToken.initializeToken) {
        const paymentToken = await Payment.getPaymentToken({
          packageId: pageData.value.selectedPackage.id,
          providerKey: PaymentProvider.STRIPE
        });

        // Mark as an error while getting Stripe token
        if (Array.isArray(paymentToken.result) || !paymentToken?.result) {
          pageData.value.paymentFormError = PaymentFormErrors.STRIPE_TOKEN_ERROR;
          return false;
        }

        pageData.value.stripeToken = paymentToken.result;
      }

      // Trigger Stripe load if token cannot get before
      if (pageData.value.paymentFormError === PaymentFormErrors.STRIPE_TOKEN_ERROR) {
        pageData.value.paymentFormError = PaymentFormErrors.STRIPE_LOAD_ERROR;
        globalEmit('integrationRetryLoad');
      } else {
        // Mark as no error on payment
        pageData.value.paymentFormError = PaymentFormErrors.NO_ERROR;
      }

      if (isStripeReturnFromPayment()) {
        handleSendPayment({
          form: {} as CreditCardDetails,
          acceptPolicy: getUrlParam('agree') === 'true',
          providerKey: PaymentProvider.STRIPE
        });
      }
    } catch {
      pageData.value.paymentFormError = PaymentFormErrors.STRIPE_TOKEN_ERROR;
    } finally {
      pageData.value.isLoading = false;
    }
  }

  async function handleApplePayPayment(paymentPayload: Record<string, any>) {
    try {
      const providerKey = PaymentProvider.APPLE_PAY;
      const paymentRequestPayload = JSON.parse(JSON.stringify((providerConfigs.value as any)?.applePay?.requestPayload));
      const transactionId = (providerConfigs.value as any)?.applePay?.transactionId;
      const ApplePaySession = (globalThis as any)?.ApplePaySession;

      const session = new ApplePaySession(2, paymentRequestPayload);

      session.onvalidatemerchant = async (event:any) => {
        const sessionUrl = event.validationURL;
        const data = await Payment.startSession({ providerKey, sessionUrl, transactionId });
        const sessionData = data?.result?.sessionData;
        session.completeMerchantValidation(sessionData);
      };

      session.oncancel = () => {
        endProviderLoading(PaymentProvider.APPLE_PAY);
      }

      session.onpaymentauthorized = async (event:any) => {
        const applePayToken = JSON.stringify(event.payment?.token || {});
        const payload = {
          transactionId,
          applePayToken,
          providerKey,
          packageId: pageData.value.selectedPackage?.id,
          acceptPolicy: paymentPayload.acceptPolicy
        }
        try {
          const { meta, result } = await Payment.sendPayment(payload);
          if (meta.httpStatus >= 200 && meta.httpStatus < 300) {
            setVisitedPages(PageType.PAYMENT);
            const { status, payment } = result || {};
            if (checkEpinAutoRefundStatus(result)) return endProviderLoading(PaymentProvider.APPLE_PAY);
            if (status === PaymentResultStatus.COMPLETE && payment) {
              session.completePayment(ApplePaySession.STATUS_SUCCESS); // Apple Success
              subscriberIdCookie.value = payment.subscriber?.subscriberId;
              goToPage(PageType.PAYMENT_SUCCESS, { includeQueryString: true });
            }
          } else {
            session.completePayment(ApplePaySession.STATUS_FAILURE); // Apple Failure
            session.abort();
          }
        } catch {
          session.completePayment(ApplePaySession.STATUS_FAILURE); // Apple Failure
          session.abort();
        }
      };

      await registerPaymentUser(paymentPayload, PaymentProvider.APPLE_PAY);

      // Show apple pay modal
      session.begin();

      endProviderLoading(providerKey);
    } catch {
      endProviderLoading(PaymentProvider.APPLE_PAY);
    }
  }

  async function handleGooglePayPayment(paymentPayload: Record<string, any>) {
    try {
      await registerPaymentUser(paymentPayload, PaymentProvider.GOOGLE_PAY);
      const paymentDataRequest = JSON.parse(JSON.stringify(providerConfigs.value[PaymentProvider.GOOGLE_PAY]?.paymentDataRequest));
      const googleClientResponse = await (googlePayClient.value as any)?.loadPaymentData(paymentDataRequest);
      checkoutGooglePayPayment(googleClientResponse, paymentPayload);
    } catch {
      endProviderLoading(PaymentProvider.GOOGLE_PAY);
    }
  }

  async function checkoutGooglePayPayment(googleClientResponse: Record<string, any>, paymentPayload: Record<string, any>) {
    const googlePayToken = googleClientResponse?.paymentMethodData?.tokenizationData?.token;
    const transactionId = (providerConfigs.value as any)?.googlePay?.transactionId;
    const payload = {
      transactionId,
      googlePayToken,
      providerKey: PaymentProvider.GOOGLE_PAY,
      packageId: pageData.value.selectedPackage?.id,
      acceptPolicy: paymentPayload.acceptPolicy,
    }
    try {
      const { meta, result } = await Payment.sendPayment(payload);
      if (meta.httpStatus >= 200 && meta.httpStatus < 300) {
        setVisitedPages(PageType.PAYMENT);
        const { status, payment } = result || {};
        if (checkEpinAutoRefundStatus(result)) return endProviderLoading(PaymentProvider.GOOGLE_PAY);
        if (status === PaymentResultStatus.COMPLETE && payment) {
          subscriberIdCookie.value = payment.subscriber?.subscriberId;
          goToPage(PageType.PAYMENT_SUCCESS, { includeQueryString: true });
        }
      }
    } catch {
      endProviderLoading(PaymentProvider.GOOGLE_PAY);
    } finally {
      endProviderLoading(PaymentProvider.GOOGLE_PAY);
    }
  }

  async function handlePackagesResponse() {
    if (packages.value?.length > 0) {
      if (pageData.value.paymentFormError === PaymentFormErrors.PACKAGE_LOAD_ERROR) {
        pageData.value.paymentFormError = PaymentFormErrors.NO_ERROR;
      }

      // Get payment token for custom providers
      if (pageData.value.providerStatus[PaymentProvider.STRIPE]) {
        pageData.value.stripeToken = selectedPackage?.stripeToken;
        await getPaymentToken();
      } else {
        pageData.value.isSubmitDisabled = false
      }
    } else {
      if (!isPreview.value) {
        pageData.value.paymentFormError = PaymentFormErrors.PACKAGE_LOAD_ERROR;
      }
    }

    if (agreement.value) {
      pageData.value.agreementData = agreement.value;
    }
  }

  function mergeProviderStatuses(providers:PaymentPageData['providerStatus'], selectedProviders:PaymentPageData['providerStatus']){
    return Object.keys(selectedProviders).reduce((acc:any, item) => {
      const isProviderSelectedAndActive = !!selectedProviders[item as PaymentProvider] && !!providers[item as PaymentProvider];
      if (item === PaymentProvider.APPLE_PAY) {
        const canMakePayments = isPreview.value ? true : (globalThis as any)?.ApplePaySession && (globalThis as any)?.ApplePaySession?.canMakePayments();
        acc[item] = canMakePayments && isProviderSelectedAndActive;
        return acc
      }
      if (item === PaymentProvider.GOOGLE_PAY) {
        const canMakePayments = isPreview.value ? true : isGoogleReadyToPay.value;
        acc[item] = canMakePayments && isProviderSelectedAndActive;
        return acc
      }
      acc[item] = isProviderSelectedAndActive
      return acc
    },{} as PaymentPageData['providerStatus']);
  }

  async function init() {
    $wait.start('initPackages');

    Promise.all([
      !isPreview.value && !pageData.value.registerOnPayment && Payment.checkSubscriptionStatus()
    ])
      .then(([subscriptionStatusResponse]) => {
        // Check active subscription
        if (!pageData.value.registerOnPayment) handleCheckSubscriptionResponse(subscriptionStatusResponse);
        // Get selected package
        handlePackagesResponse();
        setTimeout(fetchProviderStatuses, 0);
      })
      .finally(() => {
        handleErrors();
        $wait.end('initPackages');
      });
  }

  function loadGTMClickEvents() {
    if (isPreview.value) return;
    const buttons = document.querySelectorAll('.tw-element-button');

    for (const button of buttons) {
      button.addEventListener('click', paymentGTMClick);
    }

    Facebook.track('AddToCart');

    paymentGTMpageView();
  }

  function fetchProviderStatuses() {
    pageData.value.providerStatus = mergeProviderStatuses(selectedPackage?.providers, selectedPackage?.selectedProviders);
  }

  watch([isGoogleReadyToPay, isApplePaySdkLoaded], fetchProviderStatuses);

  function onStripeLoaded(active: any) {
    pageData.value.isSubmitDisabled = active;
  }

  function onIntegrationRetryLoad() {
    if (pageData.value.paymentFormError === PaymentFormErrors.STRIPE_TOKEN_ERROR) {
      return getPaymentToken();
    }

    if (pageData.value.paymentFormError === PaymentFormErrors.PACKAGE_LOAD_ERROR) {
      globalThis?.location?.reload?.();
    }
  }

  function onPaymentFormSubmitted(payloadData: any) {
    Facebook.track('InitiateCheckout');
    handleSendPayment({
      form: payloadData?.form as CreditCardDetails,
      acceptPolicy: payloadData?.acceptPolicy,
      providerKey: payloadData?.provider as PaymentProvider,
      stripeForm: payloadData.stripeForm,
    });
  }

  function onPaymentFormInputError(err: any) {
    if (isPreview.value) return;
    const [hasError, message] = err || [];
    if (hasError) {
      paymentGTMError(message);
    }
  }

  onBeforeMount(() => {
    globalListener('stripeLoaded', onStripeLoaded);
    globalListener('integrationRetryLoad', onIntegrationRetryLoad);
    globalListener('paymentFormSubmitted', onPaymentFormSubmitted);
    globalListener('paymentFormInputError', onPaymentFormInputError);
  });

  onMounted(() => {
    const redirectStatus = getUrlParam('redirect_status') || '';
    const isReturnFromPayment = ['succeeded', 'processing'].includes(redirectStatus);
    if(!isReturnFromPayment){
      pageData.value.isSuccessLoading = false
    }
    init();
    loadGTMClickEvents();
  });
  
  onBeforeUnmount(() => {
    globalRemoveListener('stripeLoaded', onStripeLoaded);
    globalRemoveListener('integrationRetryLoad', onIntegrationRetryLoad);
    globalRemoveListener('paymentFormSubmitted', onPaymentFormSubmitted);
    globalRemoveListener('paymentFormInputError', onPaymentFormInputError);
  })

  return { page, pageData };
}

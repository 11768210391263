export function cmToFeet(cmValue: number) {
  const realFeet = ((cmValue*0.393700) / 12);
  let feet = Math.floor(realFeet);
  let inches = Math.round((realFeet - feet) * 12);
  if (inches === 12) { inches = 0; feet++ }  
  return { feet, inches }
}

export function feetToCm(feet: number, inches: number) {
  return feet * 30.48 + inches * 2.54;
}

<template>
  <header class="flex h-64 bg-[#25242B] px-16 py-24 font-redhat lg:h-auto xl:px-40">
    <a :href="zotloStoreUrl">
      <img
        src="/store-assets/zotlo-store-logo.svg"
        alt="zotlo.store"
        class="h-16 lg:h-[1.375rem]"
      />
    </a>
  </header>

  <div class="mt-40 font-redhat">
    <div class="flex flex-col gap-16 p-16 text-center">
      <div v-if="config?.appLogo" class="mx-auto size-48">
        <img
          class="max-w-full rounded-[10px] border border-neutral-200 object-contain object-center"
          :src="config?.appLogo"
          :alt="config?.appName"
        />
      </div>

      <div class="space-y-8 text-[#25242B]">
        <div class="font-bold first-letter:uppercase">
          {{
            isEndingPage
              ? $t('generate.storeLayout.thankYouforYourAnswers')
              : $t('generate.storeLayout.hasSomeQuestionsForYou', { appName: config?.appName })
          }}
        </div>
      </div>
    </div>
  </div>

  <slot />

  <div class="mt-auto flex flex-col font-redhat">
    <div class="flex justify-center bg-white py-16">
      <div class="flex w-[430px] max-w-full px-8 text-[#707885] md:px-0">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="shrink-0"
        >
          <path
            d="M7.99967 11.5667C8.59982 11.5667 9.08634 11.0802 9.08634 10.48C9.08634 9.87986 8.59982 9.39334 7.99967 9.39334C7.39953 9.39334 6.91301 9.87986 6.91301 10.48C6.91301 11.0802 7.39953 11.5667 7.99967 11.5667Z"
            fill="currentColor"
          />
          <path
            d="M12.1863 6.35334V5.52001C12.1863 3.72001 11.753 1.33334 7.99967 1.33334C4.24634 1.33334 3.81301 3.72001 3.81301 5.52001V6.35334C1.94634 6.58668 1.33301 7.53334 1.33301 9.86001V11.1C1.33301 13.8333 2.16634 14.6667 4.89967 14.6667H11.0997C13.833 14.6667 14.6663 13.8333 14.6663 11.1V9.86001C14.6663 7.53334 14.053 6.58668 12.1863 6.35334ZM7.99967 12.4933C6.88634 12.4933 5.98634 11.5867 5.98634 10.48C5.98634 9.36668 6.89301 8.46668 7.99967 8.46668C9.10634 8.46668 10.013 9.37334 10.013 10.48C10.013 11.5933 9.11301 12.4933 7.99967 12.4933ZM4.89967 6.29334C4.84634 6.29334 4.79967 6.29334 4.74634 6.29334V5.52001C4.74634 3.56668 5.29967 2.26668 7.99967 2.26668C10.6997 2.26668 11.253 3.56668 11.253 5.52001V6.30001C11.1997 6.30001 11.153 6.30001 11.0997 6.30001H4.89967V6.29334Z"
            fill="currentColor"
          />
        </svg>

        <i18n-t
          scope="global"
          keypath="generate.storeLayout.privacyInfo"
          tag="p"
          class="pl-8 text-12"
          :locale="language"
        >
          <template #appName>
            {{ config?.appName }}
          </template>
          <template #terms>
            <a
              target="_blank"
              :href="applicationLinks?.termsOfServiceUrl"
              class="!text-primary-500 no-underline hover:underline"
            >
              {{ $t('generate.common.termsOfService') }}
            </a>
          </template>
          <template #privacy>
            <a
              target="_blank"
              :href="applicationLinks?.privacyUrl"
              class="!text-primary-500 no-underline hover:underline"
            >
              {{ $t('generate.common.privacyPolicy') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </div>
    <footer class="bg-[#25242B] py-24 text-white">
      <div class="mx-auto px-16 lg:max-w-[1464px]">
        <div class="lg:my-24 lg:grid lg:grid-flow-col lg:grid-cols-12 lg:gap-32">
          <div class="items-center max-lg:mb-24 md:col-span-2 md:flex md:justify-center">
            <a :href="zotloStoreUrl">
              <img
                src="/store-assets/zotlo-store-logo.svg"
                alt="zotlo.store"
                class="h-[22px]"
              />
            </a>
          </div>

          <div class="max-lg:mb-24 md:col-span-8 md:flex md:justify-center">
            <nav class="tw-payment-footer-nav text-16 font-normal">
              <a :href="DOCUMENT_LINKS.privacyPolicy" target="_blank">{{ $t('generate.common.privacyPolicy') }}</a>
              <a :href="DOCUMENT_LINKS.termsOfService" target="_blank">{{ $t('generate.common.termsOfService') }}</a>
              <a :href="DOCUMENT_LINKS.cookiePolicy" target="_blank">{{ $t('generate.common.cookiePolicy') }}</a>
            </nav>
          </div>

          <div class="md:col-span-2 md:flex md:justify-center"></div>
        </div>

        <i18n-t
          scope="global"
          keypath="generate.storeLayout.copyrightInfo"
          tag="div"
          class="py-8 text-14 font-normal text-[#B2B6C7] md:text-center"
          :locale="language"
        >
          <template #year>
            &copy; 2024
          </template>
          <template #link>
            <span
              class="!text-white"
            >
              Zotlo Store
            </span>
          </template>
        </i18n-t>
      </div>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { useSiteData } from '@/composables/useSiteData';
import { useSurveyStore } from '@/stores/survey';
import { storeToRefs } from 'pinia';

const { config, language, applicationLinks, isPreview } = useSiteData();
const { isEndingPage } = storeToRefs(useSurveyStore());

const DOCUMENT_LINKS = computed(() => {
  return {
    privacyPolicy:
      language.value === 'tr'
        ? 'https://3p-assets.cdnztl.com/docs/Zotlo-Gizlilik-Politikasi-TR-2024-04-01.pdf'
        : 'https://3p-assets.cdnztl.com/docs/Zotlo-Privacy-Policy-EN-2024-04-01.pdf',
    termsOfService:
      language.value === 'tr'
        ? 'https://3p-assets.cdnztl.com/docs/Zotlo-Kullanici-hizmet-sartlari-TR-2024-04-02.pdf'
        : 'https://3p-assets.cdnztl.com/docs/Zotlo-Terms-of-Service-EN-2024-04-02.pdf',
    cookiePolicy: 'https://3p-assets.cdnztl.com/docs/Zotlo-Store-Cookie-Policy-20240505.pdf',
    zotloPage: 'https://zotlo.com'
  };
});

const zotloStoreUrl = computed<string>(() => {
  // In preview mode this url will restart quiz
  return isPreview.value ? '' : (useRuntimeConfig()?.NUXT_ZOTLO_STORE_URL as string);
});
</script>

<style lang="postcss" scoped>
.tw-payment-footer-nav {
  @apply flex flex-col md:flex-row md:items-center;

  a {
    @apply my-4
      block
      text-[#F5F5FA]
      no-underline
      md:relative
      md:my-0
      md:px-16
      md:after:absolute
      md:after:right-0
      md:after:top-1/2
      md:after:-mt-[10px]
      md:after:h-20
      md:after:border-r
      md:after:border-r-[#3E4655]
      md:after:content-['']
      md:last:after:content-none;
  }
}
</style>

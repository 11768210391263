export function parseTime(timeString:string) {
  const [ hour, minute ] = timeString.split(':').map(Number);
  if (!minute || (!hour && hour !== 0)) return {};
  const minuteText = minute.toString().padStart(2, '0');
  // 24-hour format
  const twentyFourHourFormat = `${hour.toString().padStart(2, '0')}:${minuteText}`;

  // 12-hour format
  const period:string = hour >= 12 ? 'PM' : 'AM';
  const twelveHourFormatHour = (hour % 12 === 0 ? 12 : hour % 12).toString().padStart(2, '0');
  const twelveHourFormat = `${twelveHourFormatHour}:${minuteText} ${period}`;

  return {
    twentyFourHourFormat,
    twentyFourHour_hour: hour.toString().padStart(2, '0'),
    twelveHourFormat,
    twelveHour_hour:twelveHourFormatHour,
    minute: minuteText,
    period
  };
}

export function convert12HourTo24Hour(timeString:string, period?:string) {
  const [hoursStr, minutesStr] = timeString.split(':');
  const hours = parseInt(hoursStr);
  const minutes = parseInt(minutesStr);

  if (period?.toUpperCase() === 'PM' && hours !== 12) {
    return `${hours + 12}:${minutes.toString().padStart(2, '0')}`;
  } else if (period?.toUpperCase() === 'AM' && hours === 12) {
    return `00:${minutes.toString().padStart(2, '0')}`;
  } else {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}

export function fillHourOptions(is24HourFormat = true){
  const hours = is24HourFormat ? 24 : 12;
  const startHour = is24HourFormat ? 0 : 1;
  return Array.from({ length: hours }, (_, index) => {
    const hourValue = (startHour + index) % (is24HourFormat ? 24 : 13);
    const hourText = hourValue.toString().padStart(2, '0');
    return { label: hourText, value: hourText }
  });
}

export function fillMinuteOptions() {
  return Array.from({ length: 60 }, (_, index) => {
    const minuteText = String(index).padStart(2, '0');
    return { label: minuteText, value: minuteText }
  });
}

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PageType } from '@/types';
import { useSiteData } from '@/composables/useSiteData';
import { prepareFontUrl } from '@shared/utils';
import { View } from '@/services/view';
import { Integration } from "@/utils/integrations";
import { ProviderSdks } from "@/utils/providers";
import type { PageOptions, Page } from '@shared/types/model';
import { getNamedCookie, COOKIE } from '../utils/cookie';

export function usePage(slug: PageType) {
  const { t: $t, setLocale } = useI18n();
  const { currentPage, isPreview, isStoreFlow, siteData, config, applicationLinks } = useSiteData();
  const isUserAlreadySubscribed = !!getNamedCookie(COOKIE.USER_ALREADY_SUBSCRIBED);
  const page = ref<Page>(JSON.parse(currentPage.value?.page || '{}'));
  const pageOptions = ref<PageOptions>(JSON.parse(siteData.value?.pageOptions || '{}'));
  const { query, full: fontUrl } = prepareFontUrl([pageOptions.value.fontFamily, ...(pageOptions.value.fontFamilies?.[slug] || [])]);
  const headLinks = [] as any[];

  // Remove zotlo footer if wants to hide
  if (siteData.value?.hideGenerateFooter) {
    const zotloFooter = page.value.sections.find((section) => section.type === 'zotloFooter');
    if (zotloFooter) page.value.sections.splice(page.value.sections.indexOf(zotloFooter), 1);
  }

  if (import.meta.server || import.meta.browser || import.meta.client) {
    // Set valid locale for SSR CSR
    if (siteData.value?.lang) setLocale(siteData.value.lang);
  }

  if (config.value.favicon && !isStoreFlow.value) {
    headLinks.push({
      rel: 'icon',
      type: 'image/x-icon',
      href: config.value.favicon
    });
  }

  if (query) {
    headLinks.push(...[
      { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
      { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
      { rel: 'stylesheet', href: fontUrl }
    ]);
  }

  if (isStoreFlow.value) {
    // If store flow, add zotlo store's primary font family
    headLinks.push({
      rel: 'stylesheet', href: prepareFontUrl(['Red Hat Display'])?.full
    })
    // If store flow, favicon must be store's favicon.
    const storesFavIconUrl = `${useRuntimeConfig()?.NUXT_ZOTLO_STORE_URL as string}favicon.ico`
    headLinks.push({
      rel: 'icon',
      type: 'image/x-icon',
      href: storesFavIconUrl
    });
  }

  if (!import.meta.server && !isPreview.value && !isUserAlreadySubscribed) {
    const domain = window.location.origin;
    View.BASE_PATH = `${domain}/api/event`;
    View.page(getUserAgent(page.value?.type as PageType));
  }

  if (!import.meta.server) {
    const uaInfo = getUserAgent();
    if (uaInfo.platform !== 'desktop') {
      document?.body?.classList?.add("is-mobile-like-device");
    }
  }

  let pageProps;

  if (slug === PageType.LANDING) {
    const { pageData } = useLanding(page);
    setVisitedPages(slug);
    pageProps = pageData;
  }

  if (slug === PageType.QUIZ) {
    const { pageData } = useSurvey(page);
    pageProps = pageData;
  }

  if (slug === PageType.REGISTER) {
    const { pageData } = useRegister(page);
    pageProps = pageData;
  }

  if (slug === PageType.PAYWALL) {
    const { pageData } = usePaywall(page);
    pageProps = pageData;
  }

  if (slug === PageType.PAYMENT) {
    const { pageData } = usePayment(page);
    pageProps = pageData;
  }

  if (slug === PageType.PAYMENT_SUCCESS) {
    const { pageData } = usePaymentSuccess(page);
    pageProps = pageData;
  }

  if (pageProps?.value) {
    pageProps.value.applicationLinks = applicationLinks;
  }

  const headScripts = [ ...Integration.init().script, ...ProviderSdks.init().script ];
  const headNoscripts = [ ...Integration.init().noscript, ...ProviderSdks.init().noscript ];
  const pageSettings = pageOptions.value?.pages?.[slug] || {};
  const forceDesktopBG = !!pageSettings.forceDesktopBG;
  const { desktop, tablet, mobile } = pageSettings.backgroundImage || {};
  const bg = {
    color: pageSettings.backgroundColor,
    image: {
      desktop: desktop || '',
      tablet: (forceDesktopBG ? desktop : tablet) || '',
      mobile: (forceDesktopBG ? desktop : mobile) || '',
    },
    repeat: pageSettings.backgroundRepeat ? 'repeat' : 'no-repeat',
    size: pageSettings.backgroundRepeat ? 'auto' : 'cover',
  };
  const isWebsiteRedirectionActive = slug === PageType.PAYMENT_SUCCESS && !!siteData.value?.websiteRedirectionSettings?.status;

  return {
    page,
    pageData: pageProps,
    pageOptions,
    head: {
      title: config.value.appName ? `${config.value.appName} | ${$t(`generate.page.${currentPage.value?.template?.name}`)}` : '',
      htmlAttrs: {
        dir: siteData.value?.languageData?.isRightAlignment ? 'rtl' : 'ltr',
        lang: siteData.value?.lang,
      },
      link: headLinks,
      script: headScripts,
      noscript: headNoscripts,
      style: [
        `:root {
          --app-backgroundColor: ${bg.color};
          --app-backgroundImage-desktop: ${bg.image.desktop};
          --app-backgroundImage-tablet: ${bg.image.tablet};
          --app-backgroundImage-mobile: ${bg.image.mobile};
          --app-backgroundRepeat: ${bg.repeat};
          --app-backgroundSize: ${bg.size};
        }`
      ]
    } as any,
    isWebsiteRedirectionActive,
  }
}

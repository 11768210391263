<template>
  <div class="tw-provider-tabs flex w-full gap-8" :class="{ disabled }">
    <button
      v-if="getProviderStatus(CREDIT_CARD) || getProviderStatus(STRIPE)"
      class="tw-provider-tabs__button h-56 lg:h-64"
      :class="{ 'tw-provider-tabs__button--active': activeTab === CREDIT_CARD, ['order-' + handleStripeCreditCardOrder()]: true }"
      :style="{order: handleStripeCreditCardOrder()}"
      type="button"
      :disabled="disabled"
      @click="handleTabChange(CREDIT_CARD)"
    >
      <img
        :src="getAssetFromCdn(`editor/payment-providers/creditCard${darkMode ? '' : '_black'}.png`)"
        alt="creditCard"
      />
      <span class="pl-4" :class="{ 'text-white': darkMode }">
        {{ translate('generate.pages.payment.cardTabTitle', locale) }}
      </span>
    </button>
    <button
      v-if="getProviderStatus(PAYPAL)"
      class="tw-provider-tabs__button h-56 lg:h-64"
      :class="{ 'tw-provider-tabs__button--active': activeTab === PAYPAL}"
      :style="{ order: getOrder(PAYPAL) }"
      type="button"
      :disabled="disabled"
      @click="handleTabChange(PAYPAL)"
    >
      <img :src="getAssetFromCdn(`editor/payment-providers/paypal${darkMode ? '_disabled' : ''}.png`)" alt="paypal" />
    </button>
    <button
      v-if="getProviderStatus(GOOGLE_PAY)"
      class="tw-provider-tabs__button h-56 lg:h-64"
      :class="{
        'tw-provider-tabs__button--active': activeTab === GOOGLE_PAY
      }"
      :style="{ order: getOrder(GOOGLE_PAY)}"
      type="button"
      :disabled="disabled"
      @click="handleTabChange(GOOGLE_PAY)"
    >
      <img
        :src="getAssetFromCdn(`editor/payment-providers/googlePay.svg`)"
        class="h-[32px] !max-h-[32px]"
        alt="googlePay"
      />
    </button>
    <button
      v-if="getProviderStatus(APPLE_PAY)"
      class="tw-provider-tabs__button h-56 lg:h-64"
      :class="{
        'tw-provider-tabs__button--active': activeTab === APPLE_PAY
      }"
      :style="{ order: getOrder(APPLE_PAY)}"
      type="button"
      :disabled="disabled"
      @click="handleTabChange(APPLE_PAY)"
    >
      <img
        :src="getAssetFromCdn(`editor/payment-providers/applePay.svg`)"
        class="h-[32px] !max-h-[32px]"
        alt="applePay"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import { getAssetFromCdn } from '@shared/utils/helpers';
import { useTranslate } from '@shared/composable/useTranslate';
import { type PaymentElementTabs, PaymentProvider } from '@shared/elements/common/payment/types';

const { PAYPAL, GOOGLE_PAY, APPLE_PAY, CREDIT_CARD, STRIPE } = PaymentProvider;

const props = defineProps({
  modelValue: { type: String as PropType<PaymentElementTabs>, default: '' },
  locale: { type: String, default: '' },
  element: { type: Object as PropType<ElementType<'payment' | 'payment-with-tabs'>>, default: () => ({}) },
  submitButtonElement: { type: Object as PropType<ElementType<'button' | 'conditional-button'>>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  getProviderStatus: { type: Function, default: () => true },
  darkMode: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue']);

const { translate } = useTranslate();

const activeTab = computed<PaymentElementTabs>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

function handleTabChange(tab: PaymentElementTabs) {
  activeTab.value = tab;
}

// Get payment element submit button backgroundColor and set activeTab button style accordingly
const submitButtonElementBackgroundColor = computed(() => props.submitButtonElement.style?.backgroundColor);
const activeTabButtonStyle = computed(() => {
  const primaryColor = submitButtonElementBackgroundColor.value || props.pageOptions.colors?.theme[0];
  const backgroundColor = primaryColor + '1a'; // 1a is for %10 opacity
  return {
    borderColor: primaryColor,
    backgroundColor
  };
});

const handleStripeCreditCardOrder = () => {
  if (props.getProviderStatus(CREDIT_CARD)) {
    return getOrder(CREDIT_CARD);
  }
  else {
    return getOrder(STRIPE);
  }
};

const getOrder = (item: PaymentProvider) => {
  const order = props.element.options.order as any;
  if (!order) return;
  return order[item];
};

</script>

<style lang="postcss" scoped>
.tw-provider-tabs {
  --active-button-border-color: v-bind(activeTabButtonStyle.borderColor);
  --active-button-background-color: v-bind(activeTabButtonStyle.backgroundColor);
  &__button {
    @apply flex flex-1 items-center justify-center rounded-[6px] border border-neutral-200 p-12 text-14 font-semibold;
    &--active {
      border-color: var(--active-button-border-color);
      background-color: var(--active-button-background-color);
    }
    img {
      @apply max-h-16 shrink-0;
    }
  }
  &.disabled {
    .tw-provider-tabs__button {
      @apply cursor-not-allowed;
    }
  }
}
/* For editor screen only */
.tw-editor__screen {
  &.mobile,
  &.tablet {
    .tw-provider-tabs__button {
      @apply !h-56;
    }
  }
}
</style>

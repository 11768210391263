<template>
  <span class="tw-spinner-wrapper" :class="`tw-spinner-direction-${spinnerDirection}`">
    <WebIcon
      v-if="type === 'loader'"
      name="Loader"
      class="tw-spinner"
      :class="loaderClass"
    />
    <i
      v-else
      class="tw-spinner"
      :class="spinnerClass"
      :style="`border-width: ${stroke}`"
    />
    <span
      v-if="text"
      class="inline-flex"
      :class="textClass"
    >{{ text }}</span>
  </span>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import WebIcon from '@shared/components/icon/index.vue';

const props = defineProps({
  type: { type: String, default: 'default' },
  size: { type: [String, Number], default: '24' },
  background: { type: String, default: 'primary-700' },
  color: { type: String, default: 'transparent' },
  stroke: { type: [String, Number], default: '2' },
  direction: {
    type: String,
    required: false,
    default: 'left',
    validator(value: string) {
      return ['right', 'left', 'top', 'bottom', 'center'].includes(value);
    }
  },
  text: { type: String, default: '' },
  textSize: { type: String, default: '16' },
  textColor: { type: String, default: '#ffffff' }
})

const spinnerDirection = ref(props.direction);

const spinnerClass = computed(() => {
  return `w-${props.size} h-${props.size} border-${props.stroke} border-${props.background} border-l-${props.color}`;
});

const loaderClass = computed(() => {
  return `w-${props.size} h-${props.size} text-${props.color}`;
});

const textClass = computed(() => {
  return `text-${props.textSize} text-${props.textColor}`;
});
</script>

<style lang="postcss" scoped>
.tw-spinner {
  @apply box-border inline-flex animate-spin rounded-full border-solid;

  &-wrapper {
    @apply flex items-center gap-x-8;
  }

  &-direction {
    &-right {
      @apply flex-row-reverse;
    }
    &-center {
      @apply justify-center;
    }
    &-top {
      @apply flex-col;
    }
    &-bottom {
      @apply flex-col-reverse;
    }
  }
}
</style>

<template>
  <TextElement
    :tag-name="tagName"
    :value="textValue"
    :attr="attr"
    :element="element"
    :style="textStyle"
    :page-options="pageOptions"
    class="tw-translation-element"
  />
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { Element as ElementType, PageOptions } from '../../types/model';
import type { ElementOptions } from '../../types/options';
import { useApplyLinks } from '@shared/composable/useApplyLinks';
import TextElement from '../text/index.vue';

const props = defineProps({
  tagName: { type: String, default: 'p' },
  value: { type: String, default: '' },
  attr: { type: Object as PropType<ElementType<'translation'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'translation'>>, default: () => ({}) },
  style: { type: Object as PropType<ElementType<'translation'>['style']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  locale: { type: String, default: '' },
  isEditorMode: { type: Boolean, default: false },
});

const { textValue } = useApplyLinks(props);

const options = computed(() => props.element.options as ElementOptions['translation'])

const textStyle = computed(() => {
  return {
    ...props.style,
    fontWeight: options.value?.textStyle?.bold ? '600' : '400',
    fontStyle: options.value?.textStyle?.italic ? 'italic' : 'normal',
    textDecoration: `${options.value?.textStyle?.underline ? 'underline' : ''} ${options.value?.textStyle?.strikeThrough ? 'line-through' : ''}`.trim()
  }
});

</script>

<style lang="postcss">
.tw-translation-element {
  a {
    &[data-privacy-policy-link],
    &[data-terms-of-use-link],
    &[data-customer-service-link],
    &[data-zotlo-terms-of-use-link],
    &[data-zotlo-privacy-policy-link],
    &[data-payment-aggregator]
    {
      color: #007AFF;
    }
  }
}
</style>
